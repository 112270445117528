import { Box, Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";  // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css';

interface PreTemplateModalProps {
    opnTemp: boolean;
    handleCloseDialog: () => void;
    templateData?: any;
    fetchTemplates?: (optype: any) => void
}

const PreTemplateModal = ({ opnTemp, handleCloseDialog, templateData, fetchTemplates }: PreTemplateModalProps) => {
    // Separate state for templateName
    const [templateName, setTemplateName] = useState("");
    const [isMultiline, setIsMultiline] = useState(false);
    const handleBlur = () => setIsMultiline(false);
    const handleFocus = () => setIsMultiline(true);
    const [isMultilinetwo, setIsMultilinetwo] = useState(false);
    const handleBlurtwo = () => setIsMultilinetwo(false);
    const handleFocustwo = () => setIsMultilinetwo(true);
    const [errors, setErrors] = useState({
        templateName: "",
    });

    // Single state to store other form data
    const [formData, setFormData] = useState({
        operativeFindings: "", // Default value for radio buttons
        procedureNotes: "",
        inclusion: "",
    });

    // Handle input changes for formData
    const handleFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    // Handle templateName change separately
    const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateName(e.target.value);
    };

    // Function to handle save or update
    const handleSave = async () => {
        // Reset errors before checking
        setErrors({ templateName: "" });

        // Validate templateName
        if (!templateName.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                templateName: "Template Name is required.",
            }));
            return; // Prevent submission if validation fails
        }

        const token = localStorage.getItem("token");

        if (!token) {
            console.error("No token found in localStorage");
            return;
        }

        const requestData = {
            ope_type: "per", // Operation type
            templateName: templateName,
            templateData: formData,
        };

        // If `templateData.id` exists, it means we are updating an existing template
        if (templateData?._id) {
            // If `templateData` exists, hit the PATCH API to update the template
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/updateTemplateById/${templateData._id}`,
                    requestData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                toast.success("Template updated successfully!");
                handleCloseDialog();
                if (fetchTemplates) {
                    fetchTemplates("per")
                }

                // Reset form data after successful update
                setTemplateName("");
                setFormData({
                    operativeFindings: "",
                    procedureNotes: "",
                    inclusion: "",
                });

            } catch (error) {
                console.error("Error occurred while updating the template:", error);
                toast.error("Error occurred while updating the template");
            }
        } else {
            // If no templateData exists (new template), hit the POST API to save the template
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/addNewTemplate`,
                    requestData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                toast.success("Template saved successfully!");
                handleCloseDialog();
                // Reset form data after successful save
                setTemplateName("");
                setFormData({
                    operativeFindings: "",
                    procedureNotes: "",
                    inclusion: "",
                });
                if (fetchTemplates) {
                    fetchTemplates("per")
                }
            } catch (error) {
                console.error("Error occurred while saving the template:", error);
                toast.error("Error occurred while saving the template");
            }
        }
    };

    // Handle "Enter" key press in Inclusion field to trigger save
    const handleKeyPressInclusion = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };

    // Set templateData into form fields if templateData exists
    useEffect(() => {
        if (templateData) {
            setTemplateName(templateData?.templateName || "");
            setFormData({
                operativeFindings: templateData?.templateData?.operativeFindings || "",
                procedureNotes: templateData?.templateData?.procedureNotes || "",
                inclusion: templateData?.templateData?.inclusion || "",
            });
        }
    }, [templateData]);

    return (
        <Dialog open={opnTemp} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <Box sx={{ padding: 1.5 }}>
                <Typography variant="h6">{templateData ? "Update" : "Add"} Per Operation Template</Typography>
                <Grid container spacing={1}>
                    <Grid item md={3} sm={4} xs={6}>
                        <TextField
                            name="templateName"
                            value={templateName}
                            onChange={handleTemplateNameChange}
                            required
                            className="search-field"
                            size="small"
                            fullWidth
                            label="Template Name"
                            sx={{ mt: 1 }}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    background: "#F5F5FB",
                                    fontSize: "12px",
                                },
                            }}
                        />
                        <Typography sx={{ fontSize: "10px", color: "red" }}>
                            {errors.templateName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} mt={0.5}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            name="procedureNotes"
                            value={formData.procedureNotes}
                            onChange={handleFormDataChange}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    background: "#F5F5FB",
                                    fontSize: "12px",
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "&:hover fieldset": {
                                        border: "none",
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: "none",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    top: "-2px",
                                },
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            multiline={isMultiline}
                            minRows={isMultiline ? 3 : 1}
                            size="small"
                            fullWidth
                            label="Enter Procedure Note"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            name="operativeFindings"
                            value={formData.operativeFindings}
                            onChange={handleFormDataChange}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    background: "#F5F5FB",
                                    fontSize: "12px",
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "&:hover fieldset": {
                                        border: "none",
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: "none",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    top: "-2px",
                                },
                            }}
                            onFocus={handleFocustwo}
                            onBlur={handleBlurtwo}
                            multiline={isMultilinetwo}
                            minRows={isMultilinetwo ? 3 : 1}
                            size="small"
                            fullWidth
                            label="Operative Findings"
                        />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <TextField
                            name="inclusion"
                            value={formData.inclusion}
                            onChange={handleFormDataChange}
                            className="search-field"
                            size="small"
                            fullWidth
                            label="Incision"
                            sx={{ mt: 1 }}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    background: "#F5F5FB",
                                    fontSize: "12px",
                                },
                            }}
                            onKeyDown={handleKeyPressInclusion}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 1, display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Button onClick={handleSave} variant="contained" color="primary">  {templateData ? "Update" : "Save"}</Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default PreTemplateModal;
