// testGroupSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface TestGroup {
  id: number;
  name: string;
  // Add more properties as needed
}

interface InitialState {
  testGroups: TestGroup[];
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: string | null;
}

// Async thunk action to fetch test groups from the API
export const fetchTestGroups = createAsyncThunk(
  'testGroups/fetchTestGroups',
  async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }
      
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${process.env.REACT_APP_PATHOLOGY_URL}/test-group/getAllTestGroup`, { headers });
      return response.data?.data;
    } catch (error) {
      throw error; // Propagate the error up
    }
  }
);

// Initial state
const initialState: InitialState = {
  testGroups: [],
  loading: 'idle',
  error: null,
};

// Create test group slice
const testGroupSlice = createSlice({
  name: 'testGroupSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestGroups.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchTestGroups.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.testGroups = action.payload;
        state.error = null;
      })
      .addCase(fetchTestGroups.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message || 'Failed to fetch test groups';
      });
  },
});

export default testGroupSlice.reducer;
