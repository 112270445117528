import React from 'react';
import { useAuth } from '../../hooks/useAppContext';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes: React.FC = () => {
  const { isLoggedIn } = useAuth();
  return <>{!isLoggedIn ? <Outlet /> : <Navigate to="/" />}</>;
};

export default PrivateRoutes;
