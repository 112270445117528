import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface DeleteConfirmationModalProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    message?: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ open, handleClose, handleConfirm, message = "Are you sure you want to delete this?" }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 1 }}>
                <Typography id="modal-title" variant="h6" component="h2">
                    {message}
                </Typography>
                <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteConfirmationModal;
