import { LockPerson } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

function Restrict() {
  return (
    <Box sx={{
        backgroundColor: '#fff',
        p: 4,
        height:'80vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}> 
        <LockPerson sx={{height:'40px',minHeight:'40px',width:'40px',minWidth:'40px',border:'1px solid #FF5454',color:'white',background:'#FF5454',borderRadius:'50%',px:0.5}} />
        <Typography sx={{fontSize:{
            md:'20px',
            lg:'30px',
            color:'#FF5454'
        }}}> You have no access to this page!</Typography>
        </Box>
        </Box>
  )
}

export default Restrict