import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!(localStorage.getItem('token'))
  );
  const login = (token) => {
    localStorage.setItem('token', token);
    localStorage.setItem('expirationAlertShown','true')
    setIsLoggedIn(true);
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };
  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
  