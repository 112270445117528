import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Box, IconButton, Typography, FormControlLabel, Checkbox, TextField } from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

interface RulesRegulationModalProps {
    isOpen: boolean;
    handleOpenClose: () => void;
    onTimeOutUpdate: (updatedTimeOut: any) => void;
    initialFormState: any;
}

const POCI: React.FC<RulesRegulationModalProps> = ({ isOpen, handleOpenClose, onTimeOutUpdate, initialFormState }) => {
    const [formState, setFormState] = useState({
        Nill_Per_Oral: true,
        Keep_the_patient: true,
        Monitor_Vital: true,
        Monitor_Drain: true,
        Monitor_Dressing: true,
        Monitor_Urine: true,
        Special_Order: true,
        Nill_Hours: "", // Added
        Monitor_Vital_Hourly: "", // Added
        Monitor_Vital_Hours: "", // Added
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, value, checked } = event.target;
        setFormState((prevState: any) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (initialFormState) {
            setFormState(initialFormState);
        }
    }, [initialFormState]);

    const handleTimeOutClick = () => {
        onTimeOutUpdate(formState);
        handleOpenClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleOpenClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <DialogTitle id="alert-dialog-title">Post Operative Care Instructions</DialogTitle>
                <IconButton onClick={handleOpenClose}>
                    <HighlightOffOutlinedIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Box sx={{ bgcolor: "rgba(5, 95, 252, 1)", textAlign: "center", width: "100%", p: 1, color: "white" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>POCI</Typography>
                </Box>
                <Box sx={{ bgcolor: "rgba(5, 95, 252, .1)" }}>
                    {/* Nill Per Oral Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formState?.Nill_Per_Oral}
                                    onChange={handleChange}
                                    name="Nill_Per_Oral"
                                />
                            }
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "13px", fontWeight: "600" }}>
                            Nill Per Oral For
                            <TextField

                                variant="standard"
                                size="small"
                                sx={{
                                    mx: 1, width: "50px", fontSize: "10px", // Smaller font size
                                    '& input': {
                                        fontSize: '10px', // Targeting the input element to ensure the font size is applied here
                                    }
                                }}
                                name="Nill_Hours"
                                value={formState.Nill_Hours}
                                onChange={handleChange}
                                type="number"
                            />
                            Hours
                        </span>
                    </Box><Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.Keep_the_patient} onChange={handleChange} name="Keep_the_patient" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            Keep the patient in Head Up Supine Position / Side Position / Legs Elevated                        </span>
                    </Box>

                    {/* Monitor Vital Signs Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formState?.Monitor_Vital}
                                    onChange={handleChange}
                                    name="Monitor_Vital"
                                />
                            }
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            Monitor Vital Signs And Oxygen Saturation
                            <TextField
                                variant="standard"
                                size="small"
                                sx={{
                                    mx: 1, width: "50px", fontSize: "10px", // Smaller font size
                                    '& input': {
                                        fontSize: '10px', // Targeting the input element to ensure the font size is applied here
                                    }
                                }}
                                name="Monitor_Vital_Hourly"
                                value={formState.Monitor_Vital_Hourly}
                                onChange={handleChange}
                                type="number"
                            />
                            Hourly For
                            <TextField
                                variant="standard"
                                size="small"
                                sx={{
                                    mx: 1, width: "50px", fontSize: "10px", // Smaller font size
                                    '& input': {
                                        fontSize: '10px', // Targeting the input element to ensure the font size is applied here
                                    }
                                }}
                                name="Monitor_Vital_Hours"
                                value={formState.Monitor_Vital_Hours}
                                onChange={handleChange}
                                type="number"
                            />
                            Hours
                        </span>
                    </Box>

                    {/* Additional Sections */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formState?.Monitor_Drain}
                                    onChange={handleChange}
                                    name="Monitor_Drain"
                                />
                            }
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            Monitor Drain Output
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.Monitor_Dressing} onChange={handleChange} name="Monitor_Dressing" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            Monitor Dressing For Soakage
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.Monitor_Urine} onChange={handleChange} name="Monitor_Urine" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            Monitor Urine Output
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.Special_Order} onChange={handleChange} name="Special_Order" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            Any Special Order (Blood Transfusion / Oxygen Administration / Sending Blood or Pus    Culture or Bioxy ) (edited)
                        </span>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "column", mt: 1 }}>
                    <Button sx={{ bgcolor: "rgba(5, 95, 252, 1)" }} variant="contained" onClick={handleTimeOutClick}>
                        Submit
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default POCI;
