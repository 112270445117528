import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, Box, InputBase, Paper, InputAdornment, Button, FormControlLabel, Checkbox, Divider, Switch } from '@mui/material';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { isDateTimeGreaterThan30MinutesAgo } from '../../../../../utils/dateTimeUtils';

interface VitalAndIOPChartProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
    handleSave: (shouldClose: boolean, searchId?: any) => void;
}

interface IOPChartEntry {
    vitals: {
        temperature: string;
        BP: string;
        RR: string;
        HR: string;
        weight: string;
    };
    iopInput: {
        oralFeed: string;
        NG_Feed: string;
        IVFluid: string;
        TPN: string;
        calories: string;
    };
    iopOutput: {
        urine: { value: string; checked: boolean };
        vomiting: { value: string; checked: boolean };
        drain: { value: string; checked: boolean };
        ngAspirate: { value: string; checked: boolean };
        stool: { value: string; checked: boolean };
    };
    fluidBalance: {
        input: string;
        output: string;
        difference: string;
    };
    bloodSugar: {
        BSR: string;
        comment: string;
    };
}

interface SaveData {
    IOP_Chart: IOPChartEntry[];
}

const VitalAndIOPChart: React.FC<VitalAndIOPChartProps> = ({ onChange, data, otherTabsData, profile, handleSave }) => {
    const currentDate = dayjs();
    const [formData, setFormData] = useState<any>({
        vitals: {
            temperature: '',
            BP: '',
            RR: '',
            HR: '',
            spo: '',
        },
        iop_Input: {
            oralFeed: '',
            NG_Feed: '',
            IVFluid: '',
            TPN: '',
            calories: '',
        },
        iop_Output: {
            urine: { value: '', checked: false },
            vomiting: { value: '', checked: false },
            drain: { value: '', checked: false },
            ngAspirate: { value: '', checked: false },
            stool: { value: '', checked: false },
        },
        fluid_Balance: {
            input: '',
            output: '',
            difference: '',
        },
        blood_Sugar: {
            BSR: '',
            comment: '',
        },
    });
    const [showInput, setShowInput] = useState<boolean>(false);
    const [showOutput, setShowOutput] = useState<boolean>(false);
    const [showFluid, setShowFluid] = useState<boolean>(false);
    const [showVitals, setShowVitals] = useState<boolean>(true);
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const [saveData, setSaveData] = useState<SaveData>({
        IOP_Chart: [],
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [searchId, setSearchId] = useState<any>();
    const [clickSave, setClickSave] = useState<any>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const [section, field] = name.split('.');
        let updatedFormData = { ...formData };
        if (section == 'iop_Output') {
            if (type === 'checkbox') {
                updatedFormData = {
                    ...updatedFormData,
                    [section]: {
                        ...updatedFormData[section],
                        [field]: {
                            ...updatedFormData[section][field],
                            checked: checked,
                        },
                    },
                };
            } else {
                updatedFormData = {
                    ...updatedFormData,
                    [section]: {
                        ...updatedFormData[section],
                        [field]: {
                            ...updatedFormData[section][field],
                            value: value,
                        },
                    },
                };
            }
        }
        else {
            updatedFormData = {
                ...updatedFormData,
                [section]: {
                    ...updatedFormData[section],
                    [field]: value,
                },
            };
        }
        setFormData(updatedFormData);
    };

    const handleEdit = (index: number) => {
        setIsComponentVisible(true);
        const dataToEdit: any = saveData?.IOP_Chart[index];
        setFormData({
            vitals: dataToEdit.vitals,
            iopInput: dataToEdit.iopInput,
            iopOutput: dataToEdit.iopOutput,
            fluidBalance: dataToEdit.fluidBalance,
            bloodSugar: dataToEdit.bloodSugar,
        });

        setIsEditMode(true);
        setEditIndex(index);
    };

    const handleSaveIOP = () => {
        const dataToSave = {
            IOP_Chart: [
                {
                    vitals: formData.vitals,
                    iop_Input: formData.iop_Input,
                    iop_Output: formData.iop_Output,
                    fluid_Balance: formData.fluid_Balance,
                    blood_Sugar: formData.blood_Sugar,
                    dateTime: currentDate.format('DD.MM.YYYY hh:mm:ss A'),
                    doctorName: profile?.data?.fullName,
                }
            ],
        };

        // Update `saveData` state
        if (isEditMode && editIndex !== null) {
            setSaveData((prevData: any) => {
                const updatedData = { ...prevData };
                updatedData.IOP_Chart[editIndex] = dataToSave.IOP_Chart;
                return updatedData;
            });
        } else {
            setSaveData((prevData: any) => {
                return {
                    ...prevData,
                    IOP_Chart: prevData?.IOP_Chart ? [...prevData?.IOP_Chart, ...dataToSave?.IOP_Chart] : dataToSave?.IOP_Chart,
                };
            });
        }

        // Call `onChange` directly after saving the data
        onChange({
            ...saveData,
            IOP_Chart: saveData?.IOP_Chart ? [...saveData.IOP_Chart, ...dataToSave.IOP_Chart] : dataToSave.IOP_Chart,
        });
        setClickSave(true)
        // Reset form data
        setFormData({
            vitals: { temperature: '', BP: '', RR: '', HR: '', weight: '' },
            iop_Input: { oralFeed: '', NG_Feed: '', IVFluid: '', TPN: '', calories: '' },
            iop_Output: { urine: { value: '', checked: false }, vomiting: { value: '', checked: false }, drain: { value: '', checked: false }, ngAspirate: { value: '', checked: false }, stool: { value: '', checked: false } },
            fluid_Balance: { input: '', output: '', difference: '' },
            blood_Sugar: { BSR: '', comment: '' },
        });
        setIsEditMode(false);
        setEditIndex(null);
        setIsComponentVisible(false);
    };

    useEffect(() => {
        if (data) {
            setSaveData(data);
            onChange(data)
        }
    }, [data])
    useEffect(() => {
        if (otherTabsData) {
            setSearchId(otherTabsData?._id)
        }
    }, [otherTabsData])

    useEffect(() => {
        if (clickSave) {
            handleSave(false, searchId);
        }
    }, [clickSave])

    return (
        <Box>
            <Box
                sx={{
                    bgcolor: "rgba(233, 233, 237, 1)",
                    padding: 0.25,
                    textAlign: "center",
                    mt: 0.5,
                    mb: 0.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '30px',
                }}
            >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    Vitals and IOP
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isComponentVisible}
                            onChange={() => setIsComponentVisible(!isComponentVisible)}
                            color="primary"
                        />
                    }
                    label="+Add Vital"
                />
            </Box>
            {
                isComponentVisible && <>
                    <Grid container spacing={1} sx={{ mt: 1.2 }}>
                        <Grid item md={1} sm={1} xs={12}>
                            {/* <Typography sx={{fontSize:'12px', fontWeight:'600',pt:1}}>Vitals</Typography> */}
                            <FormControlLabel
                                control={
                                    <Checkbox size='small'
                                        checked={showVitals}
                                        onChange={() => setShowVitals(!showVitals)}
                                        name="showInput"
                                    />
                                }
                                label={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>Vitals</span>}
                            />
                        </Grid>
                        {
                            showVitals && <>
                                <Grid item md={2.2} sm={3} xs={4}>
                                    <TextField
                                        label="Temp"
                                        placeholder="92-107"
                                        size="small"
                                        className='search-field'
                                        value={formData?.vitals?.temperature}
                                        name="vitals.temperature"
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            if (/^\d{0,3}(\.\d{0,1})?$/.test(value)) {
                                                handleChange(e);
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>F</span>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            !!formData.vitals.temperature &&
                                            (parseFloat(formData.vitals.temperature) < 92 || parseFloat(formData.vitals.temperature) > 107)
                                        }
                                        helperText={null}
                                    />
                                </Grid>
                                <Grid item md={2.2} sm={3} xs={4}>
                                    <TextField
                                        label="BP"
                                        placeholder="120/80"
                                        size="small"
                                        className='search-field'
                                        value={formData?.vitals?.BP}
                                        name="vitals.BP"
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            if (value === "") {
                                                handleChange(e);
                                                return;
                                            }
                                            const regex = /^(\d{1,3})\/?(\d{0,3})$/;
                                            if (regex.test(value)) {
                                                const parts = value.split('/');
                                                const firstPart = parseInt(parts[0], 10);
                                                const secondPart = parts[1] ? parseInt(parts[1], 10) : 0;
                                                if (firstPart >= 1 && firstPart <= 300 && secondPart <= firstPart) {
                                                    handleChange(e);
                                                }
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>mgHg</span>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2.2} sm={3} xs={4}>
                                    <TextField
                                        label="RR" // Label for the input
                                        placeholder="1-50"
                                        size="small"
                                        value={formData?.vitals?.RR}
                                        name="vitals.RR"
                                        className='search-field'
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value) && (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 50))) {
                                                handleChange(e);
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>MIn</span>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={!!formData.vitals.RR && (parseInt(formData.vitals.RR) < 1 || parseInt(formData.vitals.RR) > 50)}
                                        helperText={
                                            formData.vitals.rr &&
                                                (parseInt(formData.vitals.RR) < 1 || parseInt(formData.vitals.RR) > 50)
                                                ? 'Please enter a value between 1 and 50'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item md={2.2} sm={3} xs={4}>
                                    <TextField
                                        label="HR"
                                        placeholder="60-100"
                                        size="small"
                                        className='search-field'
                                        value={formData?.vitals?.HR}
                                        name="vitals.HR"
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            if (/^\d{0,3}$/.test(value)) {
                                                handleChange(e);
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>MIn</span>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2.2} sm={3} xs={4}>
                                    <TextField
                                        label="SPO₂"
                                        placeholder="0-100"
                                        size="small"
                                        name='vitals.spo'
                                        value={formData?.vitals?.spo}
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            if (value <= 100) {
                                                handleChange(e);
                                            }
                                        }}
                                        fullWidth
                                        className='search-field'
                                        type='number'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>%</span>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Box mt={1.5}>
                        <Grid container spacing={1}>
                            <Grid item md={1} sm={1} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small'
                                            checked={showInput}
                                            onChange={() => setShowInput(!showInput)} // Toggle the checkbox state
                                            name="showInput"
                                        />
                                    }
                                    label={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>Input</span>}
                                />
                            </Grid>
                            {
                                showInput && <>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="NG Feed"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Input.NG_Feed"
                                            value={formData?.iop_Input?.NG_Feed}
                                            size='small'
                                            className='search-field'
                                            onChange={handleChange}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span>ml</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="Oral Feed"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Input.oralFeed"
                                            value={formData?.iop_Input?.oralFeed}
                                            className='search-field'
                                            size='small'
                                            onChange={handleChange}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span style={{ fontSize: '12px' }}>ml</span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="IV Fluid"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Input.IVFluid"
                                            value={formData?.iop_Input?.IVFluid}
                                            className='search-field'
                                            size='small'
                                            onChange={handleChange}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span>ml</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="TPN"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Input.TPN"
                                            value={formData?.iop_Input?.TPN}
                                            className='search-field'
                                            size='small'
                                            onChange={handleChange}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span>ml</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="Calories"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Input.calories"
                                            value={formData?.iop_Input?.calories}
                                            className='search-field'
                                            size='small'
                                            onChange={handleChange}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span>kcal</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                    <Box >
                        <Grid container spacing={1}>
                            <Grid item md={1} sm={1} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small'
                                            checked={showOutput}
                                            onChange={() => setShowOutput(!showOutput)} // Toggle the checkbox state
                                            name="showOutput"
                                        />
                                    }
                                    label={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>Output</span>}
                                />
                            </Grid>

                            {
                                showOutput && <>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData?.iop_Output?.urine?.checked}
                                                    onChange={handleChange}
                                                    name="iop_Output.urine.checked"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Urine"
                                        />
                                        {formData?.iop_Output?.urine.checked && <TextField
                                            label="Details"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Output.urine.value"
                                            value={formData?.iop_Output?.urine?.value}
                                            className="search-field"
                                            size="small"
                                            onChange={handleChange}
                                            disabled={!formData?.iop_Output?.urine?.checked}
                                        />}
                                    </Grid>

                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData?.iop_Output?.vomiting?.checked}
                                                    onChange={handleChange}
                                                    name="iop_Output.vomiting.checked"
                                                    size="small"
                                                    color="primary"
                                                />
                                            }
                                            label="Vomiting"
                                        />
                                        {formData?.iop_Output?.vomiting?.checked && <TextField
                                            label="Details"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Output.vomiting.value"
                                            value={formData?.iop_Output?.vomiting?.value}
                                            className="search-field"
                                            size="small"
                                            onChange={handleChange}
                                            disabled={!formData?.iop_Output?.vomiting?.checked}
                                        />}
                                    </Grid>

                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData?.iop_Output?.drain?.checked}
                                                    onChange={handleChange}
                                                    name="iop_Output.drain.checked"
                                                    size="small"
                                                    color="primary"
                                                />
                                            }
                                            label="Drain"
                                        />
                                        {formData?.iop_Output?.drain?.checked && <TextField
                                            label="Details"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Output.drain.value"
                                            value={formData?.iop_Output?.drain?.value}
                                            className="search-field"
                                            size="small"
                                            onChange={handleChange}
                                            disabled={!formData?.iop_Output?.drain?.checked}
                                        />}
                                    </Grid>

                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData?.iop_Output?.ngAspirate?.checked}
                                                    onChange={handleChange}
                                                    name="iop_Output.ngAspirate.checked"
                                                    size="small"
                                                    color="primary"
                                                />
                                            }
                                            label="NG Aspirate"
                                        />
                                        {formData?.iop_Output?.ngAspirate?.checked && <TextField
                                            label="Details"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Output.ngAspirate.value"
                                            value={formData?.iop_Output?.ngAspirate?.value}
                                            className="search-field"
                                            size="small"
                                            onChange={handleChange}
                                            disabled={!formData?.iop_Output?.ngAspirate?.checked}
                                        />}
                                    </Grid>

                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData?.iop_Output?.stool?.checked}
                                                    onChange={handleChange}
                                                    name="iop_Output.stool.checked"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Stool"
                                        />
                                        {formData?.iop_Output?.stool?.checked && <TextField
                                            label="Details"
                                            variant="outlined"
                                            fullWidth
                                            name="iop_Output.stool.value"
                                            value={formData?.iop_Output?.stool?.value}
                                            className="search-field"
                                            size="small"
                                            onChange={handleChange}
                                            disabled={!formData?.iop_Output?.stool?.checked}
                                        />}
                                    </Grid>
                                </>
                            }
                        </Grid>

                    </Box>
                    <Box mt={1.5} >
                        <Grid container spacing={2}>
                            <Grid item md={1} sm={1} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small'
                                            checked={showFluid}
                                            onChange={() => setShowFluid(!showFluid)} // Toggle the checkbox state
                                            name="showFluid"
                                        />
                                    }
                                    label={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>Fluid Balance</span>}
                                />
                            </Grid>
                            {
                                showFluid && <>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="Input"
                                            variant="outlined"
                                            fullWidth
                                            name="fluid_Balance.input"
                                            value={formData?.fluid_Balance?.input}
                                            className='search-field'
                                            onChange={handleChange}
                                            size='small'
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <span style={{ fontSize: '12px' }}>ml</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="Output"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            name="fluid_Balance.output"
                                            value={formData?.fluid_Balance?.output}
                                            className='search-field'
                                            onChange={handleChange}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <span style={{ fontSize: '12px' }}>ml</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="Difference"
                                            variant="outlined"
                                            fullWidth
                                            name="fluid_Balance.difference"
                                            value={formData?.fluid_Balance?.difference}
                                            onChange={handleChange}
                                            size='small'
                                            className='search-field'
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <span style={{ fontSize: '12px' }}>ml</span>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="BSR"
                                            variant="outlined"
                                            fullWidth
                                            name="blood_Sugar.BSR"
                                            value={formData?.blood_Sugar?.BSR}
                                            size='small'
                                            onChange={handleChange}
                                            className='search-field'
                                        />
                                    </Grid>
                                    <Grid item md={2.2} sm={3} xs={4}>
                                        <TextField
                                            label="Comment"
                                            variant="outlined"
                                            fullWidth
                                            name="blood_Sugar.comment"
                                            value={formData?.blood_Sugar?.comment}
                                            size='small'
                                            onChange={handleChange}
                                            className='search-field'
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mt={2} mr={2.2}>
                        <Button onClick={handleSaveIOP} variant='contained' size='small'>
                            {isEditMode ? 'Update' : 'Save'}
                        </Button>
                    </Box>
                </>
            }
            <Box mt={2}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', marginBottom: '8px' }}>Vital & IOP History</Typography>
                <Grid container spacing={2}>
                    {Array.isArray(saveData?.IOP_Chart) && saveData?.IOP_Chart?.map((item: any, idx: any) => {
                        const { vitals, iop_Input, iop_Output, fluid_Balance, blood_Sugar } = item;
                        const doctorName = item?.doctorName || '';
                        const dateTime = item?.dateTime || 'DD.MM.YYYY hh:mm:ss A';
                        return (
                            <Grid item md={6} sm={12} xs={12} key={idx}>
                                <Box sx={{ backgroundColor: '#f9f9f9', padding: 2, borderRadius: 2, boxShadow: 2 }}>
                                    <Box mb={2}>
                                        <Grid container spacing={1} alignItems="center">
                                            {[
                                                { label: 'BP', value: vitals?.BP || '120/80', unit: '' },
                                                { label: 'Temp', value: vitals?.temperature || '120', unit: 'F' },
                                                { label: 'RR', value: vitals?.RR || '00', unit: '/Min' },
                                                { label: 'HR', value: vitals?.HR || '120', unit: '/Min' },
                                                { label: 'SPO₂', value: vitals?.spo || '', unit: '%' },
                                            ]?.map((item, i) => (
                                                <Grid item xs={2.4} key={i}>
                                                    <Typography sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '2px' }}>
                                                        <h3 style={{ fontWeight: 'bold' }}>{item?.label}</h3> : {item?.value} {item?.unit}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Divider sx={{ width: '100%', my: 1, borderColor: '#e0e0e0' }} />
                                    <Box mb={2} >
                                        <Grid container spacing={1}>
                                            <Grid item md={1.5}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: 'blue' }}>Input</Typography>
                                            </Grid>
                                            {[
                                                { label: 'Oral Feed', value: iop_Input?.oralFeed || '00 ml' },
                                                { label: 'Oral Fluid', value: iop_Input?.NG_Feed || '00 ml' },
                                                { label: 'IV Fluid', value: iop_Input?.IVFluid || '00 ml' },
                                                { label: 'TPN', value: iop_Input?.TPN || '00 ml' },
                                                { label: 'Calories', value: iop_Input?.calories || '00 kcal' }
                                            ]?.map((item, i) => (
                                                <Grid item xs={2.1} key={i}>
                                                    <Typography sx={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                        <h3 style={{ fontWeight: 'bold' }}>{item?.label}</h3> : {item?.value}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Divider sx={{ width: '100%', my: 1, borderColor: '#e0e0e0' }} />
                                    <Box mb={2}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item md={1.5}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: 'blue' }}>Output</Typography>
                                            </Grid>
                                            {[
                                                { label: 'Urine', value: iop_Output?.urine?.checked ? `Yes (${iop_Output?.urine?.value || '...'})` : 'No' },
                                                { label: 'Vomiting', value: iop_Output?.vomiting?.checked ? `Yes (${iop_Output?.vomiting?.value || '...'})` : 'No' },
                                                { label: 'Drain', value: iop_Output?.drain?.checked ? `Yes (${iop_Output?.drain?.value || '...'})` : 'No' },
                                                { label: 'NG Aspirate', value: iop_Output?.ngAspirate?.checked ? `Yes (${iop_Output?.ngAspirate?.value || '...'})` : 'No' },
                                                { label: 'Stool', value: iop_Output?.stool?.checked ? `Yes (${iop_Output?.stool?.value || '...'})` : 'No' }
                                            ]?.map((item, i) => (
                                                <Grid item xs={2.1} key={i}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography sx={{ fontSize: '12px', display: 'flex', fontWeight: 'bold' }}>
                                                            {item?.label}:
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '12px' }}>
                                                            <Tooltip title={item?.value} placement='top' arrow>
                                                                <span>
                                                                    {item?.value?.length > 7 ? `${item?.value?.slice(0, 7)}...` : item?.value}
                                                                </span>
                                                            </Tooltip>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Divider sx={{ width: '100%', my: 1, borderColor: '#e0e0e0' }} />
                                    <Box mb={2} >
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item md={1.5}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: 'blue' }}>Fluid Balance</Typography>
                                            </Grid>
                                            {[
                                                { label: 'InPut', value: fluid_Balance?.input || '00 ml' },
                                                { label: 'OutPut', value: fluid_Balance?.output || '00 ml' },
                                                { label: 'Difference', value: fluid_Balance?.difference || '00 ml' },
                                                { label: 'BSR', value: blood_Sugar?.BSR || '00' },
                                                { label: 'Comment', value: blood_Sugar?.comment || '...' }
                                            ]?.map((item, i) => (
                                                <Grid item xs={2.1} key={i}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', display: 'flex' }}>
                                                            {item?.label}:
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '12px' }}>
                                                            <Tooltip title={item?.value} placement='top' arrow>
                                                                <span>
                                                                    {item?.value?.length > 7 ? `${item?.value.slice(0, 7)}...` : item?.value}
                                                                </span>
                                                            </Tooltip>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Divider sx={{ width: '100%', my: 1, borderColor: '#e0e0e0' }} />

                                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: 'blue' }}>Entered By :</Typography>
                                        <Typography sx={{ fontSize: '12px' }}>{doctorName}</Typography>
                                        <Typography sx={{ fontSize: '12px' }}>{dateTime}</Typography>
                                        {doctorName === profile?.data?.fullName && (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ textTransform: 'none' }}
                                                onClick={() => handleEdit(idx)}
                                                disabled={!isDateTimeGreaterThan30MinutesAgo(dateTime)}
                                            >
                                                <EditOutlinedIcon sx={{ fontSize: '14px' }} /> Edit
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
};

export default VitalAndIOPChart;
