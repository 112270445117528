// testGroupSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface Test {
  id: number;
  name: string;
  // Add more properties as needed
}

interface InitialState {
  tests: Test[];
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: string | null;
}

// Async thunk action to fetch tests by group ID from the API
export const fetchTestsByGroupId = createAsyncThunk<
  Test[],
  string, // Group ID
  { rejectValue: string }
>(
  'tests/fetchTestsByGroupId',
  async (groupId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }
      
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${process.env.REACT_APP_PATHOLOGY_URL}/tests/getTestsByGroupId?groupId=${groupId}`, { headers });
      return response.data?.data;
    } catch (error) {
      console.error('Error fetching tests by group ID:', error);
      return rejectWithValue('Failed to fetch tests by group ID');
    }
  }
);

// Initial state
const initialState: InitialState = {
  tests: [],
  loading: 'idle',
  error: null,
};

// Create test group slice
const testGroupSliceByID = createSlice({
  name: 'testGroupSliceByID',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestsByGroupId.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchTestsByGroupId.fulfilled, (state, action) => {        
        state.loading = 'fulfilled';
        state.tests = action.payload;
        state.error = null;
      })
      .addCase(fetchTestsByGroupId.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.payload || 'Failed to fetch tests by group ID';
      });
  },
});

export default testGroupSliceByID.reducer;
