import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state
const initialState = {
  profile: null,
  loading: false,
  error: null,
};

// Function to get token from localStorage
const getToken = () => {
  return localStorage.getItem('token');
};

// Define an asynchronous thunk action to fetch profile data from the API
export const fetchAssignedDoctorsData = createAsyncThunk(
  'assignedDoctors/fetchAssignedDoctors',
  async (_, { rejectWithValue }) => { // Use destructuring to access `rejectWithValue` function
    try {
      const token = getToken();
      if (!token) {
        throw new Error('Token not found');
      }
      
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message); // Use `rejectWithValue` to pass error message to reducer
    }
  }
);

// Create a slice for the profile data
const assignedDoctorsSlice = createSlice({
  name: 'assignedDoctorsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedDoctorsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssignedDoctorsData.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchAssignedDoctorsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Access error message from action.payload
      });
  },
});

// Export actions and reducer
export default assignedDoctorsSlice.reducer;
