// import React, { FC, useState, useEffect, useRef } from 'react';
// import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// import IosShareIcon from '@mui/icons-material/IosShare';
// import ReactCrop, { Crop } from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';

// interface CropDialogProps {
//     open: boolean;
//     onClose: () => void;
//     onComplete: (croppedImage: Blob) => void;
//     selectedImageType: any;
// }

// const CropDialog: FC<CropDialogProps> = ({ open, onClose, onComplete, selectedImageType }) => {
//     const [imageSrc, setImageSrc] = useState<string | null>(null);
//     const [selectedFile, setSelectedFile] = useState<File | null>(null);
//     const [crop, setCrop] = useState<Crop>({
//         unit: '%',
//         width: 100,
//         height: 20,
//         x: 0,
//         y: 0,
//     });
//     const imageRef = useRef<HTMLImageElement | null>(null);

//     // Reset states when the dialog opens or closes
//     useEffect(() => {
//         if (open) {
//             setImageSrc(null);
//             setSelectedFile(null);
//             setCrop({
//                 unit: '%',
//                 width: 100,
//                 height: 10,
//                 x: 0,
//                 y: 0,
//             });
//         }
//     }, [open]);

//     const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         if (event.target.files && event.target.files.length > 0) {
//             const file = event.target.files[0];
//             setSelectedFile(file);
//             const imageUrl = URL.createObjectURL(file);
//             setImageSrc(imageUrl);
//         }
//     };

//     const handleSave = async () => {
//         if (imageRef.current && crop.width && crop.height) {
//             const canvas = document.createElement('canvas');
//             const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
//             const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
//             canvas.width = crop.width * scaleX;
//             canvas.height = crop.height * scaleY;
//             const ctx = canvas.getContext('2d');
//             if (ctx) {
//                 ctx.drawImage(
//                     imageRef.current,
//                     crop.x * scaleX,
//                     crop.y * scaleY,
//                     crop.width * scaleX,
//                     crop.height * scaleY,
//                     0,
//                     0,
//                     crop.width * scaleX,
//                     crop.height * scaleY
//                 );
//                 canvas.toBlob((blob: Blob | null) => {
//                     if (blob) {
//                         onComplete(blob);
//                         onClose();
//                     }
//                 });
//             }
//         }
//     };

//     const handleCropChange = (newCrop: Crop) => {
//         if (imageRef.current) {
//             const imageWidth = imageRef.current.naturalWidth;
//             const imageHeight = imageRef.current.naturalHeight;

//             // Ensure crop box stays within the image boundaries
//             const adjustedX = Math.min(Math.max(newCrop.x, 0), imageWidth - newCrop.width);
//             const adjustedY = Math.min(Math.max(newCrop.y, 0), imageHeight - newCrop.height);

//             setCrop({
//                 ...newCrop,
//                 x: adjustedX,
//                 y: adjustedY,
//             });
//         }
//     };

//     return (
//         <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
//             <DialogTitle>
//                 <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
//                     Upload {selectedImageType}
//                 </Typography>
//             </DialogTitle>
//             <DialogContent>
//                 <Box display="flex" flexDirection="column" alignItems="center" gap="6px">
//                     <Box sx={{ display: 'flex', flexDirection: 'column', color: 'rgba(5, 95, 252, 1)', justifyContent: 'center', alignItems: 'center' }}>
//                         <IosShareIcon sx={{ color: 'rgba(5, 95, 252, 1)' }} />
//                         <Typography sx={{ fontSize: '14px', fontWeight: '600', fontStyle: 'italic', textTransform: 'capitalize' }}>
//                             Upload {selectedImageType} Image
//                         </Typography>
//                     </Box>

//                     {selectedFile && imageSrc && (
//                         <Box position="relative" width="100%" height="350px">
//                             <ReactCrop
//                                 crop={crop}
//                                 onChange={handleCropChange} // Update crop box based on user input
//                                 minWidth={50}
//                                 minHeight={20}
//                                 keepSelection
//                             >
//                                 <img
//                                     ref={imageRef}
//                                     src={imageSrc}
//                                     alt="Crop Image"
//                                     style={{
//                                         objectFit: 'contain', // Ensures the image fits within the container without distortion
//                                         // Ensures the image fits within the container height
//                                     }}
//                                 />
//                             </ReactCrop>
//                         </Box>
//                     )}

//                     <input
//                         accept="image/*"
//                         style={{ display: 'none' }}
//                         id="profile-pic-upload"
//                         type="file"
//                         onChange={handleFileChange}
//                     />

//                 </Box>
//             </DialogContent>

//             <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                 <label htmlFor="profile-pic-upload">
//                     <Button variant="contained" color="primary" component="span" startIcon={<PhotoCamera />}>
//                         Upload Photo
//                     </Button>
//                 </label>
//             </DialogContent>

//             <DialogActions>
//                 <Button onClick={onClose} color="primary">
//                     Cancel
//                 </Button>
//                 <Button onClick={handleSave} color="primary" disabled={!selectedFile}>
//                     Save
//                 </Button>
//             </DialogActions>

//         </Dialog>
//     );
// };

// export default CropDialog;
import React, { FC, useState, useEffect, useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IosShareIcon from '@mui/icons-material/IosShare';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface CropDialogProps {
    open: boolean;
    onClose: () => void;
    onComplete: (croppedImage: Blob) => void;
    selectedImageType: any;  // Updated type to accept 'header' or 'footer'
}

const CropDialog: FC<CropDialogProps> = ({ open, onClose, onComplete, selectedImageType }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: '%',
        width: 100,
        height: 10,
        x: 0,
        y: 0,
    });
    const imageRef = useRef<HTMLImageElement | null>(null);

    // Reset states when the dialog opens or closes
    useEffect(() => {
        if (open) {
            setImageSrc(null);
            setSelectedFile(null);

            // Set initial crop based on selectedImageType (header or footer)
            setCrop((prevCrop) => {
                if (selectedImageType === 'header') {
                    // Set the crop to the top of the image for header
                    return { ...prevCrop, x: 0, y: 0, height: 10 };
                } else if (selectedImageType !== 'header') {
                    // Set the crop to the bottom of the image for footer
                    return { ...prevCrop, x: 0, y: 95, height: 6 }; // Initially set to bottom (100%)
                }
                return prevCrop;
            });
        }
    }, [open, selectedImageType]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            const imageUrl = URL.createObjectURL(file);
            setImageSrc(imageUrl);
        }
    };

    // const handleSave = async () => {
    //     if (imageRef.current && crop.width && crop.height) {
    //         const canvas = document.createElement('canvas');
    //         const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    //         const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    //         canvas.width = crop.width * scaleX;
    //         canvas.height = crop.height * scaleY;
    //         const ctx = canvas.getContext('2d');
    //         if (ctx) {
    //             ctx.drawImage(
    //                 imageRef.current,
    //                 crop.x * scaleX,
    //                 crop.y * scaleY,
    //                 crop.width * scaleX,
    //                 crop.height * scaleY,
    //                 0,
    //                 0,
    //                 crop.width * scaleX,
    //                 crop.height * scaleY
    //             );
    //             canvas.toBlob((blob: Blob | null) => {
    //                 if (blob) {
    //                     onComplete(blob);
    //                     onClose();
    //                 }
    //             });
    //         }
    //     }
    // };



    const handleSave = async () => {
        if (imageRef.current && crop.width && crop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
            const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
            canvas.width = crop.width * scaleX;
            canvas.height = crop.height * scaleY;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(
                    imageRef.current,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width * scaleX,
                    crop.height * scaleY
                );

                const dataUrl = canvas.toDataURL('image/png');
                const blob = await fetch(dataUrl).then(res => res.blob());

                onComplete(blob);
                onClose();
            }
        }
    };

    const handleCropChange = (newCrop: Crop) => {
        if (imageRef.current) {
            const imageWidth = imageRef.current.naturalWidth;
            const imageHeight = imageRef.current.naturalHeight;

            // Ensure crop box stays within the image boundaries
            const adjustedX = Math.min(Math.max(newCrop.x, 0), imageWidth - newCrop.width);
            const adjustedY = Math.min(Math.max(newCrop.y, 0), imageHeight - newCrop.height);

            setCrop({
                ...newCrop,
                x: adjustedX,
                y: adjustedY,
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                    Upload {selectedImageType}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center" gap="6px">
                    <Box sx={{ display: 'flex', flexDirection: 'column', color: 'rgba(5, 95, 252, 1)', justifyContent: 'center', alignItems: 'center' }}>
                        <IosShareIcon sx={{ color: 'rgba(5, 95, 252, 1)' }} />
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', fontStyle: 'italic', textTransform: 'capitalize' }}>
                            Upload {selectedImageType} Image
                        </Typography>
                    </Box>

                    {selectedFile && imageSrc && (
                        <Box position="relative" width="100%" height="350px">
                            <ReactCrop
                                crop={crop}
                                onChange={handleCropChange} // Update crop box based on user input
                                minWidth={50}
                                minHeight={20}
                                keepSelection
                            >
                                <img
                                    ref={imageRef}
                                    src={imageSrc}
                                    alt="Crop Image"
                                    style={{
                                        objectFit: 'contain', // Ensures the image fits within the container without distortion
                                    }}
                                />
                            </ReactCrop>
                        </Box>
                    )}

                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="profile-pic-upload"
                        type="file"
                        onChange={handleFileChange}
                    />
                </Box>
            </DialogContent>

            <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <label htmlFor="profile-pic-upload">
                    <Button variant="contained" color="primary" component="span" startIcon={<PhotoCamera />}>
                        Upload Photo
                    </Button>
                </label>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!selectedFile}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CropDialog;
