
import { Box, Button, Dialog, Divider, Grid, IconButton, Typography } from "@mui/material";
import { FC, useState, useEffect } from "react";
import IosShareIcon from '@mui/icons-material/IosShare';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { string } from "yup";
import CropDialog from "../../components/cropProfile/CropDialog";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const HeaderFooterComponent = () => {
    const [openCrop, setOpenCrop] = useState(false);
    const [headerImage, setHeaderImage] = useState<string | null>(null);
    const [footerImage, setFooterImage] = useState<string | null>(null);
    const [selectedImageType, setSelectedImageType] = useState<"header" | "footer" | null>(null);
    const [aspectRatio, setAspectRatio] = useState<number>(1);
    const location = "default"
    const profile = useSelector((state: any) => state.profileSlice.profile);
    useEffect(() => {
        const fetchHeaderFooter = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/cms/get_prescription_header_and_footer?userId=${profile?.data._id}&location=${location}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        'accept': '*/*'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setHeaderImage(data?.data.headerUrl || null);
                    setFooterImage(data?.data.footerUrl || null);
                } else {
                    console.error("Failed to fetch header and footer");
                }
            } catch (error) {
                console.error("Error fetching header and footer:", error);
            }
        };

        fetchHeaderFooter();
    }, [profile?.data._id]);
    const handleImageClick = (type: "header" | "footer") => {
        setSelectedImageType(type);
        setAspectRatio(type === "header" ? 16 / 2.8 : 16 / 1.5);
        setOpenCrop(true);
    };
    // const handleCropComplete = async (croppedImage: Blob) => {
    //     setOpenCrop(false);

    //     const formData = new FormData();
    //     formData.append('file', croppedImage);

    //     const apiUrl = selectedImageType === "header"
    //         ? `${process.env.REACT_APP_ADMIN_API_URL}/cms/set_prescription_header?userId=${profile?.data._id}&location=${location}`
    //         : `${process.env.REACT_APP_ADMIN_API_URL}/cms/set_prescription_footer?userId=${profile?.data._id}&location=${location}`;

    //     try {
    //         const response = await fetch(apiUrl, {
    //             method: 'POST',
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
    //             },
    //             body: formData,
    //         });
    //         if (response.ok) {
    //             const url = URL.createObjectURL(croppedImage);
    //             selectedImageType === "header" ? setHeaderImage(url) : setFooterImage(url);
    //         } else {
    //             console.error("Failed to upload image");
    //         }
    //     } catch (error) {
    //         console.error("Error uploading image:", error);
    //     }
    // };



    const handleCropComplete = async (croppedImage: Blob) => {
        setOpenCrop(false);

        const formData = new FormData();
        formData.append('file', croppedImage, 'cropped-image.png');

        const apiUrl = selectedImageType === "header"
            ? `${process.env.REACT_APP_ADMIN_API_URL}/cms/set_prescription_header?userId=${profile?.data._id}&location=${location}`
            : `${process.env.REACT_APP_ADMIN_API_URL}/cms/set_prescription_footer?userId=${profile?.data._id}&location=${location}`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            });

            if (response.ok) {
                const url = URL.createObjectURL(croppedImage);
                selectedImageType === "header" ? setHeaderImage(url) : setFooterImage(url);
            } else {
                console.error("Failed to upload image");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };
    const handleDeleteImage = async (type: "header" | "footer") => {
        const apiUrl = type === "header"
            ? `${process.env.REACT_APP_ADMIN_API_URL}/cms/remove_prescription_header?userId=${profile?.data._id}&location=${location}`
            : `${process.env.REACT_APP_ADMIN_API_URL}/cms/remove_prescription_footer?userId=${profile?.data._id}&location=${location}`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });

            if (response.ok) {
                if (type === "header") {
                    setHeaderImage(null);
                } else {
                    setFooterImage(null);
                }
                toast.error(`${type} image deleted successfully!`);
            } else {
                console.error(`Failed to delete ${type} image`);
            }
        } catch (error) {
            console.error(`Error deleting ${type} image:`, error);
        }
    };
    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 1 }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Media Upload (Header & Footer)
                </Typography>
            </Box>
            <Divider sx={{ px: 1 }} />

            <Grid container spacing={2} sx={{ mt: .2, px: 1, mb: .5 }}>



                <Grid item md={7} sm={12} xs={12}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "400", margin: "5px" }}>
                        <span style={{ color: "red" }}><b>Note: </b></span>Please upload an image document in A4 size or an equivalent page containing your header or footer or both. This will allow you to accurately capture the header and footer details for the prescription page setup.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                Upload Header
                            </Typography>
                            <Box
                                sx={{ height: "135px", backgroundColor: "rgba(5, 95, 252, 0.1)", borderRadius: "10px", border: "2px dashed rgba(5, 95, 252, 1)", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                                onClick={() => handleImageClick("header")}
                            >
                                {headerImage ? (
                                    <img src={headerImage} alt="Header" style={{ width: "100%", height: "100%", objectFit: "contain", backgroundRepeat: "no-repeat" }} />
                                ) : (
                                    <Box sx={{ display: "flex", flexDirection: "column", color: "rgba(5, 95, 252, 1)", justifyContent: "center", alignItems: "center" }}>
                                        <IosShareIcon sx={{ color: "rgba(5, 95, 252, 1)" }} />
                                        <Typography sx={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic" }}>
                                            Upload Header Image
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                <IconButton onClick={() => handleImageClick("header")}>
                                    <EditIcon
                                        sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            background: "#055FFC",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </IconButton>
                                {headerImage ? <IconButton onClick={() => handleDeleteImage("header")}>
                                    <DeleteOutlineIcon
                                        sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            background: "red",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </IconButton> : <IconButton onClick={() => handleDeleteImage("header")} disabled>
                                    <DeleteOutlineIcon
                                        sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            background: "red",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </IconButton>}

                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                Upload Footer
                            </Typography>
                            <Box
                                sx={{ height: "135px", backgroundColor: "rgba(5, 95, 252, 0.1)", borderRadius: "10px", border: "2px dashed rgba(5, 95, 252, 1)", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                                onClick={() => handleImageClick("footer")}
                            >
                                {footerImage ? (
                                    <img src={footerImage} alt="Footer" style={{ width: "100%", height: "100%", objectFit: "contain", backgroundRepeat: "no-repeat" }} />
                                ) : (
                                    <Box sx={{ display: "flex", flexDirection: "column", color: "rgba(5, 95, 252, 1)", justifyContent: "center", alignItems: "center" }}>
                                        <IosShareIcon sx={{ color: "rgba(5, 95, 252, 1)" }} />
                                        <Typography sx={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic" }}>
                                            Upload  Footer Image
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                <IconButton onClick={() => handleImageClick("footer")}>
                                    <EditIcon
                                        sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            background: "#055FFC",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </IconButton>
                                {footerImage ? <IconButton onClick={() => handleDeleteImage("footer")}>
                                    <DeleteOutlineIcon
                                        sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            background: "red",
                                            borderRadius: "4px",
                                        }}

                                    />
                                </IconButton> : <IconButton onClick={() => handleDeleteImage("footer")} disabled>
                                    <DeleteOutlineIcon
                                        sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            background: "red",
                                            borderRadius: "4px",
                                        }}

                                    />
                                </IconButton>}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item md={5} sm={12} xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            height: '80vh',
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            position: 'relative',
                            overflow: 'hidden',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '0px',
                                left: '0',
                                right: '0',
                                height: '100px',
                                backgroundImage: headerImage ? `url(${headerImage})` : 'none',
                                backgroundSize: '100% 100%', // Stretch to fill the container
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: 'center',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0px',
                                left: '0',
                                right: '0',
                                height: '50px',
                                backgroundImage: footerImage ? `url(${footerImage})` : 'none',
                                backgroundSize: '100% 100%', // Stretch to fill the container
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: 'center',
                            }}
                        />
                        <Typography variant="body2" sx={{ textAlign: 'center', opacity: 0.3 }}>
                            A4 Paper Preview
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <CropDialog
                // aspect={aspectRatio}
                open={openCrop}
                onClose={() => setOpenCrop(false)}
                onComplete={handleCropComplete}
                selectedImageType={selectedImageType}
            /></>
    )
}

export default HeaderFooterComponent