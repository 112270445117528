import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box, Typography, MenuItem, Select, FormControl, InputLabel, IconButton, Collapse, Button, TablePagination
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PrintIcon from "@mui/icons-material/Print";
import dayjs from 'dayjs';
import { SelectChangeEvent } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Looks like a file icon
import SaveAltIcon from '@mui/icons-material/SaveAlt';




type DrWiseReport = {
  fullName: string;
  phonNumber: string;
  city: string;
  amount: number | null;
};

type Hospital = {
  _id: string;
  hospitalName: string;
  address: string;
  phoneNo: string;
  contractExpiryDate: string;
  totalAmount: number;
  drWiseReporting?: DrWiseReport[];
};

function OnlineRevenue() {
  const [hospitalData, setHospitalData] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50); // Default to 5 rows per page for better readability
  const [selectedMonth, setSelectedMonth] = useState<string>(dayjs().format('YYYY-MM'));
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const last12Months = Array.from({ length: 12 }, (_, index) =>
    dayjs().subtract(index, 'month').format('YYYY-MM')
  );

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setSelectedMonth(event.target.value);
  };

  const fetchHospitalData = async () => {
    setLoading(true);
    try {
      const [year, month] = selectedMonth.split('-');
      const fromDate = dayjs(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
      const toDate = dayjs(fromDate).endOf('month').format('YYYY-MM-DD');
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_API_URL}/projects/online_appointment_reporting?fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: '*/*',
          },
        }
      );

      if (response.data?.isSuccess) {
        setHospitalData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching hospital data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHospitalData();
  }, [selectedMonth]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when changing rows per page
  };

  const handleExpandRow = (rowIndex: number) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  const downloadHospitalReport = (hospital: Hospital) => {
    if (!hospital.drWiseReporting || hospital.drWiseReporting.length === 0) {
      alert('No doctor data');
      return;
    }
  
    // Format the data for the Excel sheet
    const formattedData = hospital.drWiseReporting.map((report, index) => ({
      No: index + 1,
      Name: report.fullName,
      Phone: report.phonNumber,
      City: report.city,
      Amount: report.amount || '-',
    }));
  
    const worksheet = XLSX.utils.json_to_sheet([]);
  
    // Add hospital name as the main heading
    XLSX.utils.sheet_add_aoa(worksheet, [[`${hospital.hospitalName}`]], { origin: 'A1' });
    worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
  
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 });
    XLSX.utils.sheet_add_json(worksheet, formattedData, { origin: 'A3', skipHeader: false });
  
    const workbook = XLSX.utils.book_new();
  
    // Truncate the sheet name to 31 characters
    const sheetName = `${hospital.hospitalName}_Report`.substring(0, 31);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, `${hospital.hospitalName}_Doctor_Wise_Report.xlsx`);
  };
  

  const downloadExcel = () => {
    const formattedData = hospitalData.map((hospital, index) => ({
      No: index + 1,
      "Hospital Name": hospital.hospitalName || "-",
      Address: hospital.address || "-",
      "Phone No": hospital.phoneNo || "-",
      "Expiry Date": hospital.contractExpiryDate
        ? dayjs(hospital.contractExpiryDate).format('DD-MMM-YYYY')
        : "-",
      "Total Amount": hospital.totalAmount || 0,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Hospital Data');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, `Hospital_Report_${selectedMonth}.xlsx`);
  };

  return (
    <Box sx={{ padding: '20px', background: '#fff' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Hospital Online Appointment Reporting
        </Typography>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="month-select-label" sx={{ fontSize: '12px' }}>
            Select Month
          </InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Select Month"
            sx={{ fontSize: '12px' }}
          >
            {last12Months.map((monthYear) => (
              <MenuItem key={monthYear} value={monthYear} sx={{ fontSize: '12px' }}>
                {dayjs(`${monthYear}-01`).format('MMMM YYYY')}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
      </Box>

      <table style={{ width: '100%', borderCollapse: 'collapse', background: '#fff', fontSize: '12px' }}>
        <thead>
          <tr style={{ backgroundColor: '#1976d2', color: '#fff', textAlign: 'left', fontSize: '12px' }}>
            <th style={{ padding: '6px' }}>No</th>
            <th>Hospital Name</th>
            <th>Address</th>
            <th>Phone No</th>
            <th>Expiry Date</th>
            <th>Total Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {hospitalData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((hospital, index) => (
            <React.Fragment key={hospital._id}>
              <tr style={{ borderBottom: '1px solid #ddd', fontSize: '12px' }}>
                <td style={{ padding: '6px' }}>{page * rowsPerPage + index + 1}</td>
                <td>{hospital.hospitalName || '-'}</td>
                <td>{hospital.address || '-'}</td>
                <td>{hospital.phoneNo || '-'}</td>
                <td>
                  {hospital.contractExpiryDate
                    ? dayjs(hospital.contractExpiryDate).format('DD-MMM-YYYY')
                    : '-'}
                </td>
                <td>{hospital.totalAmount || 0}</td>
                <td>
                  <IconButton onClick={() => handleExpandRow(index)} size="small">
                    {expandedRow === index ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                  </IconButton>
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    sx={{ marginRight: '45px', padding: '0', fontSize: '9px', alignSelf: 'flex-end', minWidth: 'auto' }}
                    onClick={() => downloadHospitalReport(hospital)}
                  >
                    <SaveAltIcon fontSize="small" /> {/* Save/Download icon */}
                  </Button>

                </td>
              </tr>
              {expandedRow === index && (
                <tr>
                  <td colSpan={7} style={{ backgroundColor: '#f9f9f9', padding: '6px' }}>
                    <Collapse in={expandedRow === index}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, fontSize: '11px' }}>
                        {hospital.drWiseReporting && hospital.drWiseReporting.length > 0 && (
                          <>
                            <table style={{ width: '100%', marginTop: '10px', fontSize: '11px' }}>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: 'left' }}>Name</th>
                                  <th style={{ textAlign: 'left' }}>Phone</th>
                                  <th style={{ textAlign: 'left' }}>City</th>
                                  <th style={{ textAlign: 'left' }}>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {hospital.drWiseReporting.map((report, idx) => (
                                  <tr key={idx}>
                                    <td>{report.fullName}</td>
                                    <td>{report.phonNumber}</td>
                                    <td>{report.city}</td>
                                    <td>{report.amount || '-'}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          </>
                        )}
                      </Box>
                    </Collapse>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={hospitalData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 200, 300]}
      />

      {/* Bottom button for entire report */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={downloadExcel}>
          Download Full Report Excel
        </Button>
      </Box>
    </Box>
  );
}

export default OnlineRevenue;
