import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Box,
    Typography,
    Divider,
    InputAdornment,
    Button,
    IconButton,
    Modal,
    Dialog,
    Tooltip,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSymptoms, selectSymptoms } from '../../../../redux/reducers/OPD/Admin/symptomsSlice';
import CustomAutocomplete from '../../../autocomplete-field';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from '@mui/icons-material/Add';
import ObsHistoryDialog from './ObsHistoryDialog';

interface HistoryData {
    onChange: (data: any) => void;
    otherTabsData: any;
    data: {
        presenting_Complaints: any;
        date_Time: any;
        past_medical_History: string;
        allergies: string;
        familyHistory: string;
        doctorName: string;
        detailed_History: string;
        past_surgical_History: string;
        past_gynaecologist_History: string;
        birth_History: string;
        // obsHistory: string;
        development_History: string;
        vaccination_History: string;
        nutritional_History: string;
        reason: string;
        comment: string;
        nutritional_Status: string
    };
    onhistorychnagedata: {
        knownDeseise: string;
        knownAllergies: string;
        ObsHistory: any;
        pregnancyInfo: any
    }
    profile: any
    onhistorychange: (onhistorychnagedata: any) => void;
    handleSaveObsData: (patiendata: any) => void
    opdData: any
}
const isOlderThan12Years = (providedDateStr: string): boolean => {
    // Get the current date
    const currentDate = new Date();

    // Parse the provided date string into a Date object (format: YYYY-MM-DD)
    const providedDate = new Date(providedDateStr);

    // Calculate the difference in years
    const differenceInTime = currentDate.getTime() - providedDate.getTime();
    const differenceInYears = differenceInTime / (1000 * 3600 * 24 * 365.25); // Convert milliseconds to years

    // Return true if the difference is greater than 12 years
    return differenceInYears > 12;
};
export const History: React.FC<HistoryData> = ({ onChange, data, otherTabsData, profile, onhistorychange, onhistorychnagedata, handleSaveObsData, opdData }) => {
    const currentDate = dayjs();    
    const [formData, setFormData] = useState<any>({
        detailed_History: '',
        past_medical_History: '',
        past_surgical_History: '',
        past_gynaecologist_History: '',
        birth_History: '',
        development_History: '',
        vaccination_History: '',
        reason: '',
        nutritional_History: '',
        nutritional_Status: '',
        family_history: '',
        comment: '',
        doctor_Name: profile?.data?.fullName,
        date_Time: currentDate
});
const [historydata, setHistoryData] = useState({
    ObsHistory: [],
    knownAllergies: "",
    knownDeseise: "",
});

const [isMultiline, setIsMultiline] = useState(false);
const [isMultilinetwo, setIsMultilinetwo] = useState(false);
const [isMultilinethree, setIsMultilinethree] = useState(false);
const [isMultilinefour, setIsMultilinefour] = useState(false);
const [isMultilinefive, setIsMultilinefive] = useState(false);
const [isMultilinesix, setIsMultilinesix] = useState(false);
const [isMultilineseven, setIsMultilineseven] = useState(false);
const [isMultilineeight, setIsMultilineeight] = useState(false);
const [allSymptoms, setAllSymptoms] = useState<any[]>([]);
const [selectedSymptoms, setSelectedSymptoms] = useState<{ [key: string]: string }>({});
const [openModal, setOpenModal] = useState(false);
const handleModalOpen = () => setOpenModal(true);
const handleModalClose = () => setOpenModal(false);
const symptoms = useSelector(selectSymptoms);
const dispatch = useDispatch();
const [selectedObsHistory, setSelectedObsHistory] = useState<any[]>([])
const [history, setHistory] = useState<any[]>([])

useEffect(() => {
    setHistory(opdData?.symptoms)
}, [opdData])

const handleChangeHistory = (key: any, value: any) => {
    setHistoryData(prevState => ({
        ...prevState,
        [key]: value
    }));
};
useEffect(() => {
    dispatch(fetchSymptoms("") as any);
}, [dispatch]);

useEffect(() => {
    setAllSymptoms(symptoms);
}, [symptoms]);

const handleFocus = () => {
    setIsMultiline(true);
};

const handleBlur = () => {
    setIsMultiline(false);
};
const handleFocustwo = () => {
    setIsMultilinetwo(true);
};

const handleBlurtwo = () => {
    setIsMultilinetwo(false);
};
const handleFocusthree = () => {
    setIsMultilinethree(true);
};

const handleBlurthree = () => {
    setIsMultilinethree(false);
}; const handleFocusfour = () => {
    setIsMultilinefour(true);
};

const handleBlurfour = () => {
    setIsMultilinefour(false);
}; const handleFocusfive = () => {
    setIsMultilinefive(true);
};

const handleBlurfive = () => {
    setIsMultilinefive(false);
}; const handleFocussix = () => {
    setIsMultilinesix(true);
};

const handleBlursix = () => {
    setIsMultilinesix(false);
}; const handleFocusseven = () => {
    setIsMultilineseven(true);
};

const handleBlurseven = () => {
    setIsMultilineseven(false);
}; const handleFocuseight = () => {
    setIsMultilineeight(true);
};

const handleBlureight = () => {
    setIsMultilineeight(false);
};

const handleChange = (field: string, value: string) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    // onChange({ ...updatedData, date_Time });
};


useEffect(() => {
    if (data) {
        setFormData((prevData: any) => {
            const updatedData = { ...prevData, ...data };
            // onChange({ ...updatedData, date_Time: updatedData?.date_Time || currentDate });
            return updatedData;
        });
        if (data?.presenting_Complaints) {
            setSelectedSymptoms(data?.presenting_Complaints);  // Directly set the object as is
        } else {
            setSelectedSymptoms({});
        }
        // if (onhistorychnagedata) {
        //     setSelectedObsHistory(onhistorychnagedata?.ObsHistory?.map((history: any) => ({
        //         ObsHistoryName: history?.ObsHistoryName,
        //         details: history?.details || '',
        //     })));
        // }
        //  else {
        //     setSelectedObsHistory([]); // Clear symptoms if none exist
        // }
    } else {
        onChange({ ...formData });
    }
    if (onhistorychnagedata) {
        setHistoryData((prevData) => {
            const updatedData = { ...prevData, ...onhistorychnagedata };
            onhistorychange({ ...updatedData });
            return updatedData;
        });
        setFormData((prevState: any) => ({
            ...prevState,
            obsHistory: onhistorychnagedata?.pregnancyInfo?.length > 0
                ? `${onhistorychnagedata?.pregnancyInfo[onhistorychnagedata?.pregnancyInfo.length - 1].pregNumber} - ${onhistorychnagedata?.pregnancyInfo[onhistorychnagedata?.pregnancyInfo.length - 1].detail}`
                : "" // In case newHistory is empty, set to empty string
        }));
    }
}, [data, onhistorychnagedata]);
// const handleSymptomTextChange = (index: number, text: string) => {
//     const updatedSymptoms = selectedSymptoms?.map((symptom, i) =>
//         i === index ? { ...symptom, details: text } : symptom
//     );

//     setSelectedSymptoms(updatedSymptoms);
// };
const handleSymptomTextChange = (symptomName: string, newDetails: string) => {
    setSelectedSymptoms((prevSymptoms: any) => ({
        ...prevSymptoms,
        [symptomName]: newDetails,  // Update the details for the specific symptom
    }));
};
useEffect(() => {
    onChange({ presenting_Complaints: selectedSymptoms, opd_Symptoms: history, ...formData, });
}, [selectedSymptoms, formData, history])
const handleObsHistoryTextChange = (index: number, text: string) => {
    const updatedObsHistory = selectedObsHistory?.map((ObsHistory, i) =>
        i === index ? { ...ObsHistory, details: text } : ObsHistory
    );
    setSelectedObsHistory(updatedObsHistory);
    onChange({ ...formData, ObsHistory: updatedObsHistory });
};
// const handleSelectChangeSymptoms = (event: any, newValue: any) => {
//     const existingSymptomNames = new Set(selectedSymptoms?.map((symptom: any) => symptom?.symptomName) || []);
//     const updatedSymptoms = newValue?.reduce((acc: any[], symptom: any) => {
//         if (symptom?.symptomName && !existingSymptomNames?.has(symptom?.symptomName)) {
//             // acc?.push({
//             //     history: symptom?.symptomName,
//             //     details: '',
//             // })
//             acc[symptom.symptomName] = '';

//         }
//         return acc;
//     }, []);

//     const finalSymptoms = [...selectedSymptoms, ...updatedSymptoms];
//     console.log('finalSymptoms', finalSymptoms, newValue);

//     setSelectedSymptoms(finalSymptoms);
// };
const handleSelectChangeSymptoms = (event: any, newValue: any) => {
    const existingSymptomNames = new Set(Object.keys(selectedSymptoms || {}));
    const updatedSymptoms = newValue?.reduce((acc: any, symptom: any) => {
        if (symptom?.symptomName && !existingSymptomNames?.has(symptom?.symptomName)) {
            acc[symptom?.symptomName] = ''; // Add symptom to the object
        }
        return acc;
    }, {});
    const finalSymptoms = { ...selectedSymptoms, ...updatedSymptoms };
    setSelectedSymptoms(finalSymptoms);
};

const handleChangeObsHistory = (event: any, newValue: any) => {
    const existing = selectedObsHistory?.some((obsHistory: any) => {
        return obsHistory?.ObsHistoryName === newValue;
    });

    if (existing) {
        return;
    }
    const updatedObsHistory = [
        ...selectedObsHistory,
        {
            ObsHistoryName: newValue,
            details: ''
        }
    ];
    setSelectedObsHistory(updatedObsHistory);
    onChange({ ...formData, ObsHistory: updatedObsHistory });
};

// const deleteTheSymtom = async (symptomName: string) => {
//     const updatedSymptoms = selectedSymptoms?.filter(
//         (symptom: any) => symptom?.symptomName !== symptomName
//     );
//     setSelectedSymptoms(updatedSymptoms);
//     // onChange({ ...formData, date_Time, symptoms: updatedSymptoms });
// }
const deleteTheSymtom = (symptomName: string) => {
    setSelectedSymptoms((prevSymptoms: any) => {
        const updatedSymptoms = { ...prevSymptoms };
        delete updatedSymptoms[symptomName];  // Delete symptom by name
        return updatedSymptoms;
    });
};

const deleteTheObsHistory = async (ObsHistoryName: string) => {
    const updatedObsHistory = selectedObsHistory?.filter(
        (ObsHistory: any) => ObsHistory?.ObsHistoryName !== ObsHistoryName
    );
    setSelectedObsHistory(updatedObsHistory);
    onChange({ ...formData, ObsHistory: updatedObsHistory });
}
const handleObsHistoryChange = (newHistory: any) => {
    setHistoryData(prevState => {
        const updatedHistory = {
            ...prevState,
            ObsHistory: newHistory
        };

        // Call handleSaveObsData with updated history data
        handleSaveObsData(updatedHistory);
        return updatedHistory;
    });
    setFormData((prevState: any) => ({
        ...prevState,
        obsHistory: newHistory?.length > 0
            ? `${newHistory[newHistory.length - 1].pregNumber} - ${newHistory[newHistory.length - 1].detail}`
            : "" // In case newHistory is empty, set to empty string
    }));
};
useEffect(() => {
    onhistorychange(historydata)
}, [historydata])

return (
    <>
        <Box mt={1} >
            <Box>
                <Grid container spacing={2} >
                    <Grid item md={2.4} sm={6} xs={6} >
                        <CustomAutocomplete
                            options={allSymptoms}
                            getOptionLabel={(option) => option?.symptomName}
                            filterOptions={(options, { inputValue }) => {
                                if (Array.isArray(options)) {
                                    return options?.filter((option: any) =>
                                        option?.symptomName?.toLowerCase()?.includes(inputValue.toLowerCase())
                                    );
                                }
                                return [];
                            }}
                            onChange={(event, value) => handleSelectChangeSymptoms(event, value)}
                            // value={selectedSymptoms}
                            placeholder="Presenting Complaints"
                            multiple
                        />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {history?.slice(0, 2).join(', ')}
                                {history?.length > 2 && (
                                    <Tooltip title={history?.slice(2)?.join(', ')} arrow placement='top'>
                                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                                            +{history?.length - 2} more
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                        {Object.keys(selectedSymptoms)?.length > 0 && (
                            <Box
                                sx={{
                                    height: '10rem',
                                    background: '#E6EFFF',
                                    border: '2px dotted #055FFC',
                                    mt: 1,
                                    padding: 0.5,
                                    borderRadius: '12px',
                                    overflowY: 'auto',
                                    "&::-webkit-scrollbar": {
                                        width: "3px",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        background: "transparent",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        background: "#888",
                                        borderRadius: "5px",
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#555",
                                    },
                                }}
                            >
                                {Object.keys(selectedSymptoms)?.map((symptomName: any, index: any) => (
                                    <Box display="flex" alignItems="center" key={symptomName} justifyContent="space-between" mt={0.5}>
                                        <Tooltip title={symptomName} arrow placement="top">
                                            <Typography sx={{ color: '#000', width: '40%', fontSize: '11px' }}>
                                                {symptomName?.length > 6
                                                    ? `${symptomName?.substring(0, 6)}...`
                                                    : symptomName}
                                            </Typography>
                                        </Tooltip>
                                        <TextField
                                            sx={{
                                                width: "90%",
                                                backgroundColor: '#F5F5FB',
                                                borderRadius: '12px',
                                                '& .MuiInputBase-root': {
                                                    height: '24px',
                                                    borderRadius: '12px',
                                                    padding: 0,
                                                    backgroundColor: 'transparent',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            value={selectedSymptoms[symptomName]}  // Get the details for the symptom
                                            onChange={(e) => handleSymptomTextChange(symptomName, e.target.value)}  // Update the details
                                            size="small"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                                cursor: 'pointer',
                                                                height: '20px',
                                                                lineHeight: '24px',
                                                            }}
                                                            onClick={() => deleteTheSymtom(symptomName)}  // Delete symptom by name
                                                        >
                                                            X
                                                        </span>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    fontSize: "12px",
                                                    padding: '0 8px',
                                                },
                                            }}
                                        />
                                    </Box>
                                ))}

                            </Box>
                        )}

                    </Grid>
                    <Grid item md={9.6} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField
                                    label="Detail History"
                                    onFocus={handleFocustwo}
                                    value={formData?.detailed_History}
                                    onChange={(e) => handleChange('detailed_History', e.target.value)}
                                    fullWidth
                                    size='small'
                                    placeholder='Not Significant'
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "13px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onBlur={handleBlurtwo}
                                    multiline={isMultilinetwo}
                                    minRows={isMultilinetwo ? 3 : 1} />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField
                                    label="Past Medical History"
                                    value={formData?.past_medical_History}
                                    onChange={(e) => handleChange('past_medical_History', e.target.value)}
                                    fullWidth

                                    placeholder='Not Significant'
                                    size='small'
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "13px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={

                                        handleFocusthree
                                    }
                                    onBlur={handleBlurthree}
                                    multiline={isMultilinethree}
                                    minRows={isMultilinethree ? 3 : 1} />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField label="Past Surgical History" InputProps={{
                                    style: {
                                        borderRadius: "12px",
                                        background: "#F5F5FB",
                                        fontSize: "13px",
                                    },
                                }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={

                                        handleFocusfour
                                    }
                                    onBlur={handleBlurfour}
                                    multiline={isMultilinefour}
                                    minRows={isMultilinefour ? 3 : 1} value={formData?.past_surgical_History} onChange={(e) => handleChange('past_surgical_History', e.target.value)} fullWidth size='small' placeholder='Not Significant' />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField label="Past Gynaecologist History" InputProps={{
                                    style: {
                                        borderRadius: "12px",
                                        background: "#F5F5FB",
                                        fontSize: "13px",
                                    },
                                }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={
                                        handleFocusfive
                                    }
                                    onBlur={handleBlurfive}
                                    multiline={isMultilinefive}
                                    minRows={isMultilinefive ? 3 : 1} value={formData?.past_gynaecologist_History} onChange={(e) => handleChange('past_gynaecologist_History', e.target.value)} fullWidth size='small' placeholder='Not Significant' />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField label="Birth History" value={formData?.birth_History} onChange={(e) => handleChange('birth_History', e.target.value)} fullWidth size='small' InputProps={{
                                    style: {
                                        borderRadius: "12px",
                                        background: "#F5F5FB",
                                        fontSize: "13px",
                                    },
                                }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={handleFocussix}
                                    onBlur={handleBlursix}
                                    multiline={isMultilinesix}
                                    minRows={isMultilinesix ? 3 : 1} />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField label="Development History" value={formData?.development_History} onChange={(e) => handleChange('development_History', e.target.value)} fullWidth size='small' InputProps={{
                                    style: {
                                        borderRadius: "12px",
                                        background: "#F5F5FB",
                                        fontSize: "13px",
                                    },
                                }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={handleFocusseven}
                                    onBlur={handleBlurseven}
                                    multiline={isMultilineseven}
                                    minRows={isMultilineseven ? 3 : 1} />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel>Vaccination History</InputLabel>
                                    <Select
                                        label="Vaccination History"
                                        value={formData.vaccination_History}
                                        onChange={(e) => handleChange('vaccination_History', e.target.value)}
                                        sx={{
                                            height: '38px',
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "12px",
                                            boxShadow: "none",
                                            "& fieldset": { border: "none" },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        {['Fully Vaccinated', 'Unvaccinated', 'Partially Vaccinated'].map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField
                                    label="Reason"
                                    value={formData.reason}
                                    onChange={(e) => handleChange('reason', e.target.value)}
                                    fullWidth
                                    className='search-field'
                                    size='small'
                                    disabled={formData?.vaccination_History === "Fully Vaccinated" || formData?.vaccination_History === ""}
                                    inputProps={{
                                        style: {
                                            fontSize: "13px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField
                                    label="Obs History"
                                    value={formData?.obsHistory}
                                    size='small'
                                    className='search-field'
                                    fullWidth
                                    disabled={
                                        otherTabsData?.patientData?.gender === "Male" ||
                                        isOlderThan12Years(otherTabsData?.patientData?.dob)
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleModalOpen}
                                                sx={{
                                                    position: 'absolute',
                                                    right: '5px',
                                                    zIndex: 1,
                                                    color: '#6c6c6c',
                                                }}
                                                disabled={
                                                    otherTabsData?.patientData?.gender === "Male" ||
                                                    !isOlderThan12Years(otherTabsData?.patientData?.dob)
                                                }                                                >
                                                <AddIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />

                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <TextField label="Nutritional History" value={formData.nutritional_History} onChange={(e) => handleChange('nutritional_History', e.target.value)} fullWidth size='small'
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "12px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    multiline={isMultiline}
                                    minRows={isMultiline ? 3 : 1}
                                />
                            </Grid>
                            <Grid item md={3} sm={4} xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel>Nutritional Status</InputLabel>
                                    <Select
                                        label="Nutritional Status"
                                        value={formData?.nutritional_Status}
                                        onChange={(e) => handleChange('nutritional_Status', e.target.value)}
                                        sx={{
                                            height: '38px',
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "12px",
                                            boxShadow: "none",
                                            "& fieldset": { border: "none" },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        {['Good', 'Average', 'Poor']?.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={3} sm={6} xs={6}>
                                <TextField
                                    label="Family History"
                                    value={formData.family_history}
                                    onChange={(e) => handleChange('family_history', e.target.value)}
                                    fullWidth
                                    className='search-field'
                                    size='small'
                                    inputProps={{
                                        style: {
                                            fontSize: "13px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={6}>
                                <TextField
                                    label="Known Allergies"
                                    value={historydata.knownAllergies}
                                    onChange={(e) => handleChangeHistory('knownAllergies', e.target.value)}
                                    fullWidth
                                    className='search-field'
                                    size='small'
                                    inputProps={{
                                        style: {
                                            fontSize: "13px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={6}>
                                <TextField
                                    label="Known Desiese"
                                    value={historydata.knownDeseise}
                                    onChange={(e) => handleChangeHistory('knownDeseise', e.target.value)}
                                    fullWidth
                                    className='search-field'
                                    size='small'
                                    inputProps={{
                                        style: {
                                            fontSize: "13px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={6}>
                                <TextField label="Comment" className='search-field' value={formData.comment} onChange={(e) => handleChange('comment', e.target.value)} fullWidth size='small'
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "13px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    onFocus={handleFocuseight}
                                    onBlur={handleBlureight}
                                    multiline={isMultilineeight}
                                    minRows={isMultilineeight ? 3 : 1}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={1}>
                    <Grid item md={2.4} sm={6} xs={12}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Doctor Name</Typography>
                        <TextField value={formData?.doctor_Name} disabled fullWidth className='search-field' size='small' />
                    </Grid>
                    <Grid item md={2.4} sm={6} xs={12}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Date&Time </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={dayjs(formData?.date_Time)}
                                disabled={true}
                                format="DD.MM.YYYY hh:mm A"
                                sx={{
                                    "& fieldset": { border: "none" },
                                    "& .MuiInputBase-root": {
                                        height: "38px",
                                        fontSize: "12px",
                                        overflow: "hidden"
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        border: "none",
                                        backgroundColor: "#F5F5FB"
                                    },
                                    width: "100%",
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <ObsHistoryDialog
            openModal={openModal}
            handleModalClose={handleModalClose}
            admissionId={otherTabsData?._id}
            onObsHistoryChange={handleObsHistoryChange}  // This is the callback for updating ObsHistory
            onhistorychnagedata={onhistorychnagedata}
            setHistoryData={setHistoryData}

        />
    </>
);
};
