// import React, { useEffect, useState } from 'react';

// import { Box, Typography, TextField, Button, Grid } from '@mui/material';
// import axios from 'axios';

// const Anaesthesia: React.FC<{ onClose: () => void, templateData: any }> = ({ onClose, templateData }) => {
//   const [name, setName] = useState('');
//   const [details, setDetails] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState<boolean | null>(null);

//   const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
//     e.preventDefault();
//     try {
//       const res = await axios.post(
//         `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/addNewTemplate`,
//         {
//           ope_type: 'Anesthesia',
//           templateName: name,
//           templateData: { details },
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('token')}`,
//           },
//         }
//       );

//       setIsSubmitted(true);
//       onClose();
//     } catch (error) {
//       console.error(error);
//       setIsSubmitted(false);
//     }
//   };
//   useEffect(() => {
//     if (templateData) {
//       setName(templateData?.templateName)
//       setDetails(templateData?.templateData?.details)
//     }
//   }, [templateData])
//   return (
//     <Box p={3}>
//       <Typography variant="h5" gutterBottom>
//         New Anaesthesia Template
//       </Typography>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={4}>
//           <TextField
//             label="Name"
//             fullWidth
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             size="small"
//             className="search-field"
//           />
//         </Grid>
//         <Grid item xs={12} sm={12}>
//           <TextField
//             label="Anaesthesia Detail"
//             variant="outlined"
//             fullWidth
//             multiline
//             rows={8}
//             value={details}
//             onChange={(e) => setDetails(e.target.value)}
//             className="search-field"
//             sx={{
//               mb: 2,
//               background: '#F5F5FB',
//               borderRadius: '8px',
//               '& .MuiOutlinedInput-root': {
//                 borderRadius: '8px',
//               },
//             }}
//           />
//         </Grid>
//       </Grid>
//       <Box display="flex" justifyContent="flex-end">
//         <Button variant="contained" color="primary" onClick={handleSave}>
//           Save
//         </Button>
//       </Box>

//       {isSubmitted !== null && (
//         <Typography color={isSubmitted ? 'green' : 'red'} mt={2}>
//           {isSubmitted
//             ? 'Template submitted successfully!'
//             : 'Failed to submit template. Please try again.'}
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default Anaesthesia;
import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import styles for Toast

const Anaesthesia: React.FC<{ onClose: () => void, templateData: any, fetchTemplates: (optype: any) => void }> = ({ onClose, templateData, fetchTemplates }) => {
  const [name, setName] = useState('');
  const [details, setDetails] = useState('');

  // Handle save or update depending on whether templateData exists
  const handleSaveOrUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const url = templateData
      ? `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/updateTemplateById/${templateData._id}`
      : `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/addNewTemplate`;

    const method = templateData ? 'PATCH' : 'POST';
    const requestData = {
      ope_type: 'Anesthesia',
      templateName: name,
      templateData: { details },
    };

    try {
      const res = await axios({
        method,
        url,
        data: requestData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Toast message for successful submission
      toast.success(templateData ? 'Template updated successfully!' : 'Template submitted successfully!');
      onClose(); // Close the modal after submission
      setName("")
      setDetails("")
      fetchTemplates("Anesthesia")
    } catch (error) {
      console.error(error);
      // Toast message for failed submission
      toast.error('Failed to submit template. Please try again.');
    }
  };

  // Populate the form fields when templateData is passed in
  useEffect(() => {
    if (templateData) {
      setName(templateData?.templateName);
      setDetails(templateData?.templateData?.details);
    }
  }, [templateData]);

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        {templateData ? 'Update Anaesthesia Template' : 'New Anaesthesia Template'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
            className="search-field"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Anaesthesia Detail"
            variant="outlined"
            fullWidth
            size='small'
            multiline
            rows={8}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            className="search-field"
            sx={{
              mb: 2,
              background: '#F5F5FB',
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleSaveOrUpdate}>
          {templateData ? 'Update' : 'Save'}
        </Button>
      </Box>
    </Box>
  );
};

export default Anaesthesia;
