import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Autocomplete,
  Divider,
  Radio,
  RadioGroup,
  Chip,
  SelectChangeEvent,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Examination } from '../Examination';
import { toast } from 'react-toastify';
import axios from 'axios';
import DynamicTable from '../../../../Dynamic-table';
import { Investigation } from '../Investegation';

interface PAFProps {
  onChange: (data: any) => void;
  data: any;
  otherTabsData: any;
  profile: any
  opdData: any
}
export const PatientAssessmentForm: React.FC<PAFProps> = ({ onChange, data, otherTabsData, profile, opdData }) => {
  const [formData, setFormData] = useState<any>({
    investigation: {
      CBC: false,
      BSR: false,
      SE: false,
      LFT: false,
      RFT: false,
      UrineCE: false,
      PT: false,
      HBsAg: false,
      AntiHCV: false,
      CXR: false,
      ECG: false,
    },
    history: {
      HTN: false,
      DM: false,
      CLD: false,
      IHD: false,
      COPD: false,
      Asthma: false,
      Smoking: false,
      Addiction: false,
      Drug: false,
      Others: false,
    },
    mallam_pati_Grade: '0',
    ASA_Score: '1',
    surgical_Risk_Score: 'low',
    drug_details: "",
    other_details: "",
    npo: '',
    blood_required: '',
    past_Surgical_History: '',
    blood_Group: "A+",
    anaesthesia_plan: '',
    anaesthesia_plan1: ''
  });
  const [ipdExamination, setIpdExamination] = useState<any>({
    GPE: [],
    systemic_Examination: []
  })
  const [medicines, setMedicines] = useState<any[]>([])
  const [examination, setExamination] = useState<any[]>([])
  const [opdTests, setOpdTests] = useState<any>();
  const [ipdInvestigation, setIpdInvestigation] = useState<any[]>([])

  useEffect(() => {
    setOpdTests(opdData?.tests)
  }, [opdData]);
  useEffect(() => {
    setExamination(opdData?.signs)
  }, [opdData])
  const currentDate = dayjs();

  const fetchFormData = async (formName: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const fetchData = response?.data?.data?.formData;
      if (formName === "PreSurgical") {
        setMedicines(fetchData?.medicines);
      } else if (formName === 'History') {
        setFormData((prevState: any) => ({
          ...prevState,
          past_Surgical_History: fetchData?.past_surgical_History || '',
        }));
      } else if (formName === 'Examination') {
        setIpdExamination({
          GPE: fetchData?.examinations,
          systemic_Examination: fetchData?.examinations_Without_GPE,
        })
      } else if (formName === 'Investegation') {
        setIpdInvestigation(fetchData?.investigation || [])
      }
    } catch (error) {
      toast.error('Failed to fetch form data.');
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await fetchFormData('PreSurgical');
        await fetchFormData('Examination');
        await fetchFormData('History');
        await fetchFormData('Investegation');
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (data) {
      setFormData({
        blood_Group: data?.blood_Group,
        blood_required: data?.blood_required,
        drug_details: data?.drug_details,
        other_details: data?.other_details,
        npo: data?.npo,
        history: data?.history,
        investigation: data?.investigation,
        score: data?.score,
        anaesthesia_plan: data?.anaesthesia_plan,
        anaesthesia_plan1: data?.anaesthesia_plan1
      })
    } else if (!data) {
      setFormData({
        investigation: {
          CBC: false,
          BSR: false,
          SE: false,
          LFT: false,
          RFT: false,
          UrineCE: false,
          PT: false,
          HBsAg: false,
          AntiHCV: false,
          CXR: false,
          ECG: false,
        },
        history: {
          HTN: false,
          DM: false,
          CLD: false,
          IHD: false,
          COPD: false,
          Asthma: false,
          Smoking: false,
          Addiction: false,
          Drug: false,
          Others: false,
        },
      })
    } else {
      onChange({ ...formData, dateTime: currentDate });
    }
  }, [data])

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (formData.history && name in formData.history) {
      const updatedHistory = {
        ...formData.history,
        [name]: type === 'checkbox' ? checked : value,
      };
      setFormData((prev: any) => ({ ...prev, history: updatedHistory }));
      return;
    }
    if (formData.investigation && name in formData.investigation) {
      const updatedInvestigation = {
        ...formData.investigation,
        [name]: type === 'checkbox' ? checked : value,
      };
      setFormData((prev: any) => ({ ...prev, investigation: updatedInvestigation }));
      return;
    }
    if (name in formData || name === 'blood_Group' || name === 'drug_details' || name === 'ASA_Score' || name === 'surgical_Risk_Score' || name === 'mallam_pati_Grade' || name === 'other_details' || name === 'npo' || name === 'blood_required' || name === 'anaesthesia_plan' || name === 'surgical_Risk_Score' || name === 'mallam_pati_Grade' || name === 'other_details' || name === 'npo' || name === 'blood_required' || name === 'anaesthesia_plan1') {
      setFormData((prev: any) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  useEffect(() => {
    const updatedData = {
      ...formData,
      history: formData?.history,
      investigation: formData?.investigation,
      score: formData?.score,
      blood_Group: formData?.blood_Group,
      drug_details: formData?.drug_details,
      other_details: formData?.other_details,
      npo: formData?.npo,
      blood_required: formData?.blood_required,
      past_Surgical_History: formData?.past_Surgical_History,
      anaesthesia_plan1: formData?.anaesthesia_plan1,
      anaesthesia_plan: formData?.anaesthesia_plan
    };
    onChange(updatedData);
  }, [formData]);


  // useEffect(() => {
  //   if (opdTests && formData?.investigation && ipdInvestigation) {
  //     const mergedTestsSet = new Set<string>();
  //     opdTests?.forEach((test: any) => {
  //       if (test?.testName) {
  //         mergedTestsSet.add(test?.testName);
  //       }
  //     });
  //     ipdInvestigation?.forEach((ipdTest: any) => {
  //       if (ipdTest?.investigation_Name) {
  //         mergedTestsSet?.add(ipdTest?.investigation_Name);
  //       }
  //     });
  //     const mergedTests = Array.from(mergedTestsSet);
  //     const updatedInvestigation = { ...formData?.investigation };
  //     Object.keys(updatedInvestigation || {})?.forEach((key) => {
  //       if (updatedInvestigation[key] === false) {
  //         const matchFound = mergedTests?.some((testName) =>
  //           testName?.toLowerCase()?.includes(key?.toLowerCase())
  //         );
  //         if (matchFound) {
  //           updatedInvestigation[key] = true;
  //         }
  //       }
  //     });
  //     setFormData((prevData: any) => ({
  //       ...prevData,
  //       investigation: updatedInvestigation,
  //     }));
  //   }
  // }, [opdTests, ipdInvestigation]);

  useEffect(() => {
    if (opdTests && formData?.investigation && ipdInvestigation) {
      const mergedTestsSet = new Set<string>();
      opdTests?.forEach((test: any) => {
        if (test?.testName) {
          const words = test?.testName?.toLowerCase()?.split(/\s+/);
          words?.forEach((word: string) => {
            mergedTestsSet?.add(word);
          });
        }
      });

      ipdInvestigation?.forEach((ipdTest: any) => {
        if (ipdTest?.investigation_Name) {
          const words = ipdTest.investigation_Name?.toLowerCase()?.split(/\s+/);
          words?.forEach((word: string) => {
            mergedTestsSet?.add(word);
          });
        }
      });

      const mergedTests = Array.from(mergedTestsSet);
      const updatedInvestigation = { ...formData?.investigation };

      Object.keys(updatedInvestigation || {})?.forEach((key) => {
        if (updatedInvestigation[key] === false) {
          const keyWords = key?.toLowerCase()?.split(/\s+/);

          const matchFound = keyWords?.some((keyWord: string) =>
            mergedTests?.includes(keyWord)
          );

          if (matchFound) {
            updatedInvestigation[key] = true;
          }
        }
      });
      setFormData((prevData: any) => ({
        ...prevData,
        investigation: updatedInvestigation,
      }));
    }
  }, [opdTests, ipdInvestigation]);

  return (
    <Box >
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ background: "#F5F5F5", borderRadius: "13px" }}>
            <Typography
              sx={{
                background: "#E5E5E5",
                textAlign: "center",
                padding: 1,
                borderRadius: "13px 13px 0 0"
              }}
            >
              PRE-ANESTHESIA ASSESSMENT
            </Typography>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <Box sx={{ padding: 1 }} >
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600", py: 0.5 }}>
                      Blood Group
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControl fullWidth size="small">
                        <Select
                          name="blood_Group"  // Use 'blood_Group' to handle the selection of blood type + Rh factor
                          value={formData?.blood_Group}  // Default to 'A+' if no blood group is selected
                          onChange={handleChange}  // Handle the selection change
                          sx={{
                            borderRadius: "12px",
                            border: "0px solid #D8D8D8",
                            background: "#fff",
                            fontSize: "12px",
                            height: "38px",
                            mb: 0.2,
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value="A+">A+</MenuItem>
                          <MenuItem value="A-">A-</MenuItem>
                          <MenuItem value="B+">B+</MenuItem>
                          <MenuItem value="B-">B-</MenuItem>
                          <MenuItem value="AB+">AB+</MenuItem>
                          <MenuItem value="AB-">AB-</MenuItem>
                          <MenuItem value="O+">O+</MenuItem>
                          <MenuItem value="O-">O-</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", mb: .3 }}>
                    <Typography sx={{ flex: 1 }}>ASA Score</Typography>
                    <TextField
                      name="ASA_Score"
                      value={formData?.ASA_Score || '1'}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (value >= 1 && value <= 6) {
                          handleChange(e);
                        }
                      }}
                      className="search-field"
                      sx={{ border: "1px solid grey", width: "30%" }}
                      size="small"
                      type="number"
                      inputProps={{ min: 1, max: 6 }}
                    />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", mb: 0.3 }}>
                    <Typography sx={{ flex: 1 }}>Surgical Risk Score</Typography>
                    <Select
                      name="surgical_Risk_Score"
                      value={formData?.surgical_Risk_Score || 'low'}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "12px",
                        border: "1px solid #000",
                        background: "#F5F5FB",
                        fontSize: "12px",
                        height: "38px",
                        width: "30%",
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="intermediate">Intermediate</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                    </Select>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ flex: 1 }}>Mallampati Grade</Typography>
                    <TextField
                      name='mallam_pati_Grade'
                      value={formData?.mallam_pati_Grade || '0'}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (value >= 1 && value <= 4) {
                          handleChange(e);
                        }
                      }}
                      className="search-field"
                      sx={{ border: "1px solid grey", width: "30%" }}
                      size='small'
                      type='number'
                      inputProps={{ min: 1, max: 4 }}
                    />
                  </Box>

                  <Box mt={1}>
                    {formData?.past_Surgical_History && <TextField
                      name='past_Surgical_History'
                      className='search-field'
                      sx={{ background: '#fff' }}
                      size='small'
                      value={formData?.past_Surgical_History}
                      label='Past Srgical History'
                    />}
                  </Box>
                  {formData?.past_Surgical_History && <Box >
                    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Anaesthesia given during previous surgery:</Typography>
                    <Select
                      placeholder="Previous Anaesthesia Given"
                      name="anaesthesia_plan"
                      value={formData?.anaesthesia_plan}
                      onChange={handleChange}
                      size='small'
                      fullWidth
                      sx={{
                        height: '38px',
                        borderRadius: "12px",
                        background: "#fff",
                        fontSize: "12px",
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      displayEmpty
                    >
                      <MenuItem value=""><>Select Anesthesia Type</></MenuItem>
                      <MenuItem value="Local">Local</MenuItem>
                      <MenuItem value="GA">GA</MenuItem>
                      <MenuItem value="Spinal">Spinal</MenuItem>
                      <MenuItem value="Nerve Block">Nerve Block</MenuItem>
                      <MenuItem value="Epidural">Epidural</MenuItem>
                    </Select>
                  </Box>}
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={0.5}>
                  {Object.keys(formData?.history ?? {})?.map((key) => (  // Default to empty object if formData?.history is null or undefined
                    <Grid item xs={6} key={key}> {/* Each checkbox takes half the width on small screens and up */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={formData?.history[key] ?? false}  // Default to false if undefined
                            onChange={handleChange}
                            name={key}
                          />
                        }
                        label={key}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Box mt={0.5} p={1} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {
                    formData?.history?.Drug && <Box>
                      <TextField
                        name='drug_details'
                        className='search-field'
                        sx={{ background: '#fff' }}
                        size='small'
                        value={formData?.drug_details}
                        onChange={handleChange}
                        label='Drug Details'
                        fullWidth
                      />
                    </Box>
                  }
                  {
                    formData?.history?.Others && <Box>
                      <TextField
                        name='other_details'
                        className='search-field'
                        sx={{ background: '#fff' }}
                        size='small'
                        fullWidth
                        value={formData?.other_details}
                        onChange={handleChange}
                        label='Other Details'
                      />
                    </Box>
                  }
                </Box>
                <Box sx={{ padding: '4px', }}>
                  <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                    Anaesthesia Plan:
                  </Typography>
                  <Select
                    name="anaesthesia_plan1"
                    value={formData?.anaesthesia_plan1}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    sx={{
                      height: '38px',
                      borderRadius: '12px',
                      background: '#fff',
                      fontSize: '12px',
                      '& fieldset': {
                        border: 'none',
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">Select Anesthesia Type</MenuItem>
                    <MenuItem value="Local">Local</MenuItem>
                    <MenuItem value="GA">GA</MenuItem>
                    <MenuItem value="Spinal">Spinal</MenuItem>
                    <MenuItem value="Nerve Block">Nerve Block</MenuItem>
                    <MenuItem value="Epidural">Epidural</MenuItem>
                  </Select>
                </Box>

              </Grid>
            </Grid>
          </Box>
        </Grid >
        <Grid item md={6} sm={12} xs={12}>
          <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"column"} gap={1}>
            <Box sx={{ background: "#F5F5F5", width: "100%", borderRadius: "13px", overflow: "hidden" }}>
              <Typography width={"100%"} sx={{ background: "#E5E5E5", textAlign: "center", p: 0.5 }} >Examination</Typography>
              <Box padding={0.5}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography sx={{ marginBottom: 0.2, mr: 2 }}>GPE:</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography sx={{
                      fontSize: '12px',
                      color: 'black',
                      fontWeight: '500',
                      backgroundColor: '#80CBC4', 
                      padding: '2px 8px', 
                      borderRadius: '10px', 
                      display: 'inline-block',
                      alignSelf: "center"
                    }}>
                      {examination?.slice(0, 2).join(', ') ? examination?.slice(0, 2)?.join(', ') : "No Prev Examination"}
                      {examination?.length > 2 && (
                        <Tooltip title={examination?.slice(2)?.join(', ')} arrow placement='top'>
                          <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                            +{examination?.length - 2} more
                          </span>
                        </Tooltip>
                      )}
                    </Typography>
                  </Box>
                </Box>
                {ipdExamination?.GPE?.map((sign: any) => (
                  <Chip
                    key={sign?._id}
                    label={sign}
                    sx={{
                      mr: .4,
                      background: "#CFCFCF",
                      borderRadius: "8px",
                      fontSize: "12px",
                      height: "24px",
                      "&:hover": {
                        background: "#055FFC",
                        color: "#fff",
                        "& .MuiChip-deleteIcon": {
                          color: "#fff",
                        },
                        "& .MuiChip-icon": {
                          color: "#fff",
                        },
                      },
                    }}
                  />
                ))}

                <Typography sx={{ marginBottom: 0.2 }}>SE:</Typography>
                <Box mt={0.3} display='flex' gap={0.2} flexWrap='wrap'>
                  {Object.entries(ipdExamination?.systemic_Examination || {}).map(([sign, value]) => (
                    <Chip
                      key={sign}
                      label={`${sign}: ${value}`} // Display both sign and its value
                      sx={{
                        background: "#CFCFCF",
                        borderRadius: "8px",
                        fontSize: "12px",
                        height: "24px",
                        "&:hover": {
                          background: "#055FFC",
                          color: "#fff",
                          "& .MuiChip-deleteIcon": {
                            color: "#fff",
                          },
                          "& .MuiChip-icon": {
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  ))}

                </Box>
              </Box>
            </Box>

            <Box sx={{ background: "#F5F5F5", width: "100%", borderRadius: "12px", overflow: "hidden" }}>
              <Typography width={"100%"} sx={{ background: "#E5E5E5", textAlign: "center", p: 0.5 }} >Investigations</Typography>
              <Box display={"flex"} padding={"10px"} mb={1}>
                <FormGroup row>
                  <Grid container>
                    {Object?.keys(formData?.investigation ?? {})?.map((key, index) => (
                      <Grid item xs={2} sm={2} md={2} lg={2} key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={formData?.investigation[key]}
                              onChange={handleChange}
                              name={key}
                            />
                          }
                          label={<span style={{ fontSize: '14px' }}>{key}</span>}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Box>
            </Box>

            <Grid container spacing={1.5}>
              <Grid item md={6} sm={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Typography sx={{ mr: 1 }}>NPO</Typography>
                  <Checkbox
                    checked={formData?.npo}
                    onChange={handleChange}
                    name='npo'
                    size='small'
                  />
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Blood Required :</Typography>
                <TextField
                  fullWidth
                  placeholder="Blood Required"
                  type='number'
                  name="blood_required"
                  value={formData?.blood_required}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === '' || /^[0-9]*$/.test(value)) {
                      const numericValue = Number(value);
                      if (numericValue >= 0 && numericValue <= 15) {
                        handleChange(event);
                      }
                    }
                  }}
                  size='small'
                  className='search-field'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">pint</InputAdornment>,
                  }}
                  inputProps={{
                    min: 0,
                    max: 15,
                    step: 1,
                  }}
                />
              </Grid>
            </Grid>

          </Box>

        </Grid>
      </Grid >
      <Box mt={1}>
        <Grid container >
          <Grid item md={12} sm={12} xs={12}>
            <DynamicTable
              headers={[
                "SN",
                "Medicine Name",
                "Schedule",
                "Duration",
                "Note",
              ]}
              data={medicines?.map((item: any, index: any) => [
                index + 1,
                item?.name,
                item?.dosage,
                item?.duration,
                item?.note,
              ])}
            />
          </Grid>

        </Grid>
      </Box>

    </Box >
  );
};
