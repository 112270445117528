import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CustomAutocomplete from '../../../autocomplete-field';
import axios from 'axios';
import DynamicTable from '../../../Dynamic-table';
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from 'react-toastify';
import { isDateTimeGreaterThan30MinutesAgo } from '../../../../utils/dateTimeUtils'; // Import the utility function
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

export const Investigation: React.FC<any> = ({ onChange, data, profile, opdData }) => {
    const [selectedTests, setSelectedTests] = useState<any[]>([]);
    const [selectedInvestigationDetails, setSelectedInvestigationDetails] = useState<any[]>([]);
    const [newTestInput, setNewTestInput] = useState("");
    const [procTests, setProcTests] = useState<any>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [testToAdvise, setTestToAdvise] = useState<any>(null); // State to hold the test that needs to be advised again
    const [opdTests, setOpdTests] = useState<any>();
    useEffect(() => {
        setOpdTests(opdData?.tests)
    }, [opdData]);

    const debouncedFetchProcedureTests = useCallback(
        debounce(async (search) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_OPD_API_URL}/procedure/getAllTests?count=50000&search=${search}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                const fetchedTests = response.data?.data || [];
                const uniqueDiagnosis = Array.from(
                    new Map(fetchedTests?.map((item: any) => [item?.testName, item]))?.values()
                );
                setProcTests(uniqueDiagnosis);
            } catch (error) {
                console.error("Error fetching procedures:", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        debouncedFetchProcedureTests(newTestInput);
    }, [debouncedFetchProcedureTests, newTestInput]);

    useEffect(() => {
        onChange({
            investigation: selectedInvestigationDetails,
            opd_Tests: opdTests
        });
    }, [selectedInvestigationDetails, opdTests]);
    useEffect(() => {
        if (Array.isArray(data?.investigation)) {
            const sortedInvestigationDetails = data?.investigation?.sort((a: any, b: any) =>
                a?.departmentName?.localeCompare(b?.departmentName)
            );
            setSelectedInvestigationDetails(sortedInvestigationDetails);
        } else {
            setSelectedInvestigationDetails([]);
        }
    }, [data]);

    // useEffect(() => {
    //     if (data?.investigation) {
    //         const sortedInvestigationDetails = (data?.investigation || [])?.sort((a: any, b: any) =>
    //             a?.departmentName?.localeCompare(b?.departmentName)
    //         );
    //         setSelectedInvestigationDetails(sortedInvestigationDetails);
    //     }
    // }, [data]);

    // const handleTestsChange = (event: React.ChangeEvent<{}>, newValue: any[]) => {
    //     if (!newValue) return;
    //     const currentDateTime = dayjs();

    //     const newInvestigations = newValue?.filter((item: any) =>
    //         !opdTests?.some((opdTest: any) =>
    //             opdTest?.testName === item?.testName && opdTest?.procedureName === item?.procedureName
    //         )
    //     );
    //     const duplicates = newValue?.filter((item: any) =>
    //         opdTests?.some((opdTest: any) =>
    //             opdTest?.testName === item?.testName && opdTest?.procedureName === item?.procedureName
    //         )
    //     );
    //     if (duplicates.length > 0) {
    //         setTestToAdvise(duplicates[0]);
    //         setDialogOpen(true);
    //         return;
    //     }
    //     if (newInvestigations?.length > 0) {
    //         setSelectedInvestigationDetails((prevDetails: any[]) => {
    //             const newDetails = newInvestigations?.map((item: any) => ({
    //                 department_Name: item?.procedureName || '',
    //                 investigation_Name: item?.testName || '',
    //                 date_Time: currentDateTime?.format('DD.MM.YYYY hh:mm A') || '',
    //                 doctor_Name: profile?.data?.fullName
    //             }));
    //             const allDetails = [...prevDetails, ...newDetails];
    //             allDetails?.sort((a, b) => a?.department_Name?.localeCompare(b?.departmentName));
    //             return allDetails;
    //         });
    //     }
    // };

    const handleTestsChange = (event: React.ChangeEvent<{}>, newValue: any[]) => {
        if (!newValue) return;

        const currentDateTime = dayjs();

        // Filter out any new investigations that already exist in opdTests or selectedInvestigationDetails
        const newInvestigations = newValue?.filter((item: any) =>
            !opdTests?.some((opdTest: any) =>
                opdTest?.testName === item?.testName && opdTest?.procedureName === item?.procedureName
            ) && !selectedInvestigationDetails?.some((existingItem: any) =>
                existingItem?.investigation_Name === item?.testName && existingItem?.department_Name === item?.procedureName
            )
        );

        // Find duplicates in the new value that already exist in the opdTests
        const duplicates = newValue?.filter((item: any) =>
            opdTests?.some((opdTest: any) =>
                opdTest?.testName === item?.testName && opdTest?.procedureName === item?.procedureName
            )
        );

        // Check if the test is already in selectedInvestigationDetails
        const alreadySelectedTests = newValue?.filter((item: any) =>
            selectedInvestigationDetails?.some((existingItem: any) =>
                existingItem?.investigation_Name === item?.testName && existingItem?.department_Name === item?.procedureName
            )
        );

        // Show toast if the selected test is already in selectedInvestigationDetails
        if (alreadySelectedTests.length > 0) {
            toast.info("This test is already selected.");
            return; // Exit the function early if already selected
        }

        // If there are duplicates in opdTests, show the dialog for duplicates
        if (duplicates.length > 0) {
            setTestToAdvise(duplicates[0]);
            setDialogOpen(true);
            return;
        }

        // Add the unique new investigations to the selectedInvestigationDetails
        if (newInvestigations?.length > 0) {
            setSelectedInvestigationDetails((prevDetails: any[]) => {
                const newDetails = newInvestigations?.map((item: any) => ({
                    department_Name: item?.procedureName || '',
                    investigation_Name: item?.testName || '',
                    date_Time: currentDateTime?.format('DD.MM.YYYY hh:mm A') || '',
                    doctor_Name: profile?.data?.fullName
                }));
                const allDetails = [...prevDetails, ...newDetails];
                allDetails?.sort((a, b) => a?.department_Name?.localeCompare(b?.department_Name)); // Fix typo in `department_Name`
                return allDetails;
            });
        }
    };

    const handleDeleteInvestigation = (rowIndex: number) => {
        setSelectedInvestigationDetails((prevDetails) => {
            const updatedDetails = [...prevDetails];
            updatedDetails?.splice(rowIndex, 1);
            toast.success("Investigation removed successfully!");
            return updatedDetails;
        });
    };

    const handleDialogClose = (confirmed: boolean) => {
        setDialogOpen(false);
        if (confirmed && testToAdvise) {
            const currentDateTime = dayjs(); // Get current time
            // Add the test again to the selectedInvestigationDetails
            setSelectedInvestigationDetails((prevDetails: any[]) => {
                const newDetails = [{
                    department_Name: testToAdvise?.procedureName || '',
                    investigation_Name: testToAdvise?.testName || '',
                    date_Time: currentDateTime.format('DD.MM.YYYY hh:mm A') || '',
                    doctor_Name: profile?.data?.fullName,
                }];
                const allDetails = [...prevDetails, ...newDetails];
                allDetails?.sort((a, b) => a?.department_Name?.localeCompare(b?.departmentName));
                return allDetails;
            });
        }
    };

    return (
        <>
            <Box sx={{ height: { md: '50vh', sm: '100%', xs: '100%' }, overflowY: 'auto' }}>
                <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                        <CustomAutocomplete
                            multiple
                            getOptionLabel={(option) => option?.testName || ""}
                            filterOptions={(options, { inputValue }) =>
                                options?.filter((option: any) =>
                                    option?.testName?.toLowerCase().includes(inputValue.toLowerCase())
                                )
                            }
                            options={procTests || []}
                            value={selectedTests}
                            onChange={handleTestsChange}
                            renderOption={(props, option: any) => (
                                <li {...props}>
                                    <Typography sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        {option.testName || ""}
                                        <span style={{ fontSize: "10px", color: "gray" }}>
                                            {option?.procedureName ? `(${option?.procedureName})` : ""}
                                        </span>
                                    </Typography>
                                </li>
                            )}
                            placeholder="Select Tests"
                            onTextFieldChange={(event) => setNewTestInput(event.target.value)}
                            handleBlur={() => setNewTestInput("")}
                        />
                    </Grid>
                    <Grid item md={8} sm={6} xs={12}>
                        <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
                            <b>OPD Investigations:</b>  {opdTests?.map((test: any) => test?.testName)?.join(', ')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box mt={1.5}>
                            <DynamicTable
                                headers={[
                                    "SN",
                                    "Date & Time",
                                    "Department",
                                    "Investigation",
                                    "Given By",
                                    "Action",
                                ]}
                                data={selectedInvestigationDetails?.map((item: any, index: number) => [
                                    index + 1,
                                    item.date_Time,
                                    item?.department_Name,
                                    item?.investigation_Name,
                                    item?.doctor_Name,
                                ])}
                                actionButtons={[
                                    {
                                        icon: (rowIndex: number) => (
                                            <DeleteIcon
                                                sx={{
                                                    color: isDateTimeGreaterThan30MinutesAgo(selectedInvestigationDetails[rowIndex]?.date_Time) ? "#FF5454" : "#D3D3D3",
                                                    "&:hover": {
                                                        background: "none",
                                                        color: isDateTimeGreaterThan30MinutesAgo(selectedInvestigationDetails[rowIndex]?.date_Time) ? "rgb(191, 44, 44)" : "#D3D3D3",
                                                    },
                                                }}
                                            />
                                        ),
                                        onClick: (rowIndex: number) => handleDeleteInvestigation(rowIndex),
                                        disabled: (rowIndex: number) => !isDateTimeGreaterThan30MinutesAgo(selectedInvestigationDetails[rowIndex]?.date_Time),
                                    },
                                ]}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>Test Already Advised</DialogTitle>
                    <DialogContent>
                        <Typography>Do you want to advise <b>{testToAdvise?.testName}</b> again?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDialogClose(false)} color="primary">No</Button>
                        <Button onClick={() => handleDialogClose(true)} color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};
