import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface User {
  // Define the structure of your user object here
  // For example:
  id: string;
  name: string;
  // Add more fields as needed
}

interface UsersState {
  users: User[];
  loading: boolean;
  error: string | null;
}

// Async thunk to fetch users by category ID
export const fetchUsersByCategoryId = createAsyncThunk(
  'users/fetchUsersByCategoryId',
  async ({ categoryId, options }: { categoryId: string | undefined, options: any }) => { // Ensure categoryId is of type string or undefined
    const token = localStorage.getItem('token');

    if (!categoryId) {
      throw new Error('Category ID is undefined');
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/users/getAllUserByCategoryId/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        ...options // Pass any additional options here
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Define the initial state
const initialState: UsersState = {
  users: [],
  loading: false,
  error: null,
};

// Create the users slice
const getUserByIdSlice = createSlice({
  name: 'getUserByIdSlice',
  initialState,
  reducers: {
    clearUsersData: state => {
      state.users = [];      
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersByCategoryId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersByCategoryId.fulfilled, (state, action: PayloadAction<{ data: User[] }>) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(fetchUsersByCategoryId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || "An error occurred"; // Handle potential undefined or null
      });
  },
});
export const { clearUsersData } = getUserByIdSlice.actions;
export default getUserByIdSlice.reducer;

// Selector
export const selectUsers = (state: { getUserByIdSlice: UsersState }) => state.getUserByIdSlice.users;
export const selectUsersLoading = (state: { getUserByIdSlice: UsersState }) => state.getUserByIdSlice.loading;
export const selectUsersError = (state: { getUserByIdSlice: UsersState }) => state.getUserByIdSlice.error;
