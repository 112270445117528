import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControlLabel,
    Button,
    FormGroup,
    Typography,
    Box,
    Container,
} from '@mui/material';
interface FormData {
    name_Procedure_Record: boolean;
    needle_Counts_Correct: boolean;
    specimen_Labelled: boolean;
    Any_equipment_problems_to_be_addressed: boolean;
    review_Concerns_for_Recovery_and_Management: boolean;
}
interface OT_NurseProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
}
const OT_Nurse: React.FC<OT_NurseProps> = ({ onChange, data, otherTabsData, profile }) => {
    const [formData, setFormData] = useState<FormData>({
        name_Procedure_Record: true,
        needle_Counts_Correct: true,
        specimen_Labelled: true,
        Any_equipment_problems_to_be_addressed: true,
        review_Concerns_for_Recovery_and_Management: true,
    });

    useEffect(() => {
        onChange(formData);
    }, []);
    
    useEffect(() => {
        if (data) {
            setFormData(data);
        }
        else {
            setFormData({
                name_Procedure_Record: true,
                needle_Counts_Correct: true,
                specimen_Labelled: true,
                Any_equipment_problems_to_be_addressed: true,
                review_Concerns_for_Recovery_and_Management: true,
            });
        }
    }, [data]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const updatedFormData = {
            ...formData,
            [name]: checked,
        };
        setFormData(updatedFormData);
        onChange(updatedFormData); // Immediately send updated form data to parent

    };
    return (
        <Container maxWidth='xs'>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ border: '1px solid #000', p: 1 }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }} gutterBottom>
                        Before Patient Leaves Operating Room
                    </Typography>
                    <Box sx={{ backgroundColor: 'rgba(5, 95, 252, 1)', p: 0.5 }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#fff' }}>
                            Sign Out
                        </Typography>
                    </Box>
                    <Box sx={{ px: 1.5, py: 1, background: 'rgba(5, 95, 252, 0.1)' }}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="name_Procedure_Record"
                                        checked={formData?.name_Procedure_Record}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        Name of procedure record
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="needle_Counts_Correct"
                                        checked={formData?.needle_Counts_Correct}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        Instrument, sponge, and needle counts are correct (or not applicable)
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="specimen_Labelled"
                                        checked={formData?.specimen_Labelled}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        How the specimen is labelled (including patient name)
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="Any_equipment_problems_to_be_addressed"
                                        checked={formData?.Any_equipment_problems_to_be_addressed}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        Any equipment problems to be addressed
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="review_Concerns_for_Recovery_and_Management"
                                        checked={formData?.review_Concerns_for_Recovery_and_Management}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        Surgeon, anaesthesia professional, and nurse review key concerns for recovery and management
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
export default OT_Nurse;