import React, { useEffect, useState, useRef } from 'react';
import { Tab, Tabs, Box, Button, Typography, Divider, Tooltip, FormControlLabel, Switch, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import { History } from './History';
import { toast } from "react-toastify";
import { PatientAssessmentForm } from './anathesia/PreAnathesiaAssessment';
import dayjs from 'dayjs';
import { PostAnathesia } from './anathesia/PostAnathesia';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchProjectProfileData } from '../../../../redux/reducers/projectProfileSlice';
import { Examination } from './Examination';
import Treatment from './treatment';
import { Investigation } from './Investegation';
import PreSurgical from './surgical/PreSurgical';
import Preoperation from './surgical/PreOperation';
import OtRoomAnaesthesiaMonitoring from './anathesia/OtRoomAnaesthesiaMonitoring';
import OT_Nurse from './ot-nurse';
import TreamentChart from './treatment_Chart/TreamentChart';
import DailyProgressNotes from './DailyProgressNotes';
import VitalAndIOPChart from './vital-iop-chart';
import CallToDoctor from './CallToDoctor';
import OT from './ot';
import PostOperation from './surgical/PostOperation';
import { ADMISSION_TYPE } from '../../../../enums';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const tabComponents: any = [
  { label: "History", component: History, formName: 'History' },
  { label: "Examination", component: Examination, formName: 'Examination' },
  { label: "Investigation", component: Investigation, formName: 'Investegation' },

  { label: "Treatment", component: Treatment, formName: 'Treatment' },
  // { label: "Delete Treatment Medicines", component: DelMedicines, formName: 'Treatment' },
  {
    label: "Anesthesia", subTabs: [
      { label: "Pre Anesthesia Assessment", component: PatientAssessmentForm, formName: 'Pre_Anesthesia_Assessment' },
      { label: "OT Room Anaesthesia Monitoring", component: OtRoomAnaesthesiaMonitoring, formName: 'ot_room_anaesthesia_monitoring' },
      { label: "Post Anesthesia Monitoring", component: PostAnathesia, formName: 'Post_Anathesia' },
    ],
  },
  {
    label: "Surgical", subTabs: [
      { label: "Pre_Surgical", component: PreSurgical, formName: 'Pre_Surgical' },
      { label: "Per_Operation", component: Preoperation, formName: 'Per_Operation' },
      { label: "Post_Operation", component: PostOperation, formName: 'Post_Operation' },
    ],
  },
  { label: "OT_Nurse", component: OT_Nurse, formName: 'OT_Nurse' },
  { label: "Treatment_Chart", component: TreamentChart, formName: 'Treatment_Chart' },
  { label: "DPN", component: DailyProgressNotes, formName: 'Daily_Progress_Notes' },
  { label: "Vital_IOP_Chart", component: VitalAndIOPChart, formName: 'Vital_And_IOP_Chart' },
  { label: "Call_To_Doctor", component: CallToDoctor, formName: 'Call_To_Doctor' },
  { label: "OT", component: OT, formName: 'OT' },
];

const calculateAge = (dob: any) => {
  if (!dob) return '';
  const birthDate = dayjs(dob);
  const today = dayjs();
  return today.diff(birthDate, 'year');
};

const TabbedForm: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSubTab, setActiveSubTab] = useState<number>(0);
  const [tabData, setTabData] = useState<any>({});
  const [historyData, setHistoryData] = useState<any>({});
  const [fetchedData, setFetchedData] = useState<any>({});
  const [age, setAge] = useState<any>();
  const profile = useSelector((state: any) => state?.profileSlice?.profile);
  const dispatch = useDispatch()
  const [knownData, setKnownData] = useState<any>();
  const navigate = useNavigate()
  const location = useLocation();
  const { otherTabsData } = location.state || {};
  const [loading, setLoading] = useState<boolean>(false)
  const [opdData, setOpdData] = useState<any>()
  const saveAndNext: any = useRef(null);
  const back: any = useRef(null);
  const save: any = useRef(null);
  const saveAndExit: any = useRef(null);
  const [admissionType, setAdmissionType] = useState<string>(otherTabsData?.admissionType); 
  const [filteredTabComponents, setFilteredTabComponents] = useState<any>(tabComponents); 
  const [openDialog, setOpenDialog] = useState(false);
  const [newAdmissionType, setNewAdmissionType] = useState<string>('');
  const [searchId, setSearchId] = useState<any>();
  const [patientId, setPatientId] = useState<any>();
  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenDialog(true)
    const newAdmissionType = event.target.checked ? 'Surgical' : 'Medical'; 
    setNewAdmissionType(newAdmissionType)
  }

  const handleConfirmChange = async () => {
    setAdmissionType(newAdmissionType); 
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateIpdAdmissionById/${otherTabsData?._id}`,
        {
          admissionType: newAdmissionType,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success('Admission type updated successfully!');
      filterTabsBasedOnAdmissionType(newAdmissionType);
      setOpenDialog(false)
    } catch (error) {
      toast.error('Failed to update admission type.');
      console.error('Error updating admission type:', error);
    } finally {

    }
  };
  const handleCancelChange = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        saveAndNext.current.click();
      }
      else if (event.key === "Backspace" && event.ctrlKey) {
        event.preventDefault();
        back.current.click();
      }
      else if (event.key === "s" && event.ctrlKey) {
        event.preventDefault();
        save.current.click();
      } else if (event.which == 27) {
        event.preventDefault();
        saveAndExit.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (otherTabsData) {
      getDataById()
      setAge(calculateAge(otherTabsData?.patientData?.dob));
      setPatientId(otherTabsData?.patientData?._id)
      setSearchId(otherTabsData?._id)
    }
  }, [otherTabsData]);
  const filterTabsBasedOnAdmissionType = (admissionType: string) => {
    const filteredTabs = tabComponents?.filter((tab: any) => {
      if (admissionType === 'Medical') {
        return !['Surgical', 'Anesthesia', 'OT_Nurse', 'OT']?.includes(tab?.label);
      }
      return true; 
    });
    setFilteredTabComponents(filteredTabs); 
  };

  useEffect(() => {
    filterTabsBasedOnAdmissionType(admissionType); 
  }, [admissionType]);
  const getDataById = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_OPD_API_URL}/opd-prescription/getLastByPatientId/${otherTabsData?.patientData?._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      const fetchedData = response?.data?.data?.[0];
      setOpdData(fetchedData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    dispatch(fetchProjectProfileData() as any)
  }, [dispatch])
  // const filteredTabComponents = tabComponents.filter((tab: any) => {
  //   if (otherTabsData?.admissionType === ADMISSION_TYPE.MEDICAL) {
  //     if (
  //       tab.label === 'Surgical' ||
  //       tab.label === 'Anesthesia' ||
  //       tab.label === 'OT_Nurse' ||
  //       tab.label === 'OT'
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true;
  // });
  const fetchFormData = async (formName: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setFetchedData(response?.data?.data?.formData);
      setTabData((prevData: any) => ({
        ...prevData,
        [activeTab]: response?.data?.data,
      }));
    } catch (error) {
      toast.error('Failed to fetch form data.');
    }
  };
  const fetchknownDataForHeader = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_IPD_API_URL}/patient-general-info/getPatientGenerlInfo/${otherTabsData?.patientData?._id}`,
      headers: {
        'accept': '*/*',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    };
    axios.request(config)
      .then((response) => {
        setKnownData(response?.data?.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const formName = filteredTabComponents[activeTab]?.subTabs
      ? filteredTabComponents[activeTab]?.subTabs[activeSubTab]?.formName
      : filteredTabComponents[activeTab]?.formName;
    fetchFormData(formName);
    fetchknownDataForHeader()
  }, [activeTab, activeSubTab]);

  const handleTabChangeWithData = (data: any) => {
    setTabData((prevData: any) => ({
      ...prevData,
      [activeTab]: data,
    }));
  };
  const handlehistorydata = (data: any) => {
    setHistoryData((prevData: any) => ({
      ...prevData,
      [activeTab]: data,
    }));
  };
  useEffect(() => {
    setAge(calculateAge(otherTabsData?.patientData?.dob));
  }, [otherTabsData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    const activeTabIndex = filteredTabComponents.findIndex((t: any) => t.label === tab);
    if (activeTabIndex !== -1) setActiveTab(activeTabIndex);
  }, [location.search]);

  const handleNext = async () => {
    setLoading(true);
    await handleSave(false);
    const currentTab = filteredTabComponents[activeTab];
    if (currentTab?.subTabs) {
      if (activeSubTab < currentTab.subTabs.length - 1) {
        handleSubTabChange(activeSubTab + 1);
      } else {
        if (activeTab < filteredTabComponents.length - 1) {
          handleTabChange(activeTab + 1);
          setActiveSubTab(0);
        }
      }
    } else {
      if (activeTab < filteredTabComponents.length - 1) {
        handleTabChange(activeTab + 1);
        setActiveSubTab(0);
      }
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (activeTab === 0) {
      navigate('/ipd-dashboard');
    } else {
      handleTabChange(activeTab - 1);
      setActiveSubTab(0);
    }
  };

  const handleSaveObsData = async (patientData: any) => {
    const url = `${process.env.REACT_APP_IPD_API_URL}/patient-general-info/updatePatientGenerlInfo`;
    const headers = {
      'Accept': '*/*',
      'Authorization': `Bearer ${localStorage.getItem("token")}`,
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({
      patientId: otherTabsData?.patientData?._id,
      knownAllergies: patientData.knownAllergies,
      knownDeseise: patientData.knownDeseise,
      pregnancyInfo: patientData.ObsHistory,
    });
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: body,
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Request failed', error);
      return null;
    }
  }
  const handleSave = async (shouldClose?: boolean,searchId?: any, patientId?:any) => {
    setLoading(true);
    if (activeTab === 0) {
      await handleSaveObsData(historyData[0])
    }
    const dataToSave = tabData[activeTab];
    const formName = filteredTabComponents[activeTab]?.subTabs
      ? filteredTabComponents[activeTab]?.subTabs[activeSubTab]?.formName
      : filteredTabComponents[activeTab]?.formName;

    try {
      const response = await axios.post(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/CreateFormData`, {
        formName,
        formData: dataToSave,
        searchId :otherTabsData?._id || searchId,
        patientId :otherTabsData?.patientData?._id || patientId,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (shouldClose) {
        navigate('/ipd-dashboard')
      }
      toast.success(response?.data?.message)
    } catch (error) {
      setLoading(false)
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (newTab: number) => {
    setActiveTab(newTab);
    setActiveSubTab(0);
    navigate(`?tab=${newTab}`, { state: { otherTabsData } });
    const currentTab = filteredTabComponents[newTab];
    navigate(`?tab=${currentTab.label}`, { state: { otherTabsData } });
  };
  const handleSubTabChange = (newSubTab: number) => {
    setActiveSubTab(newSubTab);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#fff',
    }}
      tabIndex={0} >
      <Box sx={{
        width: '100%', height: { md: '75vh', sm: '100%', xs: '100%' }, overflowY: 'auto',
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}>
        <Box sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1000 }}>
          <Box sx={{ p: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Known disease: <span style={{ fontWeight: '700' }}>{knownData?.knownDeseise || 'N/A'}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Known Allergies: <span style={{ fontWeight: '700' }}>{knownData?.knownAllergies || 'N/A'}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Doctor Name: {otherTabsData?.doctorName}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4} md={1}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  MRN: <span style={{ fontWeight: '700' }}>{otherTabsData?.patientData?.mrn}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={1}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  P.Name: <span style={{ fontWeight: '700' }}>{otherTabsData?.patientData?.patientName}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={1}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Age: <span style={{ fontWeight: '700' }}>{age}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={1}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Gender: <span style={{ fontWeight: '700' }}>{otherTabsData?.patientData?.gender}</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Tabs
            value={activeTab}
            onChange={(e, newValue) => handleTabChange(newValue)}
            variant="scrollable"
            sx={{padding:'0px', background:'#f5f5f5'}}
          >
            {filteredTabComponents?.map((tab: any, index: any) => (
              <Tab key={index} label={tab.label.replace(/_/g, ' ')} sx={{fontWeight:'700', fontSize:'12px', minWidth:'50px', padding:'4px 8px'}} />
            ))}
          </Tabs>

          <Divider />
        </Box>

        {filteredTabComponents[activeTab]?.subTabs ? (
          <Box >
            {/* onChange={(e, newValue) => setActiveSubTab(newValue)} */}
            <Tabs value={activeSubTab} onChange={(e, newValue) => handleSubTabChange(newValue)} variant={"scrollable"}>
              {filteredTabComponents[activeTab]?.subTabs?.map((subTab: any, index: any) => (
                <Tab key={index}
                  // label={subTab.label}
                  label={subTab.label.replace(/_/g, ' ')} // Replace underscores with spaces
                />
              ))}
            </Tabs>
            <Divider />
            <Box p={1.5}>
              {React.createElement(filteredTabComponents[activeTab]?.subTabs[activeSubTab]?.component, {
                onChange: handleTabChangeWithData, // Update tab data on change
                data: fetchedData || null,
                otherTabsData,
                profile,
                opdData,
                handleSave: handleSave,
              })}
            </Box>
          </Box>
        ) : (
          <Box p={1}>
            {React.createElement(filteredTabComponents[activeTab]?.component, {
              onChange: handleTabChangeWithData,
              onhistorychange: handlehistorydata,
              data: fetchedData || null,
              otherTabsData,
              profile,
              onhistorychnagedata: knownData,
              handleSaveObsData: handleSaveObsData,
              opdData,
              handleSave: handleSave,
            })}
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, pl: 1, pb: 1 }}>
          <Button variant='outlined' onClick={() => navigate("/ipd-dashboard")}>
            Go To Dashboard
          </Button>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Box>
              <Typography variant='h6' sx={{ fontSize: "12px", fontWeight: "600" }}>
                Medical
              </Typography>
            </Box>
            <Switch
              checked={admissionType === 'Surgical'} // Surgical side is selected if admissionType is Surgical
              onChange={handleSwitchChange}
              // disabled={admissionType === 'Surgical'}
              disabled={false}
            />
            <Box>
              <Typography variant='h6' sx={{ fontSize: "12px", fontWeight: "600" }}>
                Surgical
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pr: 1, pb: 1 }}>
          <Tooltip title='Ctrl + Backspace' arrow placement='top'>
            <Button variant="outlined" ref={back} onClick={handleBack}
            >Back</Button>
          </Tooltip>
          <Tooltip title='Escape' arrow placement='top'>
            <Button variant="outlined" ref={saveAndExit} onClick={() => handleSave(true)}>
              {loading ? <CircularProgress sx={{ color: '#fff' }} size={24} /> : 'Save & Exit'}
            </Button>
          </Tooltip>
          <Tooltip title='Ctrl + s' arrow placement='top'>
            <Button ref={save} variant="outlined" onClick={() => handleSave(false)}>
              {loading ? <CircularProgress sx={{ color: '#fff' }} size={24} /> : 'Save'}
            </Button>
          </Tooltip>
          {activeTab < filteredTabComponents?.length - 1 && (
            <Tooltip title='Shift + Enter' arrow placement='top'>
              <Button variant="contained" ref={saveAndNext} onClick={handleNext}>
                {loading ? <CircularProgress sx={{ color: '#fff' }} size={24} /> : 'Save & Next'}
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
      {/* <Dialog
        open={openDialog}
        onClose={handleCancelChange}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm Admission Type Change
        </DialogTitle>
        <DialogContent>
          <Typography id="confirmation-dialog-description">
            Are you sure you want to change the admission type from MEDICAL tO SURGICAL?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChange} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openDialog}
        onClose={handleCancelChange}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm Admission Type Change
        </DialogTitle>
        <DialogContent>
          <Typography id="confirmation-dialog-description">
            Are you sure you want to change the admission type from{' '}
            <strong>{admissionType}</strong> to <strong>{newAdmissionType}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChange} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default TabbedForm;
