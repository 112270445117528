import React from 'react';

const PathologyIcon: React.FC = () => {
    return (
        <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 21.7H16.8667M2.13333 18.3H4.4M3.26667 18.3V21.7M7.23333 12.0667L5.53333 13.7667M14.6 1.29999L18 4.69999M8.93333 21.7C10.3466 21.7001 11.7248 21.2599 12.8764 20.4405C14.0279 19.6212 14.8955 18.4634 15.3586 17.1281C15.8217 15.7928 15.8572 14.3464 15.4602 12.9901C15.0633 11.6337 14.2536 10.4346 13.1437 9.55972M5.53333 10.3667L8.93333 13.7667L15.7333 6.96665L12.3333 3.56665L5.53333 10.3667Z" stroke="#2A2A2A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default PathologyIcon;
