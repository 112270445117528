import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Grid,
  Typography,
  Divider,
  Checkbox,
  Dialog,
  Select,
  MenuItem,
  Tooltip,
  DialogContent,
  DialogActions,
  Autocomplete,
  Chip,
} from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicTable from "../../Dynamic-table";
import { useNavigate } from "react-router-dom";
import MedicineDoses, { MedicineDose } from "../../OPD/add-patient-record/MedicineDoses";
import { doctor } from "../../../assets/SVG/employ";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchProjectProfileData } from "../../../redux/reducers/projectProfileSlice";
import debounce from "lodash.debounce";
import DischargeReport from "../../../pages/IPD/dashboard/formsReports/discharge-report";
import DeathReport from "../../../pages/IPD/dashboard/formsReports/death-report";
import CustomAutocomplete from "../../autocomplete-field";
function formatDate(timestamp: string): string {
  const date = dayjs(timestamp);
  return date.format("DD/MM/YYYY - h:mma").toUpperCase();
}
interface DischargeModalProps {
  dischargeData: any;
  handleClose?: () => void;
  isEmergency?: any;
  isRefDeathComp?: boolean;
  refStatus?: boolean;
  deathStatus?: boolean;
  discharge?: boolean;
  // handleCloseRefer?: (() => void | undefined) | undefined
  referal?: boolean
  refId?: any
  death?: boolean
}

const DischargeModal: React.FC<DischargeModalProps> = ({
  dischargeData,
  handleClose,
  isEmergency,
  refStatus,
  deathStatus,
  referal,
  discharge,
  death,
  isRefDeathComp
  // handleCloseRefer
}) => {
  const profile = useSelector((state: any) => state?.profileSlice?.profile);
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<any>({
    mrn: 0,
    patientName: "",
    guardiansName: "",
    sex: "",
    age: "",
    weight: "",
    admissionDateAndTime: dayjs(),
    dateOfDischarge: dayjs(),
    diagnosisSignificant: "",
    surgeonName: "",
    procedureDone: "",
    anaesthesia: "",
    operativeFindings: "",
    dischargeAdvisedBy: "",
    conditionOnDischarge: "satisfactory",
    systematicExamination: {},
    discharge_note: '',
    medicines: [],
    examination: [],
    diagnosis: [],
    finalDiagnosis: '',
    referalNote: '',
    referTo: '',
    resonOfRefer: '',
    doctorName: profile?.data?.fullName,
    dateTime: dayjs().format('DD.MM.YYYY hh:mm A'),
    dateAndTime: dayjs().format('DD.MM.YYYY'),
    department: "",
    foodInstructions: "",
    dischargeTo: 'discharge'
  });  
  const [isRefDeathComps, setIsRefDeathComps] = useState<any>(isRefDeathComp)
  const [secondOpen, setSecondOpen] = useState(false);
  const [apiData, setApiData] = useState<any>(null);
  const [discountPercentage, setDiscountPercentage] = useState<number | undefined>();
  const [discountRupees, setDiscountRupees] = useState<number | undefined>();
  const [netCharges, setNetCharges] = useState(0);
  const [totalNetCharges, setTotalNetCharges] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [toPayAmount, setToPayAmount] = useState<number>(0);
  const [searchId, SetSerachId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [payableamount, setPayableAmount] = useState(0);
  const navigate = useNavigate();
  const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
  const [open, setOpen] = useState<boolean>(false)
  const [doctors, setDoctors] = useState<any[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [searchDoc, setSearchDoc] = useState<any>("")
  const [dischargeRep, setDischargeRep] = useState<boolean>(false)
  const [deathRep, setDeathRep] = useState<boolean>(false)
  const [RefRep, setRefRep] = useState<boolean>(false)
  const [allDiagnosis, setAllDiagnosis] = useState<any[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<any>([]);
  const [searchDiagnosis, setSearchDiagnosis] = useState<any>("");
  const [inputValueDiagnosis, setInputValueDiagnosis] = React.useState("");
  const [fetchedDiagnosis, setFetchedDiagnosis] = useState<any>([]);
  const [opdDiagnosis, setOpdDiagnosis] = useState<any>([]);
  const [dischargeDiagnosis, setDischargeDiagnosis] = useState<any>([])
  const debouncedGetDiagnosis = useCallback(
    debounce(async (search: string) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_OPD_API_URL}/diagnosis/getAllSelectAbleDiagnosis?search=${search}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const fetchedDiagnosis = response?.data?.data || [];
        setAllDiagnosis(fetchedDiagnosis);
      } catch (error) {
        console.error(error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    debouncedGetDiagnosis(searchDiagnosis);
  }, [debouncedGetDiagnosis, searchDiagnosis]);

  const handleSelectChangeDiagnosis = (event: { target: { value: any } }) => {
    const selectedDiagnosisNames = event.target.value;
    const newDiagnoses = selectedDiagnosisNames.filter((diagnosisName: string) =>
      !selectedDiagnosis?.includes(diagnosisName)
    );

    const selectedDiagnosisObjects = allDiagnosis?.filter((diagnosis: any) =>
      newDiagnoses.includes(diagnosis?.diagnosisName)
    );

    const updatedSelectedDiagnosis = [
      ...selectedDiagnosis,
      ...selectedDiagnosisObjects?.map((diagnosis: any) => diagnosis.diagnosisName)
    ];

    setSelectedDiagnosis(Array.from(new Set(updatedSelectedDiagnosis)));
  };

  const handleInputChangeDiagnosis = (
    event: any,
    newInputValue: React.SetStateAction<string>
  ) => {
    setInputValueDiagnosis(newInputValue);
    setSearchDiagnosis(newInputValue);
  };

  const handleInputChangeDiagnosisBlur = () => {
    setInputValueDiagnosis("");
    setSearchDiagnosis("");
  };

  const handleAddButtonClickDiagnosis = () => {
    if (inputValueDiagnosis.trim() !== "") {
      handleCreateNewDiagnosis(inputValueDiagnosis);
      setInputValueDiagnosis("");
    }
  };
  const handleCreateNewDiagnosis = async (inputValue: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_OPD_API_URL}/diagnosis/createDiagnosis`,
        { diagnosisName: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const newDiagnosis = response?.data?.data;
      if (newDiagnosis) {
        setSelectedDiagnosis((prevSelected: any) => [...prevSelected, newDiagnosis]);
        setSearchDiagnosis("");

        setAllDiagnosis((prevGenerics: any) => [...prevGenerics, newDiagnosis]);
        toast.success(response.data?.message);
      }
    } catch (error: any) {
      console.error("Error creating new diagnosis:", error);
      toast.error(error.message);
    }
  };

  const handleKeyPressDiagnosis = (event: { key: string; preventDefault: () => void; }) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddButtonClickDiagnosis();
    }
  };

  const handleDeleteChipDiagnosis = (diagnosisToDelete: string) => {
    const updatedSelectedDiagnosis = selectedDiagnosis?.filter((diagnosis: string) => diagnosis !== diagnosisToDelete);
    setSelectedDiagnosis(updatedSelectedDiagnosis);
  };

  useEffect(() => {
    if (dischargeDiagnosis?.length > 0) {
      setSelectedDiagnosis(dischargeDiagnosis);
    } else {
      const allDiagnoses = [
        ...fetchedDiagnosis,
        ...opdDiagnosis
      ];
      const uniqueDiagnoses = Array.from(new Set(allDiagnoses));
      setSelectedDiagnosis(uniqueDiagnoses);
    }
  }, [fetchedDiagnosis, opdDiagnosis, dischargeDiagnosis]);

  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      diagnosis: selectedDiagnosis
    }))
  }, [selectedDiagnosis])

  useEffect(() => {
    dispatch(fetchProjectProfileData() as any)
  }, [dispatch])

  const handleDateChange = (newValue: any) => {
    setFormData({
      ...formData,
      dateAndTime: newValue ? newValue.format('DD.MM.YYYY') : null,
    });
  };

  const handleAdvanceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = Math.max(0, Number(e.target.value));
    setAdvanceAmount(value);
  };

  const calculateNetCharges = () => {
    let discount = discountRupees ?? 0;
    const percentage = discountPercentage ?? 0;

    if (percentage > 0) {
      discount = (totalNetCharges * percentage) / 100;
    }

    const netCharges = parseFloat((totalNetCharges - discount).toFixed(1));
    setNetCharges(netCharges);

    const payableamount = parseFloat((netCharges - advanceAmount).toFixed(1));

    setPayableAmount(payableamount);
    if (toPayAmount > 0) {
      setRemainingAmount(payableamount - toPayAmount);
    } else {
      setRemainingAmount(payableamount);
    }
  };

  const handleDiscountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    const numericValue = Math.max(0, Number(value.replace(/^0+/, "")));

    if (name === "discountPercentage") {
      const percentage = Math.min(100, numericValue);
      setDiscountPercentage(percentage || undefined);
      setDiscountRupees((totalNetCharges * percentage) / 100 || 0);
    } else if (name === "discountRupees") {
      const discount = Math.min(totalNetCharges, numericValue);
      setDiscountRupees(discount || undefined);
      setDiscountPercentage((discount / totalNetCharges) * 100 || 0);
    }
    calculateNetCharges();
  };

  useEffect(() => {
    if (!selectedStatus) {
      setSelectedStatus("paid");
    }
  }, []);

  useEffect(() => {
    if (apiData) {
      const servicesTotalNetCharges = apiData?.services?.reduce(
        (total: number, service: any) => total + service?.totalCharges,
        0
      );
      let totalcherges =
        servicesTotalNetCharges +
        apiData?.rooms?.reduce((sum: number, obj: any) => {
          return sum + obj.totalCharges;
        }, 0);

      setTotalNetCharges(totalcherges);
      calculateNetCharges();
      setAdvanceAmount(apiData?.advancePayment);
    }
  }, [apiData, discountPercentage, discountRupees, totalNetCharges]);
  useEffect(() => {
    getDisAndTotal();
  }, [searchId, totalNetCharges, apiData?.advancePayment]);

  useEffect(() => {
    if (totalNetCharges > 0) {
      calculateNetCharges();
    }
  }, [discountPercentage, discountRupees]);
  const handlePayableChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = e.target.value?.replace(/[^0-9]/g, "");
    if (value === "") {
      setToPayAmount(0);
      setRemainingAmount(payableamount);
      return;
    }
    let numericValue = Math.max(0, Number(value));
    if (numericValue > payableamount) {
      numericValue = payableamount;
    }
    setToPayAmount(numericValue);
    setRemainingAmount(parseFloat((payableamount - numericValue).toFixed(1)));
  };
  useEffect(() => {
    if (secondOpen) {
      const remaining = totalNetCharges - advanceAmount - toPayAmount;
      setRemainingAmount(parseFloat(remaining.toFixed(1)));
    }
  }, [secondOpen, totalNetCharges, advanceAmount, toPayAmount]);

  const resetForm = () => {
    setFormData({
      mrn: 0,
      patientName: "",
      guardiansName: "",
      sex: "",
      age: "",
      weight: "",
      admissionDateAndTime: dayjs(),
      dateOfDischarge: dayjs().format('DD.MM.YYYY hh:mm A'),
      briefHistory: "",
      diagnosisSignificant: "",
      surgeonName: "",
      operativeFindings: "",
      dischargeAdvisedBy: "",
      conditionOnDischarge: "satisfactory",
      medicines: [],
      examination: [],
      diagnosis: [],
      procedureDone: '',
      referalNote: '',
      referTo: '',
      resonOfRefer: '',
      dischargeTo: 'discharge'
    });
  };
  const calculateAge = (dob: string) => {
    const dobDate = dayjs(dob);
    const today = dayjs();
    const age = today.diff(dobDate, "year");
    return age;
  };
  useEffect(() => {
    const dob = dischargeData?.patientData?.dob;
    const age = calculateAge(dob);
    setFormData({
      mrn: dischargeData?.mrn,
      patientName: dischargeData?.patientName,
      guardiansName: dischargeData?.guardiansName,
      sex: dischargeData?.patientData?.gender?.charAt(0),
      age: age?.toString(),
      weight: dischargeData?.weight,
      admissionDateAndTime: dayjs(dischargeData?.admissionDateAndTime),
      surgeonName: dischargeData?.doctorName,
      dateOfDischarge: dayjs(formData?.dateOfDischarge),
      conditionOnDischarge: formData?.conditionOnDischarge,
      dischargeTo: formData?.dischargeTo,
      finalDiagnosis: dischargeData?.finalDiagnosis
    });
    setOpdDiagnosis(dischargeData?.diagnosis);
  }, [dischargeData]);
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDischargeDateTimeChange = (newValue: any) => {
    if (newValue && dayjs(newValue).isValid()) {
      setFormData({
        ...formData,
        dateOfDischarge: dayjs(newValue), // Store the dayjs object directly
      });
    }
  };

  const getDisAndTotal = () => {
    if (searchId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_IPD_API_URL}/ipd-clearance/findOneByAdmissionId/${searchId}`,
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          const data = response?.data?.data;
          setDiscountRupees(data?.discountRS);
          setDiscountPercentage((data?.discountRS / totalNetCharges) * 100 || 0);
          setSelectedStatus(data?.paidStatus);
          setToPayAmount(data?.paidAmmount);
          setNetCharges(data?.netCharges);
          // setRemainingAmount(data?.dueAmmount);
          setRemainingAmount(data?.dueAmmount || totalNetCharges - advanceAmount);
        })
        .catch((error) => {
          console.error("Error fetching discharge data:", error);
        });
    }
  };

  const updateStatus = async (id: any, isEmergency: boolean) => {
    const url = isEmergency
      ? `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateEmergencyStatusById/${id}`
      : `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateIpdAdmissionStatusById/${id}`;

    const data = isEmergency
      ? { emergencyStatus: referal ? "Referred" : "Discharge" }
      : { patientStatus: referal ? "Referred" : "Discharge" };

    try {
      await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const fetchApiData = async (id: any) => {
    const url = `${process.env.REACT_APP_IPD_API_URL}/ipd-admission-services/getByIpdAdmissionId/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setApiData(response?.data?.data);
    } catch (error) {
      toast.error("Failed to fetch API data.");
    }
  };

  useEffect(() => {
    if (secondOpen || isRefDeathComps) {
      fetchApiData(dischargeData?._id)
      setSecondOpen(true)
      SetSerachId(dischargeData?._id)
    }
  }, [secondOpen, isRefDeathComps])

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/CreateFormData`,
        {
          formName: referal ? "Patient Refferal Form" : "DischargeForm",
          formData: formData,
          searchId: dischargeData?._id,
          patientId: dischargeData?.patientData?._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setSecondOpen(true);
      SetSerachId(response?.data?.data?.searchId);
      setIsRefDeathComps(true)
      await fetchApiData(dischargeData?._id);
      toast.success(response?.data?.message);
      resetForm();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleBackDischarge = async () => {
    if (death) {
      handleCloseDialog()
    }
    setSecondOpen(false)
    setIsRefDeathComps(false)
    if (referal) {
      fetchFormData('Patient Refferal Form');
    } else {
      fetchFormData('DischargeForm');
    }
  }
  const handleSecondClose = () => {
    setSecondOpen(false);
  }
  const handleDischarge = async () => {
    let data = JSON.stringify({
      ipdAdmissionId: dischargeData?._id,
      totalCharges: totalNetCharges || 0,
      discountRS: discountRupees || 0,
      netCharges: netCharges || 0,
      paidAmmount: toPayAmount || 0,
      dueAmmount: remainingAmount || 0,
      paidStatus: selectedStatus,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_IPD_API_URL}/ipd-clearance/createIPDClearance`,
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      toast.success(response?.data?.message);
      // if (
      //   (refStatus === false && deathStatus === false) ||
      //   isEmergency === false
      // ) {
      if (discharge || referal) {
        await updateStatus(dischargeData?._id, isEmergency);
      }
      if (referal) {
        setRefRep(true)
      } if (discharge) {
        setDischargeRep(true)
      }
      if (death) {
        setDeathRep(true)
      }
      // }
      // handleSecondClose();
      // handleClose();
      // setDischargeRep(true)
      // navigate("/ipd-dashboard");
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };
  const handleCloseDialog = () => {
    if (handleClose) {
      handleClose();
    }
    setSecondOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
  }

  const fetchFormData = async (formName: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${dischargeData?._id}?formName=${formName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const fetchedData = await response?.data?.data?.formData
      if (formName === "History") {
        // setFormData((prevData: any) => ({
        //   ...prevData,
        //   briefHistory: fetchedData?.detailed_History,
        //   presentingComplaints: fetchedData?.presenting_Complaints
        // }));
        setFormData((prevData: any) => ({
          ...prevData,
          briefHistory: fetchedData?.detailed_History,
          presentingComplaints: [
            // Convert the object into an array of its keys or values
            ...Object.keys(fetchedData?.presenting_Complaints || {})?.map(key => ({
              complaint: key, value: fetchedData?.presenting_Complaints[key]
            })),
            // Merge opd_Symptoms array directly
            ...(Array.isArray(fetchedData?.opd_Symptoms) ? fetchedData?.opd_Symptoms : [])
          ]
        }));
      } else if (formName == "Examination") {
        // setFormData((prevData: any) => ({
        //   ...prevData,
        //   examinations: fetchedData?.examinations,
        //   systematicExamination: fetchedData?.examinations_Without_GPE
        // }))
        setFormData((prevData: any) => ({
          ...prevData,
          examinations: [
            // Merge `examinations` (array), `opd_Examination` (array), and `examinations_Without_GPE` (converted to an array)
            ...(Array.isArray(fetchedData?.examinations) ? fetchedData?.examinations : []),
            ...(Array.isArray(fetchedData?.opd_Examination) ? fetchedData?.opd_Examination : []),
          ],
          systematicExamination: fetchedData?.examinations_Without_GPE || {}
        }));

      }
      else if (formName == "Pre_Anesthesia_Assessment") {
        setFormData((prevData: any) => ({
          ...prevData,
          anaesthesia: fetchedData?.anaesthesia_plan
        }))
      }
      else if (formName == "Treatment_Chart") {
        setMedicineData(fetchedData?.medicines || [])
      }
      else if (formName == "Per_Operation") {
        setFormData((prevData: any) => ({
          ...prevData,
          operativeFindings: fetchedData?.operative_Findings,
          procedureDone: fetchedData?.procedure_done?.procedure
        }))
      } else if (formName === "Pre_Surgical") {
        const diagnosis = fetchedData?.diagnosis || [];
        const opdDiagnosis = fetchedData?.opd_diagnosis || [];

        const mergedDiagnosis = [
          ...diagnosis,
          ...opdDiagnosis?.filter((opd: any) =>
            !diagnosis?.some((diagnosisItem: any) => diagnosisItem === opd)
          )
        ];
        setFetchedDiagnosis(mergedDiagnosis);
      }
      else if (formName === "DischargeForm") {
        if (fetchedData) {
          setFormData((prevData: any) => ({
            ...prevData,
            conditionOnDischarge: fetchedData?.conditionOnDischarge,
            dischargeAdvisedBy: fetchedData?.dischargeAdvisedBy,
            // operativeFindings: fetchedData?.operative_Findings,
            discharge_note: fetchedData?.discharge_note,
            // dischargeDate: dayjs(fetchedData?.dischargeDate),
            dischargeTo: fetchedData?.dischargeTo,
            dateOfDischarge: dayjs(fetchedData?.dateOfDischarge),
            diagnosisSignificant: fetchedData?.diagnosisSignificant,
            dateAndTime: fetchedData?.dateAndTime,
            department: fetchedData?.department,
            foodInstructions: fetchedData?.foodInstructions,
          }));
          setMedicineData(fetchedData?.medicines || []);
          setSelectedDoctor(
            doctors?.find((doc: any) => doc?._id === fetchedData?.doctorId) || null
          );
          setDischargeDiagnosis(fetchedData?.diagnosis)
        } else {
          fetchFormData('Treatment_Chart');
        }
      } else if (formName === "Patient Refferal Form") {
        if (fetchedData) {
          setFormData((prevData: any) => ({
            ...prevData,
            conditionOnDischarge: fetchedData?.conditionOnDischarge,
            dischargeAdvisedBy: fetchedData?.dischargeAdvisedBy,
            dischargeDate: fetchedData?.dischargeDate,
            dateOfDischarge: dayjs(fetchedData?.dateOfDischarge),
            resonOfRefer: fetchedData?.resonOfRefer,
            referTo: fetchedData?.referTo,
            referalNote: fetchedData?.referalNote,
            diagnosisSignificant: fetchedData?.diagnosisSignificant
          }));
          setMedicineData(fetchedData?.medicines || []);
          setSelectedDoctor(
            doctors?.find((doc: any) => doc?._id === fetchedData?.doctorId) || null
          );
        } else {
          fetchFormData('Treatment_Chart');
        }
      }
    }
    catch (error) {
      toast.error('Failed to fetch form data.');
    }
  };

  useEffect(() => {
    fetchFormData('History');
    fetchFormData('Examination');
    fetchFormData('Pre_Surgical');
    fetchFormData('Per_Operation');
    fetchFormData('OT');
    fetchFormData('Pre_Anesthesia_Assessment');
    fetchFormData('Treatment_Chart');
    if (referal) {
      fetchFormData('Patient Refferal Form');
    } else {
      fetchFormData('DischargeForm');
    }
  }, [referal]);

  const handleMedicineDoses = (medicineDoses: MedicineDose[], deletedInfo?: { medicine: MedicineDose; deletedTime: string; deletedBy: string }) => {
    const updatedFormData: any = {
      ...formData,
      medicines: medicineDoses,
    };
    setFormData(updatedFormData);
  };

  const fetchDoctors = async (search: any) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores?count=50000&search=${search}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      const fetchedDoc = response.data?.data || [];
      const uniqueDoc = Array.from(
        new Map(fetchedDoc?.map((item: any) => [item?.fullName, item]))?.values()
      );
      setDoctors(uniqueDoc);
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedFetchDoctor = useCallback(debounce(fetchDoctors, 500), []);

  useEffect(() => {
    fetchDoctors(searchDoc)
  }, [])

  const handleDoctorChange = (newValue: any | null) => {
    setSelectedDoctor(newValue); // You can store the entire object here
    setFormData((prevData: any) => ({
      ...prevData,
      surgeonName: newValue, // Store the full object in formData
    }));
  };

  const handleInputChangeDoctor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchDoc(value);
    debouncedFetchDoctor(value);
  }
  const handleInputChangeDoctorBlur = () => {
    setSearchDoc("")
    debouncedFetchDoctor("")
  }

  return (
    <Box sx={{ p: 1, background: '#fff' }}>

      {!isRefDeathComps ? <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            {referal ? "Refferal Form" : "Discharge Form"}
          </Typography>
          <Box display='flex' alignItems='center' gap={1}>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Name : {formData?.patientName}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Guardian : {formData?.guardiansName}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              MRN : {formData?.mrn}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Sex : {formData?.sex}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Age : {formData?.age}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Weight : {formData?.weight}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: "4px", mb: 1 }} />
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Admission Date and Time:
              </Typography>
              <Box
                sx={{
                  "& .css-a643pv-MuiStack-root": {
                    paddingTop: "0px",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled
                    value={
                      dayjs(formData?.admissionDateAndTime)
                    }
                    format='DD.MM.YYYY hh:mm A'
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "38px",
                        fontSize: "12px",
                        overflow: "hidden",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        border: "none",
                        backgroundColor: "#F5F5FB",
                      },
                      "& .MuiCalendarPicker-root": {
                        overflow: "hidden",
                      },
                      "& .css-4jnixx-MuiStack-root": {
                        paddingTop: "0px",
                      },
                      width: "100%",
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Discharge Date and Time:
              </Typography>
              <Box
                sx={{
                  "& .css-a643pv-MuiStack-root": {
                    paddingTop: "0px",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(formData?.dateOfDischarge)} // This will now be a dayjs object
                    format="DD.MM.YYYY hh:mm A"
                    // onChange={handleDischargeDateTimeChange}
                    // minDate={dayjs().startOf('day')}
                    disabled
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "38px",
                        fontSize: "12px",
                        overflow: "hidden",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        border: "none",
                        backgroundColor: "#F5F5FB",
                      },
                      "& .MuiCalendarPicker-root": {
                        overflow: "hidden",
                      },
                      "& .css-4jnixx-MuiStack-root": {
                        paddingTop: "0px",
                      },
                      width: "100%",
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Presenting Complaints :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.presentingComplaints
                    ? formData?.presentingComplaints.slice(0, 2).map((item: any, index: any) => {
                      if (typeof item === 'object' && item !== null) {
                        return item.complaint;
                      }
                      return item;
                    }).join(', ')
                    : 'No Presenting Complaints'
                }
                {formData?.presentingComplaints && (
                  formData?.presentingComplaints.length > 2 ? (
                    <Tooltip
                      placement="top"
                      title={
                        formData?.presentingComplaints.slice(2).map((item: any) => {
                          if (typeof item === 'object' && item !== null) {
                            return item.complaint;
                          }
                          return item;
                        }).join(', ')
                      }
                      arrow
                    >
                      <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                        +{formData?.presentingComplaints.length - 2} more
                      </span>
                    </Tooltip>
                  ) : null
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Brief History :
              </Typography>
              <TextField
                fullWidth
                placeholder="Brief History"
                name="briefHistory"
                multiline
                value={formData?.briefHistory}
                size="small"
                className="search-field"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                GPE Examination :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.examinations?.slice(0, 2)?.map((test: any) => test)?.join(', ') || 'No Examinations'
                }
                {formData?.examinations?.length > 2 && (
                  <Tooltip placement='top'
                    title={formData?.examinations?.slice(2)?.map((test: any) => test)?.join(', ')}
                    arrow
                  >
                    <span style={{ cursor: 'pointer', color: '#1976d2' }}> +{formData?.examinations?.length - 2} more</span>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Systematic Examination :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.systematicExamination
                    ? Object.keys(formData?.systematicExamination)?.slice(0, 2)?.map((key) => {
                      const value = formData?.systematicExamination[key];
                      if (typeof value === 'object' && value !== null) {
                        return Object.keys(value)?.join(', ');
                      }
                      return key;
                    }).join(', ')
                    : 'No Examinations'
                }
                {formData?.systematicExamination && Object.keys(formData?.systematicExamination)?.length > 2 && (
                  <Tooltip
                    placement="top"
                    title={Object.keys(formData?.systematicExamination)
                      ?.slice(2)
                      ?.map((key) => {
                        const value = formData?.systematicExamination[key];
                        if (typeof value === 'object' && value !== null) {
                          return Object.keys(value)?.join(', ');
                        }
                        return key;
                      })
                      ?.join(', ')}
                    arrow
                  >
                    <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                      +{Object.keys(formData?.systematicExamination)?.length - 2} more
                    </span>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "8px",
                  textTransform: "capitalize",
                }}
              >
                Diagnosis Investigation Significant Results: Photocopy given to
                patient :
              </Typography>
              <RadioGroup
                name="diagnosisSignificant"
                value={formData?.diagnosisSignificant}
                onChange={handleChange}
                row
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
              >
                <FormControlLabel sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  margin: 0,
                  gap: 0.5,
                }}
                  value="significant"
                  control={<Radio size="small" sx={{
                    "& .MuiSvgIcon-root": {
                      height: 12,
                      width: 12,
                    },
                    "&.MuiRadio-root": {
                      padding: 0,
                    },
                    "& .MuiButtonBase-root": {
                      padding: 0,
                      "&:hover": {
                        background: "none",
                      },
                    },
                  }} />}
                  label={<span style={{ fontSize: '10px', }}>Yes</span>}
                />
                <FormControlLabel sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  margin: 0,
                  gap: 0.5,
                }}
                  value="notSignificant"
                  control={<Radio size="small" sx={{
                    "& .MuiSvgIcon-root": {
                      height: 12,
                      width: 12,
                    },
                    "&.MuiRadio-root": {
                      padding: 0,
                    },
                    "& .MuiButtonBase-root": {
                      padding: 0,
                      "&:hover": {
                        background: "none",
                      },
                    },
                  }} />}
                  label={<span style={{ fontSize: '10px', }}>No</span>}
                />
              </RadioGroup>
            </Grid>
            {formData?.finalDiagnosis ? <Grid item xs={6} sm={4} md={2.4} >
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Final Diagnosis
              </Typography>
              <TextField
                fullWidth
                placeholder="Final Diagnosis"
                name="finalDiagnosis"
                value={formData?.finalDiagnosis}
                onChange={handleChange}
                className="search-field"
                size="small"
              />
            </Grid> : <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Diagnosis :
              </Typography>
              <CustomAutocomplete
                multiple
                options={allDiagnosis || []}
                value={selectedDiagnosis || []}
                onChange={(event, newValue) => {
                  handleSelectChangeDiagnosis({
                    target: {
                      value: newValue.map((diag: any) => diag.diagnosisName),
                    },
                  });
                }}
                getOptionLabel={(option) => option?.diagnosisName}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option: any) =>
                    option?.diagnosisName?.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                placeholder="Provisional Diagnosis/DD"
                onTextFieldChange={(event) =>
                  handleInputChangeDiagnosis(event, event.target.value)
                }
                handleBlur={handleInputChangeDiagnosisBlur}
                onKeyPress={handleKeyPressDiagnosis}
                handleAddClick={handleAddButtonClickDiagnosis}
              />
              <Box mt={0.4}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    background: selectedDiagnosis?.length > 0 ? "rgba(192, 192, 192, 0.2)" : "",
                    border: selectedDiagnosis?.length > 0 ? "2px solid #D8D8D8" : "",
                    p: 0.5,
                    borderRadius: "12px",
                  }}
                >
                  {selectedDiagnosis?.map((sign: any, index: any) => (
                    <Chip
                      size="small"
                      key={index}
                      label={sign}
                      onDelete={() => handleDeleteChipDiagnosis(sign)}
                      sx={{
                        borderRadius: "8px",
                        background: "#fff",
                        border: "1px solid rgba(0, 0, 0, 0.22)",
                        fontSize: "12px",
                        "&:hover": {
                          background: "#055FFC",
                          color: "#fff",
                          "& .MuiChip-deleteIcon": {
                            color: "#fff",
                          },
                          "& .MuiChip-icon": {
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>}
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Doctor Name :
              </Typography>
              <Autocomplete
                id="tests"
                size="small"
                fullWidth
                options={doctors || []}
                getOptionLabel={(option) => option?.fullName || ""}
                onChange={(event, value) => handleDoctorChange(value?.fullName)}
                renderInput={(params) => (
                  <Box display="flex" alignItems="center">
                    <TextField
                      {...params}
                      placeholder="Doctor"
                      onChange={handleInputChangeDoctor}
                      onBlur={handleInputChangeDoctorBlur}
                      variant="outlined"
                      // InputProps={{
                      //   ...params.InputProps,

                      // }}
                      fullWidth
                      className="search-field"
                    />
                  </Box>
                )}
                // name="surgeonName"
                value={doctors.find(doctor => doctor.fullName === formData?.surgeonName) || null}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Procedure Done :
              </Typography>
              <TextField
                fullWidth
                placeholder="Procedure Done"
                name="procedureDone"
                value={formData?.procedureDone}
                // onChange={handleChange}
                className="search-field"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Anaesthesia :
              </Typography>
              <TextField
                fullWidth
                placeholder="Anaesthesia"
                name="anaesthesia"
                value={formData?.anaesthesia}
                // onChange={handleChange}
                disabled
                className="search-field"
                size="small"
              />
            </Grid>

            {!referal && <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Operative Findings :
              </Typography>
              <TextField
                fullWidth
                placeholder="Operative Findings"
                name="operativeFindings"
                multiline
                rows={4}
                value={formData?.operativeFindings}
                // onChange={handleChange}
                disabled
                className="search-field"
                size="small"
              />
            </Grid>}
            {referal && <Grid item xs={6} sm={4} md={2.4}>
              <Typography
                sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}
              >
                Refer Note
              </Typography>
              <TextField
                fullWidth
                placeholder="Refer Note"
                name="referalNote"
                value={formData?.referalNote}
                onChange={handleChange}
                className="search-field"
                size="small"
              />
            </Grid>}

            {
              referal && (
                <Grid item xs={6} sm={4} md={2.4}>
                  <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                    Referring To :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Referring To"
                    name="referTo"
                    value={formData.referTo}
                    onChange={handleChange}
                    className="search-field"
                    size="small"
                  />
                </Grid>
              )
            }
            <Grid item xs={6} sm={4} md={2.4} >
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Discharge
              </Typography>
              <Select
                value={formData?.dischargeTo || "discharge"}
                name="dischargeTo"
                onChange={handleChange}
                sx={{
                  height: '38px',
                  borderRadius: "12px",
                  background: "#F5F5FB",
                  fontSize: "12px",
                  boxShadow: "none",
                  "& fieldset": { border: "none" },
                }}
                fullWidth
              >
                <MenuItem value="discharge">Discharge</MenuItem>
                <MenuItem value="dor">DOR</MenuItem>
                <MenuItem value="lama">LAMA</MenuItem>
              </Select>
            </Grid>
            {
              referal && (
                <Grid item xs={6} sm={4} md={2.4}>
                  <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                    Reason of Refer :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Referring Reason"
                    name="resonOfRefer"
                    value={formData?.resonOfRefer}
                    onChange={handleChange}
                    className="search-field"
                    size="small"
                  />
                </Grid>
              )
            }
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Condition at the time of  {referal ? "Refer" : "Discharge"} :
              </Typography>
              <Select
                value={formData?.conditionOnDischarge || "satisfactory"}
                name="conditionOnDischarge"
                onChange={handleChange}
                sx={{
                  height: '38px',
                  borderRadius: "12px",
                  background: "#F5F5FB",
                  fontSize: "12px",
                  boxShadow: "none",
                  "& fieldset": { border: "none" },
                }}
                fullWidth
              >
                <MenuItem value="satisfactory">Satisfactory</MenuItem>
                <MenuItem value="fair">Fair</MenuItem>
                <MenuItem value="poor">Poor</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item md={12} sm={12} xs={12} sx={{ mt: 1, mb: 1 }}>
              <MedicineDoses
                onMedicineDoses={handleMedicineDoses}
                medicineDataIfExist={medicineData}
                setMedicineData={setMedicineData}
                medicineData={medicineData}
                treatment={true}
              />
            </Grid>
          </Grid>
          {!referal && (
            <Box
              sx={{
                border: '1px solid #000',
                borderRadius: '8px',
                p: 2,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize:'12px',
                  fontFamily: 'Arial',
                  mb: 1,
                }}
              >
                Please visit the specified department on the given date for your examination.
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Department</Typography>
                  <TextField
                    className="search-field"
                    placeholder="Department"
                    // label="Department"
                    variant="outlined"
                    fullWidth
                    sx={{ background: '#fff' }}
                    size="small"
                    value={formData?.department}
                    onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Examination Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: '100%' }}>
                      <DatePicker
                        className="search-field"
                        // label="Examination Date"
                        value={formData.dateAndTime ? dayjs(formData.dateAndTime, 'DD.MM.YYYY') : null}
                        onChange={handleDateChange}
                        format="DD.MM.YYYY"
                        sx={{
                          "& fieldset": {
                            // Target the fieldset for better border control
                            border: "none", // Remove default border if present
                          },
                          "& .MuiInputBase-root": {
                            height: "38px",
                            fontSize: "12px",
                            overflow: "hidden",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            border: "none",
                            backgroundColor: "#fff",
                          },
                          "& .MuiCalendarPicker-root": {
                            overflow: "hidden",
                          },
                          "& .css-4jnixx-MuiStack-root": {
                            paddingTop: "0px",
                          },
                          // width: "100%",
                        }} />
                    </Box>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Food Instructions</Typography>
                  <TextField
                    className="search-field"
                    // label="Food Instructions"
                    placeholder="Food Instructions"
                    variant="outlined"
                    fullWidth
                    multiline
                    size="small"
                    sx={{ background: '#fff' }}
                    rows={2}
                    value={formData.foodInstructions}
                    onChange={(e) => setFormData({ ...formData, foodInstructions: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid item md={3} sm={6} xs={12}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Name </Typography>
                <TextField value={formData?.doctorName} disabled fullWidth className='search-field' size='small' />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Time </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(formData?.dateTime)}  // Ensure this is a valid Dayjs object
                    format="DD.MM.YYYY hh:mm A"
                    minDate={dayjs().startOf("day")}
                    disabled
                    sx={{
                      "& fieldset": { border: "none" },
                      "& .MuiInputBase-root": { height: "38px", fontSize: "12px", overflow: "hidden" },
                      "& .MuiOutlinedInput-root": { borderRadius: "8px", border: "none", backgroundColor: "#F5F5FB" },
                      width: "100%",
                    }}
                  />
                </LocalizationProvider>

              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
            gap={1}
            alignItems="center"
          >
            <Button onClick={handleClose} variant="outlined">
              Back
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {referal ? "Reffer" : "Clearance"}
            </Button>
          </Box>
        </Box>
      </> :

        <Dialog
          open={secondOpen}
          onClose={handleSecondClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: "12px",
              p: 2,
            },
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Clearance Form
          </Typography>

          {apiData?.rooms?.length > 0 && (
            <Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}
              >
                Room Charges
              </Typography>
              <DynamicTable
                headers={[
                  "SN",
                  "Category",
                  "Floor",
                  "Room",
                  "Bed No",
                  "Allocation Date & Time",
                  "Leave Date & Time",
                  "Status",
                  "Total Charges",
                ]}
                data={apiData?.rooms.map((room: any, index: any) => [
                  index + 1,
                  room?.roomCategoryName,
                  room?.floorName,
                  room?.roomName,
                  room?.bedNo,
                  formatDate(room?.allotedDate),
                  formatDate(room?.vacantDate),
                  room?.survivingTime,
                  room?.totalCharges,
                ])}
              />
            </Box>
          )}
          <Typography
            sx={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}
          >
            Services Charges
          </Typography>
          <DynamicTable
            headers={[
              "Services",
              "Unit Charges",
              "Unit Count",

              "Discount",
              "Net Charges",
            ]}
            data={apiData?.services?.map((test: any) => [
              test?.servicesName,
              test?.unitCharges,
              test?.unitCount,

              test?.discount,
              test?.totalCharges,
            ])}
          />

          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{
              backgroundColor: "#ECECEC",
              marginTop: "20px",
              borderRadius: "8px",
              px: 0.5,
              py: 1,
              "&.MuiGrid-root": {
                paddingTop: "0px",
              },
            }}
          >
            {[
              {
                label: "Charges:",
                value: totalNetCharges ? `${totalNetCharges?.toFixed(1)} /-` : "0",
              },
              {
                label: "Discount %:",
                value: (
                  <TextField
                    type="text"
                    name="discountPercentage"
                    value={
                      discountPercentage !== undefined
                        ? +discountPercentage.toFixed(2)
                        : ""
                    }
                    onChange={handleDiscountChange}
                    className="search-field"
                    sx={{
                      width: "100px",
                      backgroundColor: "white",
                    }}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                    }}
                    size="small"
                  />
                ),
              },
              {
                label: "Discount Rs:",
                value: (
                  <TextField
                    type="text"
                    name="discountRupees"
                    value={discountRupees !== undefined ? discountRupees : ""}
                    onChange={handleDiscountChange}
                    className="search-field"
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                    }}
                    sx={{ width: "100px", backgroundColor: "white" }}
                  />
                ),
              },
              {
                label: "Net Charges:",
                value: (
                  <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    {netCharges ? `${netCharges.toFixed(1)} Rs` : "0 Rs"}
                  </Typography>
                ),
              },
              {
                label: "Advance:",
                value: `${advanceAmount ? advanceAmount.toFixed(1) : 0} Rs`,
              },
              {
                label: "Payable Amount:",
                value: `${payableamount ? payableamount.toFixed(1) : 0} Rs`,
              },
              {
                label: "Enter Amount:",
                value: (
                  <TextField
                    type="text"
                    value={toPayAmount !== undefined ? toPayAmount : ""}
                    onChange={handlePayableChange}
                    className="search-field"
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                    }}
                    sx={{ width: "100px", backgroundColor: "white" }}
                  />
                ),
              },
              {
                label: "Remaining:",
                value: `${remainingAmount ? remainingAmount.toFixed(1) : 0} Rs`,
              },
            ].map(({ label, value }) => (
              <Grid
                item
                key={label}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  ml: 1,
                }}
              >
                <Typography sx={{ fontSize: "12px", marginRight: "4px" }}>
                  {label}
                </Typography>

                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  {value}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
            gap={1}
            alignItems="center"
          >
            <Button variant="outlined" onClick={handleBackDischarge}>Back</Button>
            <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
              Status
            </Typography>
            <Select
              sx={{ height: "40px", fontSize: "14px" }}
              value={selectedStatus}  // Ensure that this matches the state
              onChange={(event: any) => setSelectedStatus(event.target.value)} // Update state on change
            >
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unpaid">Unpaid</MenuItem>
            </Select>

            <Button
              // disabled={!selectedStatus}
              disabled={selectedStatus !== "paid" || remainingAmount !== 0}
              onClick={handleDischarge}
              variant="contained"
              color="primary"
            >
              Discharge & Print
            </Button>
          </Box>
        </Dialog>
      }
      {
        dischargeRep ? <DischargeReport formName={"DischargeForm"} id={dischargeData?._id} handleClose={handleCloseDialog} referal={false} report={true} directPrint={true} /> : ''
      }
      {
        RefRep ? <DischargeReport formName={"Patient Refferal Form"} id={dischargeData?._id} handleClose={handleCloseDialog} referal={true} report={true} directPrint={true} /> : ''
      }
      {
        deathRep ? <DeathReport formName={"DeathForm"} id={dischargeData?._id} handleClose={handleCloseDialog} directPrint={true} /> : ''
      }
    </Box>
  );
};

export default DischargeModal;
