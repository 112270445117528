// import React, { useEffect, useState } from 'react';
// import {
//   Box,
//   Button,
//   Dialog,
//   IconButton,
//   Tab,
//   Tabs,
//   Typography,
//   Select,
//   MenuItem,
//   CircularProgress,
//   TextField,
//   Divider,
// } from '@mui/material';
// import DynamicTable from '../../../components/Dynamic-table';
// import PostTemplate from '../../../components/IPD/modals/other-tabs/surgical/PostTemplate';
// import PreTemplateModal from '../../../components/IPD/modals/other-tabs/surgical/PreTemplateModal';
// import axios from 'axios';
// import Anaesthesia from './Anaesthesia';
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import DeleteConfirmationModal from '../../../components/Modals/delete-modal';
// import { toast } from "react-toastify";

// interface Service {
//   _id: string;
//   templateName: string;
//   templateData: any;
// }

// const Template: React.FC = () => {
//   const [tabIndex, setTabIndex] = useState<number>(0);
//   const [openPostTemplateDialog, setOpenPostTemplateDialog] = useState<boolean>(false);
//   const [openPreDialog, setOpenPreDialog] = useState<boolean>(false);
//   const [openAnaesthesiaDialog, setOpenAnaesthesiaDialog] = useState<boolean>(false);
//   const [services, setServices] = useState<Service[]>([]);
//   const [error, setError] = useState<string | null>(null);
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
//   const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
//   const [loading, setLoading] = useState<boolean>(true); // Loader initially true
//   const [editService, setEditService] = useState<Service | null>(null);

//   const tabTypes = ['post', 'per', 'Anesthesia'] as const;
//   const columns = {
//     post: ["SN", "Template Name", "Medicines", "Actions"],
//     per: ["SN", "Template Name", "Operative Findings", "Procedure Note", "Incision", "Actions"],
//     Anesthesia: ["SN", "Name", "Details", "Actions"],
//   };

//   const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
//     setTabIndex(newValue);
//     setError(null);
//   };

//   const fetchTemplates = async (opeType?: string) => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=${opeType}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       }
//       );
//       if (response?.data?.isSuccess) {
//         setServices(response?.data?.data || []);
//       } else {
//         setError("Failed to load templates.");
//       }
//     } catch (error) {
//       console.error("Error fetching templates:", error);
//       setError("An error occurred while fetching templates.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     // Reset services when tab changes
//     setServices([]);
//     fetchTemplates(tabTypes[tabIndex]);
//   }, [tabIndex, isDeleteModalOpen, openPreDialog, openPostTemplateDialog, openAnaesthesiaDialog]);

//   const handleEditClick = (service: Service) => {
//     setEditService(service);
//   };

//   const handleEditConfirm = async () => {
//     if (!editService) return;

//     try {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         toast.error("No authorization token found. Please log in again.");
//         return;
//       }
//       const response = await axios.patch(
//         `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/updateTemplateById/${editService?._id}`,
//         {
//           ope_type: tabTypes[tabIndex],
//           templateName: editService.templateName,
//           templateData: editService.templateData,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response?.data?.isSuccess) {
//         toast.success(response?.data?.message);
//         setEditService(null);
//       } else {
//         toast.error(response.data.message);
//       }
//     } catch (error: any) {
//       console.error("Error updating item:", error);
//       toast.error(error.message);
//     }
//   };

//   const handleDeleteClick = (serviceId: string) => {
//     setSelectedServiceId(serviceId);
//     setIsDeleteModalOpen(true);
//   };

//   const handleDeleteConfirm = async () => {
//     try {
//       if (selectedServiceId) {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           toast.error("No authorization token found. Please log in again.");
//           return;
//         }

//         const response = await axios.delete(
//           `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/deleteTemplateById/${selectedServiceId}`, {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//         );

//         if (response?.data?.isSuccess) {
//           toast.success(response?.data?.message);
//           fetchTemplates();
//         } else {
//           toast.error(response.data?.message);
//         }
//       }
//     } catch (error: any) {
//       console.error("Error deleting item:", error);
//       toast.error(error.message);
//     } finally {
//       setIsDeleteModalOpen(false);
//     }
//   };

//   return (
//     <Box >
//       <Box sx={{ background: '#fff', p: 0.5, borderRadius: '12px' }}>
//         <Typography variant="h6" sx={{ fontWeight: '600', px: 1, py: .3 }}>
//           Template
//         </Typography>
//       </Box>
//       <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ background: '#fff' }} mt={1}>
//         <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="POST OPERATION" />
//           <Tab label="PER OPERATION" />
//           <Tab label="ANAESTHESIA" />
//         </Tabs>
//         <Button variant="contained" color="primary" size="small" sx={{ mr: 0.5 }} onClick={() => {
//           if (tabIndex === 0) setOpenPostTemplateDialog(true);
//           else if (tabIndex === 1) setOpenPreDialog(true);
//           else if (tabIndex === 2) setOpenAnaesthesiaDialog(true);
//         }}>
//           {tabIndex === 0 ? "+ Post Operation" : tabIndex === 1 ? "+ Per Operation" : "+ Anaesthesia Plan"}
//         </Button>
//       </Box>
//       <Divider />

//       <Box mt={0.5}>
//         <DynamicTable
//           loading={loading}
//           headers={columns[tabTypes[tabIndex] as "post" | "per" | "Anesthesia"]}
//           // Instead of mapping through services, handle an empty array
//           data={services?.length === 0 ? [] : services?.map((service, index) => {
//             const actions = (
//               <Box>
//                 <IconButton onClick={() => handleEditClick(service)}>
//                   <EditIcon
//                     sx={{
//                       color: "#FFB800",
//                       height: 20.09,
//                       width: 29.09,
//                       "&:hover": { background: "none", color: "rgb(133, 96, 1)" },
//                     }}
//                   />
//                 </IconButton>
//                 <IconButton onClick={() => handleDeleteClick(service?._id)}>
//                   <DeleteIcon
//                     sx={{
//                       color: "#FF5454",
//                       height: 20.09,
//                       width: 29.09,
//                       "&:hover": { background: "none", color: "rgb(191, 44, 44)" },
//                     }}
//                   />
//                 </IconButton>
//               </Box>
//             );

//             if (tabTypes[tabIndex] === 'post') {
//               return [
//                 index + 1,
//                 service?.templateName,
//                 <Select
//                   key={`medicines-${service._id}`}
//                   defaultValue={service?.templateData?.medicines?.[0]?.name || ""}
//                   displayEmpty
//                   className="search-field"
//                   // sx={{ fontSize: '0.875rem', width: '150px' }}
//                   sx={{
//                     borderRadius: "12px",
//                     border: "0px solid #D8D8D8",
//                     background: "#F5F5FB",
//                     fontSize: "12px",
//                     boxShadow: "none", // Remove any default shadow that might look like a border
//                     "& fieldset": {
//                       // Target the fieldset for better border control
//                       border: "none", // Remove default border if present
//                     },
//                   }}
//                 >
//                   {service?.templateData?.medicines?.map((med: any, medIndex: number) => (
//                     <MenuItem key={medIndex} value={med?.name} sx={{ fontSize: '0.875rem' }}>
//                       {med.name}
//                     </MenuItem>
//                   ))}
//                 </Select>,
//                 actions
//               ];
//             } else if (tabTypes[tabIndex] === 'per') {
//               return [
//                 index + 1,
//                 service?.templateName,
//                 service?.templateData?.operativeFindings,
//                 service?.templateData?.procedureNotes,
//                 service?.templateData?.inclusion,
//                 actions
//               ];
//             } else {
//               return [
//                 index + 1,
//                 service?.templateName,
//                 service?.templateData?.details,
//                 actions
//               ];
//             }
//           })}
//         />

//       </Box>

//       {/* Edit Template Dialog */}
//       {editService && (
//         <Dialog open={Boolean(editService)} onClose={() => setEditService(null)} fullWidth maxWidth="md">
//           <Box p={3}>
//             <Typography variant="h5" gutterBottom>Edit Template</Typography>
//             <TextField
//               label="Template Name"
//               value={editService.templateName}
//               onChange={(e) => setEditService({ ...editService, templateName: e.target.value })}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="Template Data"
//               value={JSON.stringify(editService.templateData, null, 2)}
//               onChange={(e) => setEditService({ ...editService, templateData: JSON.parse(e.target.value) })}
//               fullWidth
//               multiline
//               rows={4}
//               margin="normal"
//             />
//             <Box display="flex" justifyContent="flex-end" mt={2}>
//               <Button onClick={() => setEditService(null)} color="secondary">Cancel</Button>
//               <Button onClick={handleEditConfirm} color="primary" variant="contained" sx={{ ml: 2 }}>Save</Button>
//             </Box>
//           </Box>
//         </Dialog>
//       )}
//       <Dialog open={openPostTemplateDialog} onClose={() => setOpenPostTemplateDialog(false)} fullWidth maxWidth="md">
//         <Box p={2}>
//           <PostTemplate handleClose={() => setOpenPostTemplateDialog(false)} />
//         </Box>
//       </Dialog>

//       {/* PreTemplate Modal */}
//       <Dialog open={openPreDialog} onClose={() => setOpenPreDialog(false)} fullWidth maxWidth="md">
//         <PreTemplateModal opnTemp={openPreDialog} handleCloseDialog={() => setOpenPreDialog(false)} />
//       </Dialog>

//       {/* Anaesthesia Dialog */}
//       <Dialog open={openAnaesthesiaDialog} onClose={() => setOpenAnaesthesiaDialog(false)} fullWidth maxWidth="md">
//         <Anaesthesia onClose={() => setOpenAnaesthesiaDialog(false)} />
//       </Dialog>

//       {/* Delete Confirmation Modal */}
//       <DeleteConfirmationModal
//         open={isDeleteModalOpen}
//         handleClose={() => setIsDeleteModalOpen(false)}
//         handleConfirm={handleDeleteConfirm}
//       />
//     </Box>
//   );
// };

// export default Template;
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
} from '@mui/material';
import DynamicTable from '../../../components/Dynamic-table';
import PostTemplate from '../../../components/IPD/modals/other-tabs/surgical/PostTemplate';
import PreTemplateModal from '../../../components/IPD/modals/other-tabs/surgical/PreTemplateModal';
import axios from 'axios';
import Anaesthesia from './Anaesthesia';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit"; // Import Edit Icon
import DeleteConfirmationModal from '../../../components/Modals/delete-modal';
import { toast } from "react-toastify";

interface Service {
  _id: string;
  templateName: string;
  templateData: any;
}

const Template: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [openPostTemplateDialog, setOpenPostTemplateDialog] = useState<boolean>(false);
  const [openPreDialog, setOpenPreDialog] = useState<boolean>(false);
  const [openAnaesthesiaDialog, setOpenAnaesthesiaDialog] = useState<boolean>(false);
  const [services, setServices] = useState<Service[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loader initially true
  const [selectedServiceData, setSelectedServiceData] = useState<Service | null>(null); // New state to store selected data for editing

  const tabTypes = ['post', 'per', 'Anesthesia'] as const;
  const columns = {
    post: ["SN", "Template Name", "Medicines", "Actions"],
    per: ["SN", "Template Name", "Operative Findings", "Procedure Note", "Incision", "Actions"],
    Anesthesia: ["SN", "Name", "Details", "Actions"],
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setError(null);
  };

  const fetchTemplates = async (opeType?: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=${opeType}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
      );
      setServices(response?.data?.data || []);
      // if (response?.data?.isSuccess ) {
      //   
      // } else {
      //   setError("Failed to load templates.");
      // }
    } catch (error) {
      console.error("Error fetching templates:", error);
      setError("An error occurred while fetching templates.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // setServices([]);
    fetchTemplates(tabTypes[tabIndex]);
  }, [tabIndex]);

  const handleDeleteClick = (serviceId: string) => {
    setSelectedServiceId(serviceId);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (service: Service) => {
    setSelectedServiceData(service); // Set the selected row data for editing
    if (tabIndex === 0) setOpenPostTemplateDialog(true);
    else if (tabIndex === 1) setOpenPreDialog(true);
    else if (tabIndex === 2) setOpenAnaesthesiaDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedServiceId) {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("No authorization token found. Please log in again.");
          return;
        }

        const response = await axios.delete(
          `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/deleteTemplateById/${selectedServiceId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
        );

        if (response?.data?.isSuccess) {
          toast.success(response?.data?.message);
          fetchTemplates(tabIndex === 0 ? "post" : tabIndex === 1 ? "per" : "Anesthesia");
        } else {
          toast.error(response.data?.message);
        }
      }
    } catch (error: any) {
      console.error("Error deleting item:", error);
      toast.error(error.message);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };
  console.log("tabIndex", tabIndex)
  return (
    <Box>
      <Box sx={{ background: '#fff', p: 0.5, borderRadius: '12px' }}>
        <Typography variant="h6" sx={{ fontWeight: '600', px: 1, py: .3 }}>
          Template
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ background: '#fff' }} mt={1}>
        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="POST OPERATION" />
          <Tab label="PER OPERATION" />
          <Tab label="ANAESTHESIA" />
        </Tabs>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 0.5 }}
          onClick={() => {
            if (tabIndex === 0) {
              setOpenPostTemplateDialog(true); // Open post-template dialog without passing data
              setSelectedServiceData(null); // Ensure no data is passed for 'Add'
            } else if (tabIndex === 1) {
              setOpenPreDialog(true); // Open pre-template dialog without passing data
              setSelectedServiceData(null); // Ensure no data is passed for 'Add'
            } else if (tabIndex === 2) {
              setOpenAnaesthesiaDialog(true); // Open anaesthesia dialog without passing data
              setSelectedServiceData(null); // Ensure no data is passed for 'Add'
            }
          }}
        >
          {tabIndex === 0
            ? "+ Post Operation"
            : tabIndex === 1
              ? "+ Per Operation"
              : "+ Anaesthesia Plan"}
        </Button>
      </Box>
      <Divider />

      <Box mt={0.5}>
        <DynamicTable
          loading={loading}
          headers={columns[tabTypes[tabIndex] as "post" | "per" | "Anesthesia"]}
          data={services?.map((service, index) => {
            const actions = (
              <Box>
                <IconButton onClick={() => handleEditClick(service)}> {/* Edit button */}
                  <EditIcon
                    sx={{
                      color: "#4caf50", // Green for Edit
                      height: 20.09,
                      width: 29.09,
                      "&:hover": { background: "none", color: "rgb(39, 174, 96)" },
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(service?._id)}>
                  <DeleteIcon
                    sx={{
                      color: "#FF5454",
                      height: 20.09,
                      width: 29.09,
                      "&:hover": { background: "none", color: "rgb(191, 44, 44)" },
                    }}
                  />
                </IconButton>
              </Box>
            );

            if (tabTypes[tabIndex] === 'post') {
              return [
                index + 1,
                service?.templateName,
                <Select
                  key={`medicines-${service._id}`}
                  defaultValue={service?.templateData?.medicines?.[0]?.name || ""}
                  displayEmpty
                  className="search-field"
                  sx={{
                    borderRadius: "12px",
                    border: "0px solid #D8D8D8",
                    background: "#F5F5FB",
                    fontSize: "12px",
                    boxShadow: "none",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                >
                  {service?.templateData?.medicines?.map((med: any, medIndex: number) => (
                    <MenuItem key={medIndex} value={med?.name} sx={{ fontSize: '0.875rem' }}>
                      {med.name}
                    </MenuItem>
                  ))}
                </Select>,
                actions
              ];
            } else if (tabTypes[tabIndex] === 'per') {
              return [
                index + 1,
                service?.templateName,
                service?.templateData?.operativeFindings,
                service?.templateData?.procedureNotes,
                service?.templateData?.inclusion,
                actions
              ];
            } else {
              return [
                index + 1,
                service?.templateName,
                service?.templateData?.details,
                actions
              ];
            }
          })}
        />
      </Box>

      {/* Post Template Dialog */}
      <Dialog open={openPostTemplateDialog} onClose={() => setOpenPostTemplateDialog(false)} fullWidth maxWidth="md">
        <Box p={2}>
          <PostTemplate handleClose={() => setOpenPostTemplateDialog(false)} templateData={selectedServiceData} fetchTemplates={fetchTemplates} /> {/* Pass data */}
        </Box>
      </Dialog>

      {/* PreTemplate Modal */}
      <Dialog open={openPreDialog} onClose={() => setOpenPreDialog(false)} fullWidth maxWidth="md">
        <PreTemplateModal opnTemp={openPreDialog} handleCloseDialog={() => setOpenPreDialog(false)} templateData={selectedServiceData} fetchTemplates={fetchTemplates} /> {/* Pass data */}
      </Dialog>

      {/* Anaesthesia Dialog */}
      <Dialog open={openAnaesthesiaDialog} onClose={() => setOpenAnaesthesiaDialog(false)} fullWidth maxWidth="md">
        <Anaesthesia onClose={() => setOpenAnaesthesiaDialog(false)} templateData={selectedServiceData} fetchTemplates={fetchTemplates} /> {/* Pass data */}
      </Dialog>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

export default Template;
