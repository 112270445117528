import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Divider,
    FormControlLabel,
    Checkbox,
    Dialog,
    Button,
} from "@mui/material";
import DynamicTable from "../../../../../components/Dynamic-table";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import logo from "../../../../../assets/SVG/OPDIcon/logo.png";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

interface Form {
    dischargeData?: any;
    examinationData?: string;
    detailedExaminationNotes?: string;
    referal?: boolean;
    allData?: any
    formName?: string
    id?: string
    report?: boolean;
    directPrint?: boolean;
    handleClose?: () => void;
}

const DischargeReport: React.FC<Form> = ({ dischargeData, detailedExaminationNotes, referal, allData, id, formName, directPrint, handleClose }) => {
    const projectProfile = useSelector(
        (state: any) => state?.projectProfileSlice?.projectProfile
    );
    const DischargeReportRef = useRef()
    const [dischargedData, setDischargedData] = useState<any>(dischargeData || {})
    const [logoLoadingError, setLogoLoadingError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleLogoError = () => {
        setLogoLoadingError(true);
    };
    const defaultData = {
        admissionType: dischargedData?.admissionType || allData?.admissionType || '',
        patientName: dischargedData?.patientName || "",
        guardianName: dischargedData?.guardiansName || "",
        age: dischargedData?.age || "",
        sex: dischargedData?.sex || "",
        weight: dischargedData?.weight || "",
        mrn: dischargedData?.mrn || "",
        admissionDate: dischargedData?.admissionDateAndTime
            ? dayjs(dischargedData?.admissionDateAndTime)?.format('DD.MM.YYYY')
            : "",
        dischargeDateTime: dischargedData?.dateOfDischarge
            ? dayjs(dischargedData?.dateOfDischarge)?.format("DD.MM.YYYY hh:mma")
            : "",
        presentingComplaint:
            typeof dischargedData?.presentingComplaints === "string"
                ? dischargedData?.presentingComplaints
                : Array.isArray(dischargedData?.presentingComplaints)
                    ? dischargedData?.presentingComplaints
                        ?.map((item: any) =>
                            typeof item === "object" && item !== null
                                ? item.complaint
                                : item
                        )
                        ?.join(", ")
                    : dischargedData?.presentingComplaints && typeof dischargedData?.presentingComplaints === "object"
                        ? Object.keys(dischargedData?.presentingComplaints)
                            ?.map(key => `${key}: ${dischargedData?.presentingComplaints[key]}`)
                            ?.join(", ")
                        : "",
        briefHistory: dischargedData?.briefHistory || "",
        examinationData:
            Array.isArray(dischargedData?.examinations)
                ? dischargedData?.examinations?.join(", ")
                : typeof dischargedData?.examinations === "string"
                    ? dischargedData?.examinations
                    : dischargedData?.examinations
                        ? Object.entries(dischargedData?.examinations)
                            ?.map(([key, value]) => `${key}: ${value}`)
                            ?.join(", ")
                        : "",
        diagnosis: dischargedData?.diagnosis || "",
        finalDiagnosis: dischargedData?.finalDiagnosis || "",
        SergonName: dischargedData?.surgeonName || "",
        procedureDone: dischargedData?.procedureDone || "",
        operativeFindings: dischargedData?.operativeFindings || ".",
        anaesthesia: dischargedData?.anaesthesia || "",
        dischargeAdvisedBy: dischargedData?.dischargeTo || "",
        referedBy: dischargedData?.referedBy || "",
        referTo: dischargedData?.referTo || "",
        referalNote: dischargedData?.referalNote || "",
        resonOfRefer: dischargedData?.resonOfRefer || "",
        lama: dischargedData?.lama,
        dor: dischargedData?.dor,
        conditionOnDischarge: dischargedData?.conditionOnDischarge || "",
        diagnosticInvestigations:
            Array.isArray(dischargedData?.diagnosticInvestigations)
                ? dischargedData.diagnosticInvestigations?.join(", ")
                : typeof dischargedData?.diagnosticInvestigations === "string"
                    ? dischargedData.diagnosticInvestigations
                    : dischargedData?.diagnosticInvestigations
                        ? Object.entries(dischargedData.diagnosticInvestigations)
                            ?.map(([key, value]) => `${key}: ${value}`)
                            ?.join(", ")
                        : "",
        medications: dischargedData?.medicines || [],
        dateAndTime: dischargedData?.dateAndTime,
        department: dischargedData?.department,
        foodInstructions: dischargedData?.foodInstructions,
    };

    const fetchFormData = async (formName: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${id}?formName=${formName}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const fetchedData = response?.data?.data?.formData
            setDischargedData(fetchedData);
        }
        catch (error) {
            toast.error('Failed to fetch form data.');
        }
    };

    useEffect(() => {
        if (formName) {
            fetchFormData(formName);
        }
    }, [id, formName]);

    const handlePrint = useReactToPrint({
        content: () => DischargeReportRef?.current || null,
        documentTitle: "Patient Discharge Report",
        onAfterPrint: () => handleCancelPrint(),
        pageStyle: "A4",
    });

    const handlePrintData = () => {
        setTimeout(() => handlePrint(), 1000);
    };

    useEffect(() => {
        if (directPrint) {
            setDialogOpen(true);
        }
    }, [directPrint]);

    const handleYesPrint = () => {
        handlePrintData();
        setDialogOpen(false);
    };

    const handleCancelPrint = () => {
        if (handleClose) {
            handleClose();
        }
    };
    const [admissionType, setAdmissionType] = useState<string>(defaultData?.admissionType || '');
    console.log("Admission Type:", defaultData?.admissionType);
    console.log("Discharge Data Prop:", dischargeData);
    console.log("All Data Prop:", allData);


    return (
        // component={Paper} sx={{ border: '1px solid #ddd', }}
        <Box p={0.5} ref={DischargeReportRef} sx={{ padding: '20px' }}>
            <Box
                sx={{
                    width: "100%",
                    backgroundSize: "cover",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box display="flex" alignItems="center">
                    {!logoLoadingError ? (
                        <img
                            src={projectProfile?.hospitalLogoUrl}
                            alt="Hospital Logo"
                            style={{ width: "70px", height: '70px' }}
                            onError={handleLogoError}
                        />
                    ) : (
                        <img src={logo} alt="Default Logo" />
                    )}
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "600",
                            color: "#000",
                            fontSize: "16px",
                            textTransform: "capitalize",
                            ml: 2
                        }}
                    >
                        {projectProfile?.hospitalName}
                    </Typography>
                </Box>
            </Box>
            <Box mb={2}>
                {allData?.patientData && (
                    <Box p={2} sx={{ borderBottom: '2px solid #000' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h6" style={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>Patient Name : <span style={{ color: '#000', fontSize: '12px', fontWeight: 'normal', textTransform: 'capitalize' }}>{allData?.patientData?.patientName}</span></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display='flex' justifyContent='flex-end'>
                                    <Typography variant="h6" style={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>MRN # : <span style={{ color: '#000', fontSize: '12px', fontWeight: 'normal' }}>{allData?.patientData?.mrn}</span></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
            <Typography variant="h6" align="center" gutterBottom>
                {referal ? (
                    <>
                        Referral FORM{" "}
                        <Typography
                            component="span"
                            sx={{ fontSize: "10px", fontWeight: "normal" }}
                        >
                            (This is an e-form so no signature is needed)
                        </Typography>
                    </>
                ) : (
                    <>
                        DISCHARGE FORM{" "}
                        <Typography
                            component="span"
                            sx={{ fontSize: "10px", fontWeight: "normal" }}
                        >
                            (This is an e-form so no signature is needed)
                        </Typography>
                    </>
                )}
            </Typography>

            <Divider sx={{ mb: 1 }} />

            <Grid container spacing={1}>
                {/* Patient Details */}
                <Grid item xs={12}>
                    <TableContainer >
                        <Table size="small" >
                            <TableBody>
                                <TableRow sx={{ '& td': { borderBottom: '1px solid #ddd' } }}>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Patient Name</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.patientName}</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>MRN</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.mrn}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '& td': { borderBottom: '1px solid #ddd' } }}>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>S/o, D/o, W/o</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.guardianName}</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Date of Admission</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.admissionDate}</TableCell>
                                </TableRow >
                                <TableRow sx={{ '& td': { borderBottom: '1px solid #ddd' } }}>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Discharge Date & Time</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>
                                        {defaultData?.dischargeDateTime || "N/A"}
                                    </TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Weight</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.weight}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '& td': { borderBottom: '1px solid #ddd' } }}>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Age/Sex</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>
                                        {defaultData?.age || "N/A"} / {defaultData?.sex || "N/A"}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {/* Presenting Complaint */}
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                        Presenting Complaint:
                    </Typography>
                    <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.presentingComplaint}</Typography>
                </Grid>

                <Divider sx={{ width: '100%', mt: 0.4, mb: 0.4 }} />

                {/* Brief History & Examination */}
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                        Brief History & Examination:
                    </Typography>
                    <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.briefHistory}</Typography>
                    <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.examinationData}</Typography>
                    {detailedExaminationNotes && (
                        <Box mt={1}>
                            <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                                Detailed Examination Notes:
                            </Typography>
                            <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{detailedExaminationNotes}</Typography>
                        </Box>
                    )}
                </Grid>

                <Divider sx={{ width: '100%', mt: 0.4, mb: 0.4 }} />

                {/* Diagnostic Investigations */}
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                        Diagnostic Investigations:
                    </Typography>
                    <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.diagnosticInvestigations}</Typography>
                </Grid>

                <Divider sx={{ width: '100%', mt: 0.4, mb: 0.4 }} />

                {/* Diagnosis and Procedure */}
                <Grid item xs={12}>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {!referal && (
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Diagnosis</TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>
                                            {/* Show finalDiagnosis if available, otherwise show diagnosis */}
                                            {defaultData?.finalDiagnosis ? defaultData?.finalDiagnosis : defaultData?.diagnosis || "N/A"}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Doctor Name</TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>
                                            {defaultData?.SergonName || "N/A"}
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow>
                                    {defaultData?.admissionType !== 'Medical' && (
                                        <>
                                            <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: '600' }}>Procedure Done</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontSize: '10px', fontWeight: 'normal' }}>
                                                {defaultData?.procedureDone}
                                            </TableCell>
                                        </>
                                    )}
                                    {referal ? (
                                        <>
                                            <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', borderTop: '1px solid #ddd', fontWeight: '600' }}>Condition at time of Refer</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', borderTop: '1px solid #ddd', fontWeight: 'normal' }}>{defaultData?.conditionOnDischarge}</TableCell>
                                        </>
                                    ) : (
                                        <>
                                            {defaultData?.admissionType !== 'Medical' && (
                                                <>
                                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', borderLeft: '1px solid #ddd', borderTop: '1px solid #ddd', fontWeight: '600' }}>Anaesthesia</TableCell>
                                                    <TableCell sx={{ borderRight: '1px solid #ddd', fontSize: '10px', borderTop: '1px solid #ddd', fontWeight: 'normal' }}>{defaultData?.anaesthesia}</TableCell>
                                                </>
                                            )}
                                        </>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Divider sx={{ width: '100%', mt: 0.5 }} />
                <Grid item xs={12}>
                    {
                        referal ? <>
                            <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                                Referal Notes:
                            </Typography>
                            <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.referalNote}</Typography>
                        </> : <>
                            {defaultData?.admissionType !== 'Medical' && (
                                <>
                                    <TableCell sx={{ fontSize: '10px', fontWeight: '600' }}>Operative Findings</TableCell>
                                    <TableCell sx={{ fontSize: '10px', fontWeight: 'normal' }}>
                                        {defaultData?.operativeFindings}
                                    </TableCell>
                                </>
                            )}
                        </>
                    }
                </Grid>

                <Divider sx={{ width: '100%', mt: 0.4 }} />
                {!referal && <Grid item xs={12} md={12} lg={12}>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            borderRight: '1px solid #ddd',
                                            borderLeft: '1px solid #ddd',
                                            borderTop: '1px solid #ddd',
                                            fontSize: '10px',
                                        }}
                                    >
                                        <strong>Discharge Advised By:</strong> {defaultData?.dischargeAdvisedBy}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderRight: '1px solid #ddd',
                                            borderLeft: '1px solid #ddd',
                                            borderTop: '1px solid #ddd',
                                            fontSize: '10px',
                                        }}
                                    >
                                        <strong>Condition on Discharge:</strong> {defaultData?.conditionOnDischarge}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>}

                <Grid item xs={12}>
                    <DynamicTable
                        headers={["SN", "Medicine", "Strength", "Dosage", "Frequency", "Duration"]}
                        data={defaultData?.medications?.map((item: any, idx: any) => [
                            idx + 1,
                            item.name,
                            item.strength,
                            item.dosage,
                            item?.frequency.abr_notation,
                            item.duration,
                        ])}
                    />
                </Grid>
                {referal && <Box px={1}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box>
                                <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>Refer To</Typography>
                                <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.referTo}</Typography>
                            </Box>
                        </Grid>
                        <Divider sx={{ width: '100%', mt: 0.2, mb: 0.3 }} />
                        <Grid item md={12} sm={12} xs={12}>
                            <Box>
                                <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>Reason of Refer</Typography>
                                <Typography sx={{ fontSize: '10px', fontWeight: 'normal' }}>{defaultData?.resonOfRefer}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>}
                {!referal && (defaultData?.department || defaultData?.dateAndTime || defaultData?.foodInstructions) && (
                    <Box mt={1} p={1} ml={2}>
                        <Typography sx={{ mb: 2, fontWeight: '600' }}>
                            Please visit the specified department on the given date for your examination.
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body2" sx={{ fontWeight: '600', display: 'inline', fontSize: '10px' }}>
                                    Department:
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'inline', fontSize: '10px' }}>
                                    {defaultData?.department || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" sx={{ fontWeight: '600', display: 'inline', fontSize: '10px' }}>
                                    Next Examination Date:
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'inline', fontSize: '10px' }}>
                                    {defaultData?.dateAndTime || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" sx={{ fontWeight: '600', display: 'inline', fontSize: '10px' }}>
                                    Food Instructions:
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'inline', fontSize: '10px' }}>
                                    {defaultData?.foodInstructions || "N/A"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}

            </Grid>
            <Dialog open={dialogOpen} maxWidth='xs' fullWidth>
                <Box p={3}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', textAlign: 'center' }}>Do you want to print the report?</Typography>
                    <Box mt={2} display="flex" justifyContent="center" gap={1}>

                        <Button variant="outlined" onClick={handleCancelPrint}>
                            No
                        </Button>
                        <Button variant="contained" onClick={handleYesPrint}>
                            Yes
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};

export default DischargeReport;
