import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Typography,
  Grid,
  Divider,
  Dialog,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DischargeModal from "./DischargeModal";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Helper function to format dates and times
const formatDate = (date: dayjs.Dayjs | null) =>
  date ? date.format("DD.MM.YYYY") : "";
const formatTime = (time: dayjs.Dayjs | null) =>
  time ? time.format("hh:mm A") : "";

interface FormData {
  mrn: number;
  date: dayjs.Dayjs;
  patientName: string;
  dateOfAdmission: dayjs.Dayjs;
  guardiansName: string;
  currentDate: dayjs.Dayjs;
  gender: string;
  diagnosis: string;
  causeOfDeath: string;
  deadBodyReceivedBy: string;
  doctorName: string;
  signature: string;
  dateTimeDoc: dayjs.Dayjs;
  dateTimeDeath: dayjs.Dayjs;
  primaryCauseOfDeath: string;     // New field
  secondaryCauseOfDeath: string;   // New field
  relationWithDiseased: string;    // New field
  cnicOfReceiver: string;          // New field
  phoneNumberOfReceiver: string;   // New field
  department: string;   // New field
  age: string;            // New field
  weight: string;         // New field

}

const DeathForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    mrn: 0,
    date: dayjs(),
    patientName: "",
    dateOfAdmission: dayjs(),
    guardiansName: "",
    // motherName: "",
    // spouseName: "",
    currentDate: dayjs(),
    gender: "",
    dateTimeDeath: dayjs(),
    dateTimeDoc: dayjs(),
    diagnosis: "",
    causeOfDeath: "",
    deadBodyReceivedBy: "",
    doctorName: "",
    signature: "",
    primaryCauseOfDeath: "",     // Initialize new field
    secondaryCauseOfDeath: "",   // Initialize new field
    relationWithDiseased: "",       // Initialize new field
    cnicOfReceiver: "",             // Initialize new field
    phoneNumberOfReceiver: "",      // Initialize new field
    department: "",    // Initialize new field
    age: "",
    weight: "",
  });
  const [open, setOpen] = useState<boolean>(false);
  const [dataById, setDataById] = useState<any>();
  const [deathStatus, setDeathStatus] = useState<boolean>(false);
  const location = useLocation();
  const { data, id } = location?.state;
  // const id = (location.state as { id?: any })?.id;
  const isFromOPD = (location.state as { isFromOPD?: any })?.isFromOPD;
  const navigate = useNavigate();
  const handleCloseDischarge = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!id) {
      setFormData((prevData: any) => ({
        ...prevData,
        mrn: data?.mrn,
        date: dayjs(data?.date),
        patientName: data?.patientName,
        dateOfAdmission: dayjs(data?.dateOfAdmission),
        guardiansName: data?.guardiansName,
        currentDate: dayjs(),
        gender: data?.patientData?.gender,
        dateOfDeath: dayjs(data?.dateOfDeath),
        timeOfDeath: dayjs(data?.timeOfDeath),
        diagnosis: data?.diagnosis,
        causeOfDeath: data?.causeOfDeath,
        deadBodyReceivedBy: data?.deadBodyReceivedBy,
        doctorName: data?.doctorName,
        signature: data?.signature,
        dateOfDoc: dayjs(data?.dateOfDoc),
        timeOfDoc: dayjs(data?.timeOfDoc),


      }));
    }
  }, [data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const date = dayjs(value, "YYYY-MM-DD", true);
      if (date.isValid()) {
        setFormData({
          ...formData,
          [name]: date,
        });
      }
    };

  const handleTimeChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const time = dayjs(value, "hh:mm A", true);
      if (time.isValid()) {
        setFormData({
          ...formData,
          [name]: time,
        });
      }
    };

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      gender: event.target.value,
    });
  };

  const handleArrowClick = async () => {
    try {
      const url = isFromOPD
        ? `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateIpdAdmissionStatusById/${data?._id}`
        : `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateEmergencyStatusById/${data?._id}`;
      // Replace with your API endpoint
      const requestBody = isFromOPD
        ? { patientStatus: "Death" }
        : { emergencyStatus: "Death" };

      await axios.patch(url, requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  useEffect(() => {
    getDataBySearchId();
  }, []);

  const getDataBySearchId = async () => {
    try {
      const formName = "DeathForm";
      const response = await axios.get(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${data?._id}?formName=${formName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDataById(response?.data?.data);
      const fetchedData = response.data?.data?.formData;
      if (fetchedData) {
        setFormData((prevData: any) => ({
          ...prevData,
          mrn: fetchedData?.mrn,
          patientName: fetchedData?.patientName,
          age: fetchedData?.age,                // Added Age
          weight: fetchedData?.weight,
          dateOfAdmission: dayjs(fetchedData?.dateOfAdmission),
          date: dayjs(fetchedData?.date),
          guardiansName: fetchedData?.guardiansName,
          currentDate: dayjs(),
          gender: fetchedData?.gender,
          dateTimeDeath: dayjs(fetchedData?.dateTimeDeath),
          dateTimeDoc: dayjs(fetchedData?.dateTimeDoc),
          diagnosis: fetchedData?.diagnosis,
          causeOfDeath: fetchedData?.causeOfDeath,
          deadBodyReceivedBy: fetchedData?.deadBodyReceivedBy,
          doctorName: fetchedData?.doctorName,
          signature: fetchedData?.signature,
          cnicOfReceiver: fetchedData?.cnicOfReceiver,
          primaryCauseOfDeath: fetchedData?.primaryCauseOfDeath,
          relationWithDiseased: fetchedData?.relationWithDiseased,
          phoneNumberOfReceiver: fetchedData?.phoneNumberOfReceiver,
          secondaryCauseOfDeath: fetchedData?.secondaryCauseOfDeath,
          department: fetchedData?.department,

        }));
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {
    if (
      !formData.mrn ||
      !formData.patientName ||
      !formData.dateTimeDoc ||
      !formData.dateTimeDeath
    ) {
      toast.error("Please fill all required fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/CreateFormData`,
        {
          formName: "DeathForm",
          formData: formData,
          searchId: data?._id,
          patientId: data?.patientData?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDeathStatus(true);
      setOpen(true);
      handleArrowClick();
      toast.success(response.data.success);
    } catch (error: any) {
      toast.error(error.message);
    }
  }


  const handleDateTimeChange = (formattedDate: any) => {
    if (formattedDate) {
      setFormData({
        ...formData,
        dateTimeDeath: formattedDate,
      });
    }
  };

  const handleDateDocTimeChange = (formattedDate: any) => {
    if (formattedDate) {
      setFormData({
        ...formData,
        dateTimeDoc: formattedDate,
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          background: "#fff",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            color: "#000",
            whiteSpace: "nowrap",

          }}
        >
          Death Form
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            justifyContent: "flex-end",
            textAlign: "right",
            fontSize: "12px",
            ml: 3,
          }}
        >
          <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
            <strong>Name:</strong> {formData.patientName}
          </Typography>

          <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
            <strong>Guardian:</strong> {formData.guardiansName}
          </Typography>

          <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
            <strong>MRN:</strong> {formData.mrn}
          </Typography>

          <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
            <strong>Sex:</strong> {formData.gender}
          </Typography>
          {/* <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
  <strong>Age:</strong> {formData.age}
</Typography>

<Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
  <strong>Weight:</strong> {formData.weight}
</Typography> */}



          {/* <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
            <strong>Date:</strong> {formatDate(formData.date)}
          </Typography>

          <Typography sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
            <strong>Date of Admission:</strong> {formatDate(formData.dateOfAdmission)}
          </Typography> */}
        </Box>
      </Box>

      <Box p={2} mt={1} sx={{ background: "#fff", borderRadius: "8px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
              Department
            </Typography>
            <TextField
              placeholder="Department"
              name="department"
              value={formData.department}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Date & Time Of Death
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={formData?.dateTimeDeath}
                onChange={(newValue: any) => {
                  handleDateTimeChange(newValue);
                }}
                format="DD.MM.YYYY hh:mm A"
                minDate={dayjs().startOf("day")} // Prevent selecting past dates
                sx={{
                  "& .MuiInputBase-root": {
                    height: "38px",
                    fontSize: "12px",
                    overflow: "hidden",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#F5F5FB",
                  },
                  "& .MuiCalendarPicker-root": {
                    overflow: "hidden",
                  },
                  "& .css-4jnixx-MuiStack-root": {
                    paddingTop: "0px",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Diagnosis
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                {Array.isArray(formData?.diagnosis) && formData?.diagnosis?.length > 0 ? (
                  <>
                    {formData?.diagnosis.slice(0, 2)}
                    {formData?.diagnosis.length > 2 && (
                      <Tooltip title={formData?.diagnosis.slice(2)} arrow placement="top">
                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                          +{formData?.diagnosis.length - 2} more
                        </span>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <span>No diagnoses available</span> // Handle case where no diagnoses exist
                )}
              </Typography>

            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
              Primary Cause of Death
            </Typography>
            <TextField
              placeholder="Primary Cause of Death"
              name="primaryCauseOfDeath"
              value={formData.primaryCauseOfDeath}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
              Secondary Cause of Death
            </Typography>
            <TextField
              placeholder="Secondary Cause of Death"
              name="secondaryCauseOfDeath"
              value={formData.secondaryCauseOfDeath}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Dead Body Received By
            </Typography>
            <TextField
              placeholder="Dead Body Received By"
              name="deadBodyReceivedBy"
              value={formData?.deadBodyReceivedBy}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
              Relation with Diseased
            </Typography>
            <TextField
              placeholder="Relation with Diseased"
              name="relationWithDiseased"
              value={formData.relationWithDiseased}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
              CNIC of Receiver
            </Typography>
            <TextField
              placeholder="CNIC of Receiver"
              name="cnicOfReceiver"
              value={formData.cnicOfReceiver}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
              Phone Number of Receiver
            </Typography>
            <TextField
              placeholder="Phone Number of Receiver"
              name="phoneNumberOfReceiver"
              value={formData.phoneNumberOfReceiver}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2 }} />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Doctor Name
            </Typography>
            <TextField
              placeholder="Doctor Name"
              name="doctorName"
              value={formData?.doctorName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Date & Time
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker

                value={formData?.dateTimeDoc}
                disabled
                onChange={(newValue) => {
                  const formattedDate = newValue
                    ? newValue.toISOString()
                    : null;
                  handleDateDocTimeChange(formattedDate);
                }}
                format="DD.MM.YYYY hh:mm A"
                minDate={dayjs().startOf("day")} // Prevent selecting past dates
                sx={{
                  "& .MuiInputBase-root": {
                    height: "38px",
                    fontSize: "12px",
                    overflow: "hidden",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#F5F5FB",
                  },
                  "& .MuiCalendarPicker-root": {
                    overflow: "hidden",
                  },
                  "& .css-4jnixx-MuiStack-root": {
                    paddingTop: "0px",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Signature
            </Typography>
            <TextField
              placeholder="Signature"
              name="signature"
              value={formData?.signature}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid> */}
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
          <Button onClick={() => navigate("/ipd-dashboard")}>Back</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleCloseDischarge}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          PaperProps={{
            sx: {
              borderRadius: "12px",
            },
          }}
        >
          <DischargeModal
            dischargeData={data}
            handleClose={handleCloseDischarge}
            deathStatus={deathStatus}
            isRefDeathComp={true}
            death={true}
          // isEmergency={isEmergency}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default DeathForm;
