// labDepartmentSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface LabDepartment {
  id: number;
  name: string;
  // Add more properties as needed
}

interface InitialState {
  labDepartments: LabDepartment[];
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: string | null;
}

// Async thunk action to fetch lab departments from the API
export const fetchLabDepartments = createAsyncThunk(
  'labDepartments/fetchLabDepartments',
  async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }
      
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${process.env.REACT_APP_PATHOLOGY_URL}/lab-department/getAllLabDepartment`, {
        params: {
          count:100,
          pageNo:1,
        },
        headers,
      });
      return response.data?.data;
    } catch (error) {
      throw error; // Propagate the error up
    }
  }
);

// Initial state
const initialState: InitialState = {
  labDepartments: [],
  loading: 'idle',
  error: null,
};

// Create lab department slice
const labDepartmentSlice = createSlice({
  name: 'labDepartmentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabDepartments.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchLabDepartments.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.labDepartments = action.payload;
        state.error = null;
      })
      .addCase(fetchLabDepartments.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message || 'Failed to fetch lab departments';
      });
  },
});

export default labDepartmentSlice.reducer;
