import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import debounce from "lodash.debounce";
import DynamicTable from "../../../Dynamic-table";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
interface CallToDoctorProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
    handleSave: (shouldClose: boolean, searchId?: any) => void;
}

interface FormData {
    doctor_Name?: string;
    call_Sent_By?: string;
    call_Date_Time?: any;
    response_Date_Time?: any;
    reason_For_Call?: string;
    response?: string;
    callToDoc: any;
    deleteID: string;
    tooltipName: string
}

const CallToDoctor: React.FC<CallToDoctorProps> = ({ onChange, data, otherTabsData, profile, handleSave }) => {
    const [deleteID, setDeleteID] = useState(profile?.data?._id);
    const [tooltipName, setTooltipName] = useState(profile?.data?.fullName);
    const [formData, setFormData] = useState<any>({
        doctor_Name: "",
        call_Sent_By: "",
        call_Date_Time: dayjs(),
        response_Date_Time: "",
        reason_For_Call: "",
        response: "",
        deleteID: deleteID,
        tooltipName: tooltipName
    });
    const [doctors, setDoctors] = useState<any[]>([]);
    const [searchDoc, setSearchDoc] = useState("");
    const [savedData, setSavedData] = useState<any[]>([]);
    const [editIndex, setEditIndex] = useState<number | null>(null); // Track index of row being edited
    const [isMultiline, setIsMultiline] = useState(false);
    const [isMultilinetwo, setIsMultilinetwo] = useState(false);
    const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const [searchId, setSearchId] = useState<any>();
    const [clickSave, setClickSave] = useState<any>(false);

    const handleFocustwo = () => {
        setIsMultilinetwo(true);
    };
    const handleBlurtwo = () => {
        setIsMultilinetwo(false);
    };
    const handleFocus = () => {
        setIsMultiline(true);

    };
    const handleBlur = () => {
        setIsMultiline(false);
    };
    const fetchDoctors = async (search: any) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores?count=50000&search=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const fetchedDoc = response.data?.data || [];
            const uniqueDoc = Array.from(
                new Map(fetchedDoc?.map((item: any) => [item?.fullName, item]))?.values()
            );
            setDoctors(uniqueDoc);
        } catch (error) {
            console.error(error);
        }
    };
    const debouncedFetchDoctor = useCallback(debounce(fetchDoctors, 500), []);
    useEffect(() => {
        debouncedFetchDoctor(searchDoc);
    }, [searchDoc, debouncedFetchDoctor]);
    const handleInputChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { name, value } = event.target;
        if (name) {
            setFormData((prev: any) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleDateTimeChange = (name: string) => (newValue: dayjs.Dayjs | null) => {
        setFormData((prev: any) => ({
            ...prev,
            [name]: newValue,
        }));
    };
    const handleDoctorChange = (value: any) => {
        setFormData((prev: any) => ({
            ...prev,
            doctor_Name: value,
            call_Sent_By: null, 
        }));
    };
    const handleCallSentByChange = (event: any, value: any) => {
        setFormData((prev: any) => ({
            ...prev,
            call_Sent_By: value,
        }));
    };
    useEffect(() => {
        if (data) {
            setSavedData(data?.call_To_Doc);
        }
    }, [data]);
    // const handleSaveCTD = () => {
    //     const isEmpty = !(
    //         formData.doctor_Name ||
    //         formData.call_Sent_By ||
    //         formData.call_Date_Time ||
    //         formData.response_Date_Time ||
    //         formData.reason_For_Call ||
    //         formData.response
    //     );
    //     if (isEmpty) {
    //         return;
    //     }
    //     if (editIndex !== null) {
    //         const updatedData = [...savedData];
    //         updatedData[editIndex] = formData;
    //         setSavedData(updatedData);
    //         setEditIndex(null);
    //     } else {
    //         setSavedData((prevData = []) => {
    //             const updatedData = [...prevData, formData];
    //             return updatedData;
    //         });
    //     }

    //     setFormData({
    //         doctor_Name: "",
    //         call_Sent_By: "",
    //         call_Date_Time: dayjs(),
    //         response_Date_Time: "",
    //         reason_For_Call: "",
    //         response: "",
    //         deleteID: deleteID,
    //         tooltipName: tooltipName

    //     });
    // };
    // const handleDelete = (index: number) => {
    //     const updatedData = savedData?.filter((_, i) => i !== index);
    //     setSavedData(updatedData);
    // };
    // useEffect(() => {
    //     onChange({ call_To_Doc: savedData });
    // }, [savedData]);

    const handleSaveCTD = async () => {
        const isEmpty = !(
            formData.doctor_Name ||
            formData.call_Sent_By ||
            formData.call_Date_Time ||
            formData.response_Date_Time ||
            formData.reason_For_Call ||
            formData.response
        );
        if (isEmpty) {
            return;
        }
        const updatedData = editIndex !== null
        ? (Array.isArray(savedData) ? savedData?.map((item, index) => (index === editIndex ? formData : item)) : [])
        : (Array.isArray(savedData) ? [...savedData, formData] : [formData]);

        setSavedData(updatedData); 

        onChange({ call_To_Doc: updatedData });

        setClickSave(true); 
        setFormData({
            doctor_Name: "",
            call_Sent_By: "",
            call_Date_Time: dayjs(),
            response_Date_Time: "",
            reason_For_Call: "",
            response: "",
            deleteID: deleteID,
            tooltipName: tooltipName
        });

    };


    const handleDelete = (index: number) => {
        const updatedData = savedData?.filter((_, i) => i !== index);
        setSavedData(updatedData);

        // After deleting, call onChange immediately with the updated data
        onChange({ call_To_Doc: updatedData });

        setClickSave(true)
    };
    useEffect(() => {
        if (otherTabsData) {
            setSearchId(otherTabsData?._id)
        }
    }, [otherTabsData])

    useEffect(() => {
        if (clickSave) {
            handleSave(false, searchId);
        }
    }, [clickSave])

    const handleEdit = (index: number) => {
        const editedData = savedData[index];
        setFormData({
            ...editedData,
            call_Date_Time: editedData?.call_Date_Time ? dayjs(editedData?.call_Date_Time) : null,
            response_Date_Time: editedData?.response_Date_Time ? dayjs(editedData?.response_Date_Time) : null,

        });
        setEditIndex(index);

        setTimeout(() => {
            textFieldRef.current?.focus();
        }, 10);
        setTimeout(() => {
            textFieldRef.current?.focus();
        }, 10);
    };
    const handleResponseEdit = (index: number) => {
        const editedData = savedData[index];
        setFormData({
            ...editedData,
            call_Date_Time: editedData?.call_Date_Time ? dayjs(editedData?.call_Date_Time) : null,
            response_Date_Time: editedData?.response_Date_Time ? dayjs(editedData?.response_Date_Time) : dayjs(),

        });
        setEditIndex(index);

        setTimeout(() => {
            textFieldRef.current?.focus();
        }, 10);
        setTimeout(() => {
            textFieldRef.current?.focus();
        }, 10);
    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSaveCTD();
            handleBlurtwo()
            handleBlur()
        }
    };

    return (
        <>
            <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item md={3} sm={4} xs={6}>
                    <Autocomplete
                        size="small"
                        fullWidth
                        options={doctors || []}
                        getOptionLabel={(option) => option?.fullName || ""}
                        onChange={(event, value) => handleDoctorChange(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Call Sent To"
                                variant="outlined"
                                fullWidth
                                className="search-field"
                                onChange={(e) => setSearchDoc(e.target.value)}
                                label="Call Sent To"
                                disabled={formData.doctor_Name?._id === profile?.data._id}
                            />
                        )}
                        value={formData.doctor_Name || null}
                        disabled={formData.doctor_Name?._id === profile?.data._id}
                    />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <Autocomplete
                        size="small"
                        fullWidth
                        options={doctors?.filter(doc => doc !== formData?.doctor_Name) || []}
                        getOptionLabel={(option) => option?.fullName || ""}
                        onChange={handleCallSentByChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Sender"
                                variant="outlined"
                                fullWidth
                                className="search-field"
                                onChange={(e) => setSearchDoc(e.target.value)}
                                label="Call Sent By"
                                disabled={formData.doctor_Name?._id === profile?.data._id}
                            />
                        )}
                        value={formData.call_Sent_By || null}
                        disabled={formData.doctor_Name?._id === profile?.data._id}
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            value={formData?.call_Date_Time}
                            onChange={handleDateTimeChange("call_Date_Time")}
                            minDate={dayjs().startOf("day")}
                            label="Date & Time of Call"
                            sx={{
                                "& fieldset": { border: "none" },
                                "& .MuiInputBase-root": { height: "38px", fontSize: "12px", overflow: "hidden" },
                                "& .MuiOutlinedInput-root": { borderRadius: "8px", border: "none", backgroundColor: "#F5F5FB" },
                                width: "100%",
                            }}
                            disabled={formData.doctor_Name?._id === profile?.data._id}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            value={formData?.response_Date_Time}
                            onChange={handleDateTimeChange("response_Date_Time")}
                            minDate={dayjs().startOf("day")}
                            label="Date & Time of Response"
                            sx={{
                                "& fieldset": { border: "none" },
                                "& .MuiInputBase-root": { height: "38px", fontSize: "12px", overflow: "hidden" },
                                "& .MuiOutlinedInput-root": { borderRadius: "8px", border: "none", backgroundColor: "#F5F5FB" },
                                width: "100%",
                            }}
                            disabled={!(editIndex !== null && formData.doctor_Name?._id === profile?.data._id)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <TextField
                        name="reason_For_Call"
                        value={formData?.reason_For_Call}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        disabled={formData.doctor_Name?._id === profile?.data._id}
                        placeholder="Enter Reason"
                        onKeyDown={handleKeyDown}
                        label="Reason for Call"
                        InputProps={{
                            style: {
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "13px",
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                top: "-2px",
                            },
                        }}
                        onBlur={handleBlur}
                        multiline={isMultiline}
                        minRows={isMultiline ? 3 : 1}
                        onFocus={handleFocus}
                    />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <TextField
                        name="response"
                        value={formData.response}
                        onChange={handleInputChange}
                        fullWidth
                        inputRef={textFieldRef}
                        size="small"
                        placeholder="Enter Response"
                        disabled={!(editIndex !== null && formData.doctor_Name?._id === profile?.data._id)}
                        label="Response"
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            style: {
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "13px",
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                top: "-2px",
                            },
                        }}
                        onBlur={handleBlurtwo}
                        multiline={isMultilinetwo}
                        minRows={isMultilinetwo ? 3 : 1}
                        onFocus={handleFocustwo}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", mt: .5 }}>
                <Button size='small' variant='contained'
                    onClick={handleSaveCTD}
                    disabled={
                        !formData?.reason_For_Call ||
                        !formData?.doctor_Name ||
                        !formData?.call_Sent_By
                    }

                >
                    {editIndex !== null ? "Update" : "Save"}
                </Button>
            </Box>
            <Grid container spacing={1} sx={{ mt: .5 }}>
                <Grid item xs={12}>
                    <DynamicTable
                        headers={[
                            "SN",
                            "Call Sent To",
                            "Call Sent By",
                            "Date&Time of Call",
                            "Date&Time of Response",
                            "Reason",
                            "Response",
                            "Note",
                            "Action"
                        ]}
                        data={savedData?.map((item: any, index: number) => [
                            index + 1,
                            item.doctor_Name?.fullName || "N/A",
                            item.call_Sent_By?.fullName || "N/A",
                            item.call_Date_Time ? dayjs(item.call_Date_Time).format("YYYY.MM.DD hh:mm A") : "N/A",
                            item.response_Date_Time ? dayjs(item.response_Date_Time).format("YYYY.MM.DD hh:mm A") : "N/A",
                            item.reason_For_Call || "N/A",
                            item.response || "N/A",
                            item.decisionOnCall || "N/A",
                        ])}
                        actionButtons={[
                            {
                                icon: (rowIndex: number) => (
                                    <Tooltip title={`Only ${savedData[rowIndex]?.tooltipName} Can Edit`} placement="top">
                                        <span
                                            style={{
                                                pointerEvents: "auto",
                                            }}
                                        >
                                            <EditIcon
                                                sx={{
                                                    color: savedData[rowIndex]?.deleteID !== profile?.data._id ? "#B0B0B0" : "#FFB800", // 
                                                    "&:hover": {
                                                        background: "none",
                                                        color: savedData[rowIndex]?.deleteID !== profile?.data._id ? "#B0B0B0" : "rgb(133, 96, 1)",
                                                    },
                                                    pointerEvents: "none",
                                                }}
                                                onClick={(e) => {
                                                    if (savedData[rowIndex]?.deleteID !== profile?.data._id) {
                                                        e.stopPropagation();
                                                    } else {
                                                        handleEdit(rowIndex);
                                                    }
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                ),
                                onClick: (rowIndex: number) => handleEdit(rowIndex),
                                disabled: (rowIndex: number) => savedData[rowIndex]?.deleteID !== profile?.data._id,
                            },
                            {
                                icon: (rowIndex: number) => (
                                    <Tooltip title={`Response By ${savedData[rowIndex]?.doctor_Name?.fullName}`} placement="top">
                                        <span
                                            style={{
                                                pointerEvents: "auto",
                                            }}
                                        >
                                            <FlipCameraAndroidOutlinedIcon
                                                sx={{
                                                    color: savedData[rowIndex]?.doctor_Name?._id !== profile?.data._id ? "#B0B0B0" : "#80CBC4", // 
                                                    "&:hover": {
                                                        background: "none",
                                                        color: savedData[rowIndex]?.doctor_Name?._id !== profile?.data._id ? "#B0B0B0" : "#80CBC4",
                                                    },
                                                    pointerEvents: "none",
                                                }}
                                                onClick={(e) => {
                                                    if (savedData[rowIndex]?.doctor_Name?._id !== profile?.data._id) {
                                                        e.stopPropagation();
                                                    } else {
                                                        handleResponseEdit(rowIndex);
                                                    }
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                ),
                                onClick: (rowIndex: number) => handleEdit(rowIndex),
                                disabled: (rowIndex: number) => savedData[rowIndex]?.doctor_Name?._id !== profile?.data._id,
                            },
                            {
                                icon: (rowIndex: number) => (
                                    <Tooltip title={`Only ${savedData[rowIndex]?.tooltipName} Can Delete`} placement="top">
                                        <span
                                            style={{
                                                pointerEvents: "auto", // Ensure pointer events are enabled for the tooltip
                                            }}
                                        >
                                            <DeleteIcon
                                                sx={{
                                                    color: savedData[rowIndex]?.deleteID !== profile?.data._id ? "#B0B0B0" : "#FF5454", // Grey out if disabled
                                                    "&:hover": {
                                                        background: "none",
                                                        color: savedData[rowIndex]?.deleteID !== profile?.data._id ? "#B0B0B0" : "rgb(191, 44, 44)", // Hover color only if enabled
                                                    },
                                                    pointerEvents: "none", // Disable click event on the icon itself
                                                }}
                                                onClick={(e) => {
                                                    if (savedData[rowIndex]?.deleteID !== profile?.data._id) {
                                                        e.stopPropagation(); // Prevent the click from triggering the delete function
                                                    } else {
                                                        handleDelete(rowIndex); // Only trigger the delete if the button is not disabled
                                                    }
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                ),
                                onClick: (rowIndex: number) => handleDelete(rowIndex),
                                disabled: (rowIndex: number) => savedData[rowIndex]?.deleteID !== profile?.data._id,
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CallToDoctor;