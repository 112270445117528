import Preloader from '../../components/Preloader';
import { Suspense, lazy } from "react";
const About = lazy(() => import("../../pages/About"));
const Counter = lazy(() => import('../../pages/Counter'))
const SignUp = lazy(() => import('../../pages/SignUp'))
const VerifyEmail = lazy(() => import('../../pages/VerifyEmail'));
const PublicPaths = [
  {
    path: "/about",
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <About />
      </Suspense>
    ),
  },
  {

    path: '/counter',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <Counter />
      </Suspense>
    )
  },
  {

    path: '/sign-up',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <SignUp />
      </Suspense>
    )
  },
  {

    path: '/verify-email/:token',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <VerifyEmail />
      </Suspense>
    )
  }
];
export default PublicPaths;
