// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Paper,
//   Typography,
//   Grid,
//   TextField,
//   Checkbox,
//   Radio,
//   Select,
//   MenuItem,
//   IconButton,
//   Button,
//   Switch,
//   Slider,
//   FormControlLabel,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   RadioGroup,
//   FormLabel,
// } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import PreviewIcon from "@mui/icons-material/RemoveRedEye"; // Preview icon
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";
// import RGL, { WidthProvider } from "react-grid-layout";
// import { log } from "console";
// import { useReactToPrint } from 'react-to-print';

// const ReactGridLayout = WidthProvider(RGL);

// const useStyles = makeStyles({
//   sidebar: {
//     padding: "16px",
//     borderRight: "1px solid #ddd",
//     height: "100vh",
//     overflowY: "auto",
//   },
//   formBody: {
//     padding: "16px",
//     height: "100vh",
//     width: "100%",
//     backgroundColor: "#f9f9f9",
//     overflow: "auto",
//     position: "relative",
//   },
//   textfieldCss: {
//     fontSize: "12px",
//     fontWeight: "400",
//     color: "#000000",
//     height: "40px",
//   },
//   draggableItem: {
//     margin: "8px 0",
//     padding: "8px",
//     backgroundColor: "#e0e0e0",
//     cursor: "grab",
//     borderRadius: "4px",
//   },
//   formElement: {
//     padding: "8px",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-start",
//     justifyContent: "space-between",
//     // boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.1)",
//   },

//   formElementContent: {
//     width: "100%",
//   },
// });

// interface FormElement {
//   id: string;
//   type: string;
//   label?: string;
//   placeholder?: string;
//   value?: string;
//   options?: string[];
//   min?: string;
//   max?: string;
//   step?: string;
//   widthOption?: "small" | "medium" | "large" | "full" | "custom";
//   width?: string;
// }

// const DynamicForm: React.FC = () => {
//   const classes = useStyles();
//   const [formElements, setFormElements] = useState<FormElement[]>([]);
//   const [selectedElement, setSelectedElement] = useState<FormElement | null>(
//     null
//   );
//   const [open, setOpen] = useState(false);
//   const [jsonOpen, setJsonOpen] = useState(false);
//   const [formName, setFormName] = useState("My Form");
//   const [formNameOpen, setFormNameOpen] = useState(false);
//   const [layout, setLayout] = useState<
//     { i: string; x: number; y: number; w: number; h: number }[]
//   >([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [formFields, setFormFields] = useState<any[]>([]);

//   const handleDragStart = (
//     event: React.DragEvent<HTMLDivElement>,
//     type: string
//   ) => {
//     event.dataTransfer.setData("application/reactflow", type);
//     event.dataTransfer.effectAllowed = "move";
//   };

//   const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
//     event.preventDefault();
//     const type = event.dataTransfer.getData("application/reactflow");
//     const newElement: FormElement = {
//       id: Math.random().toString(),
//       type,
//       label: "",
//       placeholder: "",
//       value: "",
//       options: [],
//       min: "",
//       max: "",
//       step: "",
//     };
//     const newLayout = {
//       i: newElement.id,
//       x: 0,
//       y: 0,
//       w: 2,
//       h: 0,
//     };

//     setFormElements((prevElements) => [...prevElements, newElement]);
//     setLayout((prevLayout) => [...prevLayout, newLayout]);
//   };

//   const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
//     event.preventDefault();
//   };

//   const handleDelete = (id: string) => {
//     setFormElements((prevElements) =>
//       prevElements.filter((element) => element.id !== id)
//     );
//     setLayout((prevLayout) => prevLayout.filter((item) => item.i !== id));
//   };

//   const handleEdit = (element: FormElement) => {
//     setSelectedElement(element);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedElement(null);
//   };

//   const handleSave = () => {
//     if (selectedElement) {
//       setFormElements((prevElements) =>
//         prevElements.map((el) =>
//           el.id === selectedElement.id ? selectedElement : el
//         )
//       );
//     }
//     handleClose();
//   };

//   const handleInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     if (selectedElement) {
//       const { name, value } = e.target;
//       setSelectedElement({
//         ...selectedElement,
//         [name]: value,
//       });
//     }
//   };

//   const handleJsonView = () => {
//     setJsonOpen(!jsonOpen);
//   };

//   const handleFormNameOpen = () => {
//     setFormNameOpen(true);
//   };

//   const handleFormNameClose = () => {
//     setFormNameOpen(false);
//   };

//   const handleFormNameSave = () => {
//     setFormNameOpen(false);
//   };


//   const handleWidthOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (selectedElement) {
//       const newWidthOption = e.target.value as
//         | "small"
//         | "medium"
//         | "large"
//         | "full"
//         | "custom";
//       setSelectedElement({
//         ...selectedElement,
//         widthOption: newWidthOption,
//         width: newWidthOption === "custom" ? selectedElement.width : undefined,
//       });
//       // Update the width of the form element in the layout
//       setLayout((prevLayout) =>
//         prevLayout.map((item) =>
//           item.i === selectedElement.id
//             ? { ...item, w: getWidthInGridUnits(newWidthOption) }
//             : item
//         )
//       );
//     }
//   };
//   const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (selectedElement) {
//       setSelectedElement({
//         ...selectedElement,
//         width: e.target.value,
//       });
//       // Update the custom width in the layout
//       setLayout((prevLayout) =>
//         prevLayout.map((item) =>
//           item.i === selectedElement.id
//             ? { ...item, w: getWidthInGridUnits("custom", e.target.value) }
//             : item
//         )
//       );
//     }
//   };
//   const getWidthInGridUnits = (widthOption: string, customWidth?: string) => {
//     switch (widthOption) {
//       case "small":
//         return 3;
//       case "medium":
//         return 6;
//       case "large":
//         return 9;
//       case "full":
//         return 12;
//       case "custom":
//         return customWidth
//           ? Math.round((12 * parseFloat(customWidth)) / 100)
//           : 12;
//       default:
//         return 12;
//     }
//   };
//   const renderFormElement = (element: FormElement) => {
//     const widthStyles = {
//       small: "25%",
//       medium: "50%",
//       large: "75%",
//       full: "100%",
//       custom: element.width || "100%",
//     };

//     const style = {
//       width:
//         widthStyles[element.widthOption as keyof typeof widthStyles] || "100%",
//     };

//     return (
//       <Box className={classes.formElementContent} style={style}>
//         <Typography sx={{ mb: 1 }}>{element.label}</Typography>
//         {(() => {
//           switch (element.type) {
//             case "text":
//               return (
//                 <TextField
//                   placeholder={element.placeholder}
//                   variant="outlined"
//                   size="small"
//                   fullWidth
//                   className={classes.textfieldCss}
//                 />
//               );
//             case "textarea":
//               return (
//                 <TextField
//                   placeholder={element.placeholder}
//                   variant="outlined"
//                   multiline
//                   rows={4}
//                   fullWidth
//                 />
//               );
//             case "checkbox":
//               return (
//                 <FormControl component="fieldset">
//                   <Box display="flex" alignItems="center" sx={{fontSize:'8px'}}>
//                     {element.options?.map((option, index) => (
//                       <FormControlLabel
//                         key={index}
//                         control={<Checkbox size="small" value={option} />}
//                         label={option || "CheckBox"}

//                       />
//                     ))}
//                   </Box>
//                 </FormControl>
//               );
//             case "radio":
//               return (
//                 <FormControl component="fieldset">
//                   <Box display="flex" alignItems="center">
//                     {element.options?.map((option, index) => (
//                       <FormControlLabel
//                         key={index}
//                         control={<Radio size="small" value={option} />}
//                         label={option || "Radio"}
//                       />
//                     ))}
//                   </Box>
//                 </FormControl>
//               );
//             case "select":
//               return (
//                 <Select
//                   fullWidth
//                   variant="outlined"
//                   displayEmpty
//                   size="small"
//                   value={element.value}
//                   className={classes.textfieldCss}
//                 >
//                   <MenuItem value="" disabled>
//                     {element.placeholder}
//                   </MenuItem>
//                   {element.options?.map((option, index) => (
//                     <MenuItem key={index} value={option}>
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               );
//             case "button":
//               return (
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   size="small"
//                   className={classes.textfieldCss}
//                 >
//                   {element.label || "Button"}
//                 </Button>
//               );
//             case "switch":
//               return (
//                 <FormControlLabel
//                   className={classes.textfieldCss}
//                   control={<Switch size="small" />}
//                   label={element.label || "Switch"}
//                 />
//               );
//             case "slider":
//               return (
//                 <Box width="100%">
//                   <Typography gutterBottom>
//                     {element.label || "Slider"}
//                   </Typography>
//                   <Slider
//                     defaultValue={30}
//                     step={element.step ? parseFloat(element.step) : 1}
//                     min={element.min ? parseFloat(element.min) : 0}
//                     max={element.max ? parseFloat(element.max) : 100}
//                     aria-labelledby="continuous-slider"
//                   />
//                 </Box>
//               );
//             case "date":
//               return (
//                 <TextField
//                   className={classes.textfieldCss}
//                   type="date"
//                   size="small"
//                   InputLabelProps={{ shrink: true }}
//                   variant="outlined"
//                   fullWidth
//                   inputProps={{ min: element.min, max: element.max }}
//                 />
//               );
//             case "time":
//               return (
//                 <TextField
//                   size="small"
//                   type="time"
//                   InputLabelProps={{ shrink: true }}
//                   variant="outlined"
//                   fullWidth
//                   className={classes.textfieldCss}
//                   inputProps={{ min: element.min, max: element.max }}
//                 />
//               );
//             default:
//               return null;
//           }
//         })()}
//       </Box>
//     );
//   };

//   const renderPreview = () => {
//     // Define the size of each grid cell
//     const cellSize = 100; // Each cell in the grid is 100px x 100px

//     // Calculate the overall width and height based on the grid
//     const maxWidth = Math.max(
//       ...formFields?.map((el: any) => (el.x + el.w) * cellSize)
//     );
//     const maxHeight = Math.max(
//       ...formFields?.map((el: any) => (el.y + el.h) * cellSize)
//     );

//     return (
//       <Dialog
//         open={previewOpen}
//         onClose={() => setPreviewOpen(false)}
//         fullWidth
//         // maxWidth='xl'
//         PaperProps={{
//           sx: {
//             width: "100%",
//             maxWidth: "1270px!important",
//           },
//         }}
//       >
//         <Box id="preview-content">
//           <DialogTitle align="center">{formName}</DialogTitle>
//           <DialogContent>
//             <Box
//               className={classes.formBody}
//               mt={4}
//               sx={{
//                 position: "relative",
//                 width: `${maxWidth}px`,
//                 height: `${maxHeight}px`,
//                 overflow: "auto", // Ensure scrolling if content overflows
//               }}
//             >
//               {formFields?.map((element: any) => {
//                 // Calculate width and height based on grid units
//                 const width = element.w * cellSize;
//                 const height = element.h * cellSize;

//                 // Calculate top and left position based on grid units
//                 const top = element.y * cellSize;
//                 const left = element.x * cellSize;

//                 return (
//                   <Box
//                     key={element.id}
//                     className={classes.formElement}
//                     style={{
//                       position: "absolute",
//                       width: `${width}px`,
//                       height: `${height}px`,
//                       top: `${top}px`,
//                       left: `${left}px`,
//                       // boxSizing: "border-box", // Include padding and border in the element's width and height
//                     }}
//                   >
//                     {renderFormElement(element)}
//                   </Box>
//                 );
//               })}
//             </Box>
//           </DialogContent>
//         </Box>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="primary">
//             Close
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleDownloadPDF}
//           >
//             Download PDF
//           </Button>
//         </DialogActions>
//       </Dialog>
//     );
//   };

//   const generateJSON = () => {
//     return {
//       formName,
//       formElements: formElements.map((item) => ({
//         ...item,
//         ...layout.find((l) => l.i === item.id),
//       })),
//     };
//   };

//   useEffect(() => {
//     const updateFormFields = () => {
//       const { formElements: updatedFormElements } = generateJSON();
//       setFormFields(updatedFormElements);
//     };
//     updateFormFields();
//   }, [formName, formElements, layout]);
//   const handlePreview = () => {
//     setPreviewOpen(true);
//   };


//   const handlePrint = useReactToPrint({
//     content: () => document.getElementById("preview-content"),
//     documentTitle: 'Patient Report',
//     onAfterPrint: () => '',
//     pageStyle: 'A4'

//     // Clear report data after printing
//   });
//   const handleDownloadPDF = async ()=>{
//     await handlePrint()
//   }
//   return (
//     <Grid container>
//       <Grid item xs={3} className={classes.sidebar}>
//         {[
//           "text",
//           "textarea",
//           "checkbox",
//           "radio",
//           "select",
//           "button",
//           "switch",
//           "slider",
//           "date",
//           "time",
//         ].map((type) => (
//           <div
//             key={type}
//             className={classes.draggableItem}
//             draggable
//             onDragStart={(event) => handleDragStart(event, type)}
//           >
//             {type.charAt(0).toUpperCase() + type.slice(1)}
//           </div>
//         ))}
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             mt: 2,
//           }}
//         >
//           <Button variant="contained" onClick={handleJsonView}>
//             {jsonOpen ? "Hide" : "Show"} JSON
//           </Button>
//           <Button variant="contained" color="primary" onClick={handlePreview}>
//             <PreviewIcon /> Preview Form
//           </Button>
//         </Box>
//       </Grid>
//       <Grid
//         item
//         xs={9}
//         className={classes.formBody}
//         onDrop={handleDrop}
//         onDragOver={handleDragOver}
//       >
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="h6" align="center">
//             Drop Fields Here
//           </Typography>
//           {/* <IconButton onClick={handleFormNameOpen}>
//             <EditIcon />
//           </IconButton> */}
//         </Box>
//         <Typography variant="subtitle1" align="center">
//           Form Name: {formName}{" "}
//           <EditIcon
//             onClick={handleFormNameOpen}
//             sx={{ fontSize: "12px", color: "blue", cursor: "pointer" }}
//           />
//         </Typography>

//         <ReactGridLayout
//           onLayoutChange={(newLayout) => {
//             setLayout(newLayout);
//           }}
//           isDraggable={true}
//           isResizable={true}
//           style={{
//             minHeight: "75vh",
//             // background: "#F3F3F4",
//             overflowY: "auto",
//             border: "2px dotted",
//             paddingBottom: "10px",
//           }}
//         >
//           {formElements.map((element) => (
//             <div
//               key={element.id}
//               data-grid={{
//                 i: element.id,
//                 x: layout.find((item) => item.i === element.id)?.x || 0,
//                 y: layout.find((item) => item.i === element.id)?.y || 0,
//                 w: layout.find((item) => item.i === element.id)?.w || 0,
//                 h: layout.find((item) => item.i === element.id)?.h || 0,
//               }}
//             >
//               <Paper className={classes.formElement}>
//                 {renderFormElement(element)}
//                 <Box display="flex" justifyContent="flex-end">
//                   <IconButton onClick={() => handleEdit(element)}>
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton onClick={() => handleDelete(element.id)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </Box>
//               </Paper>
//             </div>
//           ))}
//         </ReactGridLayout>
//       </Grid>

//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Edit Field</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             label="Label"
//             name="label"
//             fullWidth
//             variant="outlined"
//             value={selectedElement?.label}
//             onChange={handleInputChange}
//           />
//           {selectedElement?.type !== "button" &&
//             selectedElement?.type !== "switch" &&
//             selectedElement?.type !== "slider" &&
//             selectedElement?.type !== "date" &&
//             selectedElement?.type !== "time" && (
//               <TextField
//                 margin="dense"
//                 label="Placeholder"
//                 name="placeholder"
//                 fullWidth
//                 variant="outlined"
//                 value={selectedElement?.placeholder}
//                 onChange={handleInputChange}
//               />
//             )}
//           {(selectedElement?.type === "text" ||
//             selectedElement?.type === "textarea" ||
//             selectedElement?.type === "select" ||
//             selectedElement?.type === "radio" ||
//             selectedElement?.type === "checkbox") && (
//             <TextField
//               margin="dense"
//               label="Value"
//               name="value"
//               fullWidth
//               variant="outlined"
//               value={selectedElement?.value}
//               onChange={handleInputChange}
//             />
//         )}
//         {(selectedElement?.type === "select" ||
//           selectedElement?.type === "radio" ||
//           selectedElement?.type === "checkbox") && (
//           <TextField
//             margin="dense"
//             label="Options (comma separated)"
//             name="options"
//             fullWidth
//             variant="outlined"
//             value={selectedElement?.options?.join(", ")}
//             onChange={(e) =>
//               setSelectedElement({
//                 ...selectedElement,
//                 options: e.target.value.split(",").map((opt) => opt.trim()),
//               })
//             }
//           />
//         )}
//         {(selectedElement?.type === "date" ||
//           selectedElement?.type === "time" ||
//           selectedElement?.type === "slider" ||
//           selectedElement?.type === "number") && (
//             <>
//               <TextField
//                 margin="dense"
//                 label="Min Value"
//                 name="min"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={selectedElement?.min}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 margin="dense"
//                 label="Max Value"
//                 name="max"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={selectedElement?.max}
//                 onChange={handleInputChange}
//               />
//             </>
//           )}
//           {(selectedElement?.type === "slider" ||
//             selectedElement?.type === "number") && (
//             <TextField
//               margin="dense"
//               label="Step Value"
//               name="step"
//               type="text"
//               fullWidth
//               variant="outlined"
//               value={selectedElement?.step}
//               onChange={handleInputChange}
//             />
//           )}
//           <FormControl component="fieldset">
//             <FormLabel component="legend">Width Option</FormLabel>
//             <RadioGroup
//               name="widthOption"
//               value={selectedElement?.widthOption || "full"}
//               onChange={handleWidthOptionChange}
//             >
//               <FormControlLabel
//                 value="small"
//                 control={<Radio />}
//                 label="Small (25%)"
//               />
//               <FormControlLabel
//                 value="medium"
//                 control={<Radio />}
//                 label="Medium (50%)"
//               />
//               <FormControlLabel
//                 value="large"
//                 control={<Radio />}
//                 label="Large (75%)"
//               />
//               <FormControlLabel
//                 value="full"
//                 control={<Radio />}
//                 label="Full (100%)"
//               />
//               <FormControlLabel
//                 value="custom"
//                 control={<Radio />}
//                 label="Custom"
//               />
//             </RadioGroup>
//           </FormControl>
//           {selectedElement?.widthOption === "custom" && (
//             <TextField
//               margin="dense"
//               label="Custom Width (%)"
//               name="width"
//               type="text"
//               fullWidth
//               variant="outlined"
//               value={selectedElement?.width || ""}
//               onChange={handleWidthChange}
//             />
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleSave} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={formNameOpen} onClose={handleFormNameClose}>
//         <DialogTitle>Edit Form Name</DialogTitle>
//         <DialogContent>
//           <TextField
//             size="small"
//             margin="dense"
//             label="Form Name"
//             fullWidth
//             variant="outlined"
//             value={formName}
//             onChange={(e) => setFormName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFormNameClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleFormNameSave} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//       {renderPreview()}
//       {jsonOpen && (
//         <Grid item xs={12} style={{ padding: "16px" }}>
//           <Paper style={{ padding: "16px" }}>
//             <Typography variant="h6">Generated JSON</Typography>
//             <pre style={{ whiteSpace: "pre-wrap" }}>
//               {JSON.stringify(generateJSON(), null, 2)}
//             </pre>
//           </Paper>
//         </Grid>
//       )}
//     </Grid>
//   );
// };

// export default DynamicForm;


import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  Radio,
  Select,
  MenuItem,
  IconButton,
  Button,
  Switch,
  Slider,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/RemoveRedEye"; // Preview icon
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import RGL, { WidthProvider } from "react-grid-layout";
import { log } from "console";
import { useReactToPrint } from 'react-to-print';

const ReactGridLayout = WidthProvider(RGL);

const useStyles = makeStyles({
  sidebar: {
    padding: "16px",
    borderRight: "1px solid #ddd",
    height: "100vh",
    overflowY: "auto",
  },
  formBody: {
    padding: "16px",
    height: "100vh",
    width: "100%",
    backgroundColor: "#f9f9f9",
    overflow: "auto",
    position: "relative",
  },
  textfieldCss: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#000000",
    height: "40px",
  },
  draggableItem: {
    margin: "8px 0",
    padding: "8px",
    backgroundColor: "#e0e0e0",
    cursor: "grab",
    borderRadius: "4px",
  },
  formElement: {
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    // boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.1)",
  },

  formElementContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    alignItems: "center",
    justifyContent: "flex-start",

  },
});

interface FormElement {
  id: string;
  type: string;
  label?: string;
  placeholder?: string;
  value?: string;
  options?: string[];
  min?: string;
  max?: string;
  step?: string;
  widthOption?: "small" | "medium" | "large" | "full" | "custom";
  width?: string;
}

const DynamicForm: React.FC = () => {
  const classes = useStyles();
  const [formElements, setFormElements] = useState<FormElement[]>([]);
  const [selectedElement, setSelectedElement] = useState<FormElement | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [jsonOpen, setJsonOpen] = useState(false);
  const [formName, setFormName] = useState("My Form");
  const [formNameOpen, setFormNameOpen] = useState(false);
  const [layout, setLayout] = useState<
    { i: string; x: number; y: number; w: number; h: number }[]
  >([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [formFields, setFormFields] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<any>(
    {
      label: 'A4',
      width: '794px',       // 210mm
      widthInMM: '210mm',
      height: '1123px',     // 297mm
      heightInMM: '297mm'
    })
  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    type: string
  ) => {
    event.dataTransfer.setData("application/reactflow", type);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const type = event.dataTransfer.getData("application/reactflow");
    const newElement: FormElement = {
      id: Math.random().toString(),
      type,
      label: "",
      placeholder: "",
      value: "",
      options: [],
      min: "",
      max: "",
      step: "",
    };
    const newLayout = {
      i: newElement.id,
      x: 0,
      y: 0,
      w: 2,
      h: 0,
    };

    setFormElements((prevElements) => [...prevElements, newElement]);
    setLayout((prevLayout) => [...prevLayout, newLayout]);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDelete = (id: string) => {
    setFormElements((prevElements) =>
      prevElements.filter((element) => element.id !== id)
    );
    setLayout((prevLayout) => prevLayout.filter((item) => item.i !== id));
  };

  const handleEdit = (element: FormElement) => {
    setSelectedElement(element);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedElement(null);
  };

  const handleSave = () => {
    if (selectedElement) {
      setFormElements((prevElements) =>
        prevElements.map((el) =>
          el.id === selectedElement.id ? selectedElement : el
        )
      );
    }
    handleClose();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (selectedElement) {
      const { name, value } = e.target;
      setSelectedElement({
        ...selectedElement,
        [name]: value,
      });
    }
  };

  const handleJsonView = () => {
    setJsonOpen(!jsonOpen);
  };

  const handleFormNameOpen = () => {
    setFormNameOpen(true);
  };

  const handleFormNameClose = () => {
    setFormNameOpen(false);
  };

  const handleFormNameSave = () => {
    setFormNameOpen(false);
  };

  // const handleWidthOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (selectedElement) {
  //     const newWidthOption = e.target.value as
  //       | "small"
  //       | "medium"
  //       | "large"
  //       | "full"
  //       | "custom";
  //     setSelectedElement({
  //       ...selectedElement,
  //       widthOption: newWidthOption,
  //       width: newWidthOption === "custom" ? selectedElement.width : undefined,
  //     });
  //   }
  // };

  // const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (selectedElement) {
  //     setSelectedElement({
  //       ...selectedElement,
  //       width: e.target.value,
  //     });
  //   }
  // };
  const pageSizes = [
    {
      label: 'A3',
      width: '1123px',      // 297mm
      widthInMM: '297mm',
      height: '1587px',     // 420mm
      heightInMM: '420mm'
    },
    {
      label: 'A4',
      width: '794px',       // 210mm
      widthInMM: '220mm',
      height: '1123px',     // 297mm
      heightInMM: '260mm'
    },
    {
      label: 'A5',
      width: '559px',       // 148mm
      widthInMM: '148mm',
      height: '794px',      // 210mm
      heightInMM: '210mm'
    }
  ];



  const handleWidthOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedElement) {
      const newWidthOption = e.target.value as
        | "small"
        | "medium"
        | "large"
        | "full"
        | "custom";
      setSelectedElement({
        ...selectedElement,
        widthOption: newWidthOption,
        width: newWidthOption === "custom" ? selectedElement.width : undefined,
      });
      // Update the width of the form element in the layout
      setLayout((prevLayout) =>
        prevLayout.map((item) =>
          item.i === selectedElement.id
            ? { ...item, w: getWidthInGridUnits(newWidthOption) }
            : item
        )
      );
    }
  };
  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedElement) {
      setSelectedElement({
        ...selectedElement,
        width: e.target.value,
      });
      // Update the custom width in the layout
      setLayout((prevLayout) =>
        prevLayout.map((item) =>
          item.i === selectedElement.id
            ? { ...item, w: getWidthInGridUnits("custom", e.target.value) }
            : item
        )
      );
    }
  };
  const getWidthInGridUnits = (widthOption: string, customWidth?: string) => {
    switch (widthOption) {
      case "small":
        return 3;
      case "medium":
        return 6;
      case "large":
        return 9;
      case "full":
        return 12;
      case "custom":
        return customWidth
          ? Math.round((12 * parseFloat(customWidth)) / 100)
          : 12;
      default:
        return 12;
    }
  };
  const renderFormElement = (element: FormElement) => {
    const widthStyles = {
      small: "25%",
      medium: "50%",
      large: "75%",
      full: "100%",
      custom: element.width || "100%",
    };

    const style = {
      width:
        widthStyles[element.widthOption as keyof typeof widthStyles] || "100%",
    };

    return (
      <Box className={classes.formElementContent} style={style}>
        <Typography >{element?.label ? element?.label + ":" : ""}</Typography>
        {(() => {
          switch (element.type) {
            case "text":
              return (
                <TextField
                  placeholder={element.placeholder}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={element?.value}
                  className={classes.textfieldCss}
                />
              );
            case "textarea":
              return (
                <TextField
                  placeholder={element.placeholder}
                  variant="outlined"
                  multiline
                  rows={4}
                  value={element?.value}
                  fullWidth
                />
              );
            case "checkbox":
              return (
                <FormControl component="fieldset">
                  <Box display="flex" alignItems="center" sx={{ fontSize: '8px' }}>
                    {element.options?.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox size="small" value={option} />}
                        label={option || "CheckBox"}

                      />
                    ))}
                  </Box>
                </FormControl>
              );
            case "radio":
              return (
                <FormControl component="fieldset">
                  <Box display="flex" alignItems="center">
                    {element.options?.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={<Radio size="small" value={option} />}
                        label={option || "Radio"}
                      />
                    ))}
                  </Box>
                </FormControl>
              );
            case "select":
              return (
                <Select
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  size="small"
                  value={element.value}
                  className={classes.textfieldCss}
                >
                  <MenuItem value="" disabled>
                    {element.placeholder}
                  </MenuItem>
                  {element.options?.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              );
            case "button":
              return (
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  className={classes.textfieldCss}
                >
                  {element.label || "Button"}
                </Button>
              );
            case "switch":
              return (
                <FormControlLabel
                  className={classes.textfieldCss}
                  control={<Switch size="small" />}
                  label={element.label || "Switch"}
                />
              );
            case "slider":
              return (
                <Box width="100%">
                  <Typography gutterBottom>
                    {element.label || "Slider"}
                  </Typography>
                  <Slider
                    defaultValue={30}
                    step={element.step ? parseFloat(element.step) : 1}
                    min={element.min ? parseFloat(element.min) : 0}
                    max={element.max ? parseFloat(element.max) : 100}
                    aria-labelledby="continuous-slider"
                  />
                </Box>
              );
            case "date":
              return (
                <TextField
                  className={classes.textfieldCss}
                  type="date"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ min: element.min, max: element.max }}
                />
              );
            case "time":
              return (
                <TextField
                  size="small"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  className={classes.textfieldCss}
                  inputProps={{ min: element.min, max: element.max }}
                />
              );
            default:
              return null;
          }
        })()}
      </Box>
    );
  };

  const renderPreview = () => {
    // Define the size of each grid cell
    const cellSize = Math.floor(parseInt(pageSize?.width) / 12); // Each cell in the grid is 100px x 100px
    // Calculate the overall width and height based on the grid
    const maxWidth = Math.max(
      ...formFields?.map((el: any) => (el.x + el.w) * cellSize)
    );
    const maxHeight = Math.max(
      ...formFields?.map((el: any) => (el.y + el.h) * cellSize)
    );

    return (
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullWidth
        // maxWidth='xl'
        PaperProps={{
          sx: {
            width: `${pageSize?.width}`,
            // maxWidth: "1270px!important",
          },
        }}
      >
        <Box id="preview-content" sx={{
          width: `${pageSize?.widthInMM}`,
          // height: `${pageSize?.heightInMM}`,
        }}>
          <DialogTitle align="center">{formName}</DialogTitle>
          <DialogContent>
            <Box
              className={classes.formBody}
              mt={4}
              sx={{
                position: "relative",
                // width: `${maxWidth}px`,
                // height: `${maxHeight}px`,
                overflow: "auto", // Ensure scrolling if content overflows
              }}
            >
              {formFields?.map((element: any) => {
                // Calculate width and height based on grid units
                const width = element.w * cellSize;
                const height = element.h * cellSize;

                // Calculate top and left position based on grid units
                const top = element.y * cellSize;
                const left = element.x * cellSize;

                return (
                  <Box
                    key={element.id}
                    className={classes.formElement}
                    style={{
                      position: "absolute",
                      width: `${width}px`,
                      height: `${height}px`,
                      top: `${top}px`,
                      left: `${left}px`,
                      // boxSizing: "border-box", // Include padding and border in the element's width and height
                    }}
                  >
                    {renderFormElement(element)}
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
          >
            Download PDF
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const generateJSON = () => {
    return {
      formName,
      formElements: formElements.map((item) => ({
        ...item,
        ...layout.find((l) => l.i === item.id),
      })),
    };
  };

  useEffect(() => {
    const updateFormFields = () => {
      const { formElements: updatedFormElements } = generateJSON();
      setFormFields(updatedFormElements);
    };
    updateFormFields();
  }, [formName, formElements, layout]);
  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handlePageSizeChange = (e: any): void => {
    const pageSize = pageSizes.find(p => p.label === e.target.value);
    setPageSize(pageSize);
  }

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("preview-content"),
    documentTitle: 'Patient Report',
    onAfterPrint: () => '',
    pageStyle: pageSize?.label || "A4"

    // Clear report data after printing
  });
  const handleDownloadPDF = async () => {
    await handlePrint()
  }
  return (
    <Grid container>
      <Grid item xs={3} className={classes.sidebar}>
        {[
          "text",
          "textarea",
          "checkbox",
          "radio",
          "select",
          "button",
          "switch",
          "slider",
          "date",
          "time",
        ].map((type) => (
          <div
            key={type}
            className={classes.draggableItem}
            draggable
            onDragStart={(event) => handleDragStart(event, type)}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </div>
        ))}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Button variant="contained" onClick={handleJsonView}>
            {jsonOpen ? "Hide" : "Show"} JSON
          </Button>
          <Button variant="contained" color="primary" onClick={handlePreview}>
            <PreviewIcon /> Preview Form
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={9}
        className={classes.formBody}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" align="center">
            Drop Fields Here
          </Typography>
          {/* <IconButton onClick={handleFormNameOpen}>
            <EditIcon />
          </IconButton> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "center" }}>
          <Typography variant="subtitle1" align="center">
            Form Name: {formName}{" "}
            <EditIcon
              onClick={handleFormNameOpen}
              sx={{ fontSize: "12px", color: "blue", cursor: "pointer" }}
            />
          </Typography>
          <Typography variant="subtitle1" align="center">
            PageSize:{" "}
            <Select
              size="small"
              value={pageSize.label}
              onChange={handlePageSizeChange}
              sx={{
                borderRadius: '12px',
                border: '1px solid #D8D8D8',
                background: '#F5F5FB',
                fontSize: '12px',
                boxShadow: 'none',
                padding: '4px 12px',
                '& fieldset': { border: 'none' },
              }}
              displayEmpty
            >
              {pageSizes?.map((size, index) => (
                <MenuItem
                  key={index}
                  value={size.label}
                  sx={{
                    fontSize: '14px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                  }}
                >
                  <Box>{size.label}</Box>
                  <Box sx={{ color: '#888', fontSize: '12px' }}>
                    {size.width} x {size.height}
                  </Box>
                </MenuItem>
              ))}
            </Select>

          </Typography>
        </Box>


        <Box width={pageSize?.width}>
          <ReactGridLayout
            onLayoutChange={(newLayout) => {
              setLayout(newLayout);
            }}
            isDraggable={true}
            isResizable={true}
            style={{
              minHeight: "75vh",
              background: "#ffff",
              overflowY: "auto",
              border: "2px dotted",
              paddingBottom: "10px",
            }}
          >
            {formElements.map((element) => (
              <div
                key={element.id}
                data-grid={{
                  i: element.id,
                  x: layout.find((item) => item.i === element.id)?.x || 0,
                  y: layout.find((item) => item.i === element.id)?.y || 0,
                  w: layout.find((item) => item.i === element.id)?.w || 0,
                  h: layout.find((item) => item.i === element.id)?.h || 0,
                }}
              >
                <Box className={classes.formElement}>
                  {renderFormElement(element)}
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton onClick={() => handleEdit(element)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(element.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </div>
            ))}
          </ReactGridLayout>
        </Box>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Field</DialogTitle>
        <FormControl component="fieldset" >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "7px", alignItems: "center", paddingLeft: "25px", }}>
            <FormLabel component="legend" sx={{ fontSize: "15px", fontWeight: "700" }} >Width Option</FormLabel>
            <RadioGroup
              name="widthOption"
              value={selectedElement?.widthOption || "full"}
              onChange={handleWidthOptionChange}
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "11px" }}
            >
              <FormControlLabel
                value="small"
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '15px',
                  },
                }} />}
                label="Small (25%)"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '11px',
                  },
                }}
              />

              <FormControlLabel
                value="medium"
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '15px',
                  },
                }} />}
                label="Medium (50%)"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '11px',
                  },
                }}
              />
              <FormControlLabel
                value="large"
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '15px',
                  },
                }} />}
                label="Large (75%)"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '11px',
                  },
                }}
              />
              <FormControlLabel
                value="full"
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '15px',
                  },
                }} />}
                label="Full (100%)"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '11px',
                  },
                }}
              />
              <FormControlLabel
                value="custom"
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '15px',
                  },
                }} />}
                label="Custom"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '11px',
                  },
                }}
              />
            </RadioGroup>
          </Box>
        </FormControl>
        <DialogContent>

          <TextField
            margin="dense"
            label="Label"
            name="label"
            fullWidth
            variant="outlined"
            value={selectedElement?.label}
            onChange={handleInputChange}
            className="search-field"
            size="small"
          />
          {selectedElement?.type !== "button" &&
            selectedElement?.type !== "switch" &&
            selectedElement?.type !== "slider" &&
            selectedElement?.type !== "date" &&
            selectedElement?.type !== "time" && (
              <TextField
                margin="dense"
                label="Placeholder"
                name="placeholder"
                fullWidth
                variant="outlined"
                value={selectedElement?.placeholder}
                onChange={handleInputChange}
                className="search-field"
                size="small"
              />
            )}
          {(selectedElement?.type === "text" ||
            selectedElement?.type === "textarea" ||
            selectedElement?.type === "select" ||
            selectedElement?.type === "radio" ||
            selectedElement?.type === "checkbox") && (
              <TextField
                margin="dense"
                label="Value"
                name="value"
                fullWidth
                variant="outlined"
                value={selectedElement?.value}
                onChange={handleInputChange}
                className="search-field"
                size="small"
              />
            )}
          {(selectedElement?.type === "select" ||
            selectedElement?.type === "radio" ||
            selectedElement?.type === "checkbox") && (
              <TextField
                margin="dense"
                label="Options (comma separated)"
                name="options"
                fullWidth
                variant="outlined"
                value={selectedElement?.options?.join(", ")}
                onChange={(e) =>
                  setSelectedElement({
                    ...selectedElement,
                    options: e.target.value.split(",").map((opt) => opt.trim()),
                  })
                }
              />
            )}
          {(selectedElement?.type === "date" ||
            selectedElement?.type === "time" ||
            selectedElement?.type === "slider" ||
            selectedElement?.type === "number") && (
              <>
                <TextField
                  margin="dense"
                  label="Min Value"
                  name="min"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={selectedElement?.min}
                  onChange={handleInputChange}
                  className="search-field"
                  size="small"
                />
                <TextField
                  margin="dense"
                  label="Max Value"
                  name="max"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={selectedElement?.max}
                  onChange={handleInputChange}
                  className="search-field"
                  size="small"
                />
              </>
            )}
          {(selectedElement?.type === "slider" ||
            selectedElement?.type === "number") && (
              <TextField
                margin="dense"
                label="Step Value"
                name="step"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedElement?.step}
                onChange={handleInputChange}
                className="search-field"
                size="small"
              />
            )}

          {selectedElement?.widthOption === "custom" && (
            <TextField
              margin="dense"
              label="Custom Width (%)"
              name="width"
              type="text"
              fullWidth
              variant="outlined"
              value={selectedElement?.width || ""}
              onChange={handleWidthChange}
              className="search-field"
              size="small"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={formNameOpen} onClose={handleFormNameClose}>
        <DialogTitle>Edit Form Name</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            margin="dense"
            label="Form Name"
            fullWidth
            variant="outlined"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormNameClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormNameSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {renderPreview()}
      {jsonOpen && (
        <Grid item xs={12} style={{ padding: "16px" }}>
          <Paper style={{ padding: "16px" }}>
            <Typography variant="h6">Generated JSON</Typography>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(generateJSON(), null, 2)}
            </pre>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default DynamicForm;