import { Autocomplete, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography, Tabs, Tab, CardContent, Card } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DynamicTable from "../../../../Dynamic-table";
import { MedicineDose } from "../../../../OPD/add-patient-record/MedicineDoses";
import dayjs from "dayjs";
import CloseIcon from '@mui/icons-material/Close';
import StopIcon from '@mui/icons-material/Stop';
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { isDateTimeGreaterThan30MinutesAgo } from "../../../../../utils/dateTimeUtils";

interface TreatmentProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any
}
const TreamentChart: React.FC<TreatmentProps> = ({ onChange, data, otherTabsData, profile }) => {
    const currentDate = dayjs();
    const [services, setServices] = useState<any[]>([])
    const [id, setId] = useState("")
    const [routeAdm, setRouteAdm] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({
        medicines: [],
        transfusion: [],
        stopped_medicines: [],
        stoppedTransfusion: []
    })
    const [openStopDialog, setOpenStopDialog] = useState<boolean>(false);
    const [medicineToStop, setMedicineToStop] = useState<any>(null);
    const [openDelMed, setOpenDelMed] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<any>(0);

    const handleCloseStopDialog = () => {
        setOpenDelMed(false)
    }
    const handleOpenStopDialog = () => {
        setOpenDelMed(true)
    }
    const handleTabChange = (event: any, newValue: any) => {
        if (newValue < formData?.stopped_medicines?.length) {
            setSelectedTab(newValue);
        } else {
            setSelectedTab(0);
        }
    };

    const mergeMedicines = (existingMedicines = [], newMedicines = [], stopped_medicines = []) => {
        const existingMedicinesMap = new Map(existingMedicines?.map((medicine: any) => [medicine?.id, medicine]));
        const deletedMedicineIds = new Set(stopped_medicines?.map((medicine: any) => medicine?.id));
        newMedicines?.forEach((newMedicine: any) => {
            if (deletedMedicineIds?.has(newMedicine.id)) {
                return;
            }
            const existingMedicine = existingMedicinesMap?.get(newMedicine.id);

            if (existingMedicine) {
                existingMedicinesMap?.set(newMedicine?.id, { ...existingMedicine, ...newMedicine });
            } else {
                existingMedicinesMap?.set(newMedicine.id, newMedicine);
            }
        });
        existingMedicinesMap?.forEach((medicine, id) => {
            if (deletedMedicineIds?.has(id)) {
                existingMedicinesMap?.delete(id);
            }
        });
        return Array.from(existingMedicinesMap?.values());
    };

    const mergeTransfusions = (existingTransfusions = [], newTransfusions = [], stoppedTransfusions = []) => {
        const existingTransfusionsMap = new Map(existingTransfusions?.map((transfusion: any) => [transfusion?.id, transfusion]));
        const deletedTransfusionIds = new Set(stoppedTransfusions?.map((transfusion: any) => transfusion?.id));
        newTransfusions?.forEach((newTransfusion: any) => {
            if (deletedTransfusionIds?.has(newTransfusion.id)) {
                return;
            }
            const existingTransfusion = existingTransfusionsMap?.get(newTransfusion.id);
            if (existingTransfusion) {
                existingTransfusionsMap?.set(newTransfusion?.id, { ...existingTransfusion, ...newTransfusion });
            } else {
                existingTransfusionsMap?.set(newTransfusion.id, newTransfusion);
            }
        });
        existingTransfusionsMap?.forEach((transfusion, id) => {
            if (deletedTransfusionIds?.has(id)) {
                existingTransfusionsMap?.delete(id);
            }
        });
        return Array.from(existingTransfusionsMap?.values());
    };

    const fetchFormData = async (formName: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const fetchedData = await response?.data?.data?.formData;
            setFormData((prevData: any) => ({
                ...prevData,
                medicines: mergeMedicines(prevData?.medicines || [], fetchedData?.medicines || []),
                transfusion: mergeTransfusions(prevData?.transfusion, fetchedData?.transfusion),
            }));
            setId(response?.data?.data?.searchId);
        } catch (error) {
            toast.error('Failed to fetch form data.');
        }
    };

    useEffect(() => {
        fetchFormData('Treatment');
    }, []);

    useEffect(() => {
        if (data) {
            setFormData((prevState: any) => ({
                ...prevState,
                medicines: mergeMedicines(prevState?.medicines || [], data?.medicines || [], data?.stopped_medicines || []),
                transfusion: mergeTransfusions(prevState?.transfusion, data?.transfusion, data?.stoppedTransfusion || []),
                stopped_medicines: data?.stopped_medicines || [],
                stoppedTransfusion: data?.stoppedTransfusion
            }));
        }
    }, [data]);

    useEffect(() => {
        onChange({ ...formData, transfusion: formData?.transfusion })
    }, [formData?.transfusion])

    const getAllocatedServices = async (id: any) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_IPD_API_URL}/ipd-services-allocation/getAll_allocateServices/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response?.data?.isSuccess) {
                setServices(response?.data?.data || []);
            }
        } catch (error) {
            console.error("Error fetching admission information:", error);
        }
    }
    useEffect(() => {
        if (id) {
            getAllocatedServices(id)
        }
    }, [id]);

    const handleDeleteGivenBy = (medicineName: string, idx: number) => {
        if (!formData || !formData?.medicines) return;
        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicineName) {
                const updatedGivenBy = med?.med_History?.filter((_: any, index: number) => index !== idx);
                return {
                    ...med,
                    med_History: updatedGivenBy,
                };
            }
            return med;
        });
        setFormData((prevState: any) => ({
            ...prevState,
            medicines: updatedMedicines,
        }));
    };

    const getRouteAdm = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/getAllByPagination`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    count: 50000,
                    pageNo: 1,
                },
            }
        );
        setRouteAdm(response.data?.data);
    };

    useEffect(() => {
        getRouteAdm();
    }, []);

    const formatDateTime = (dateTime: string) => {
        if (!dateTime) return { date: 'No date available', time: 'No time available' };
        const dayjsDate = dayjs(dateTime, 'DD.MM.YYYY hh:mm A');
        return {
            date: dayjsDate.format('DD.MMM.YYYY'),
            time: dayjsDate.format('hh:mm A'),
        };
    };

    useEffect(() => {
        onChange({ ...formData, medicines: formData?.medicines, transfusion: data?.transfusion });
    }, [formData])

    const handleContinue = (medicine: any) => {
        const doctorName = profile?.data?.fullName;
        const startedAt = currentDate.format('DD.MM.YYYY hh:mm A');
        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicine?.name) {
                if (med?.status === 'started') return med;
                const updatedStatusHistory = [
                    ...(med?.med_History || []),
                    {
                        status: 'started',
                        date_Time: startedAt,
                        doctorName,
                        action: 'started',
                    },
                ];
                return {
                    ...med,
                    status: 'started',
                    med_History: updatedStatusHistory,
                };
            }
            return med;
        });
        setFormData({ ...formData, medicines: updatedMedicines });
    };

    const handleGivenBy = (medicine: MedicineDose) => {
        const doctorName = profile?.data?.fullName;
        const givenAt = currentDate.format('DD.MM.YYYY hh:mm A');

        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicine?.name) {
                const updatedStatusHistory = [
                    ...(med?.med_History || []),
                    {
                        status: 'given',
                        date_Time: givenAt,
                        doctorName,
                        action: 'given',
                    },
                ];
                return {
                    ...med,
                    med_History: updatedStatusHistory,
                };
            }
            return med;
        });
        setFormData({ ...formData, medicines: updatedMedicines });
    };

    const handleStopClick = (medicine: any) => {
        setMedicineToStop(medicine); // Set the medicine to stop
        setOpenStopDialog(true); // Open the confirmation dialog
    };
    const handleStopMedicine = () => {
        if (!medicineToStop) return;

        const doctorName = profile?.data?.fullName;
        const stoppedAt = currentDate.format('DD.MM.YYYY hh:mm A');

        // Update the medicine history with stopped status
        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicineToStop?.name) {
                const updatedStatusHistory = [
                    ...(med?.med_History || []),
                    {
                        status: 'stopped',
                        date_Time: stoppedAt,
                        doctorName,
                        action: 'stopped',
                    },
                ];
                return {
                    ...med,
                    status: 'stopped',
                    med_History: updatedStatusHistory,
                };
            }
            return med;
        });

        // Add to deleted medicines and remove from the medicines list
        const updatedDeletedMedicines = [
            ...(formData?.stopped_medicines || []),
            { ...medicineToStop, stoppedAt, doctorName },
        ];

        setFormData({
            ...formData,
            medicines: updatedMedicines.filter((med: any) => med?.name !== medicineToStop?.name),
            stopped_medicines: updatedDeletedMedicines,
        });

        setOpenStopDialog(false); // Close the dialog after stopping the medicine
        setMedicineToStop(null); // Clear the medicine to stop
    };

    const handleCancelStop = () => {
        setOpenStopDialog(false); // Close the dialog without doing anything
        setMedicineToStop(null); // Clear the medicine to stop
    };
    const [stopReasonDialogOpen, setStopReasonDialogOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [transfusionToStop, setTransfusionToStop] = useState<any>(null);
    const [selectedTransfusion, setSelectedTransfusion] = useState<any>(null);
    const [selectedTransfuseTab, setSelectedTransfuseTab] = useState(0);
    const [showStoppedTransfusions, setShowStoppedTransfusions] = useState(false);

    const handleTransfuseTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTransfuseTab(newValue);
    };
    const handleDeleteGivenHistory = (transfusionId: number, historyIndex: number) => {
        const updatedTransfusions = formData?.transfusion?.map((item: any) => {
            if (item?.id === transfusionId) {
                const updatedHistory = [...item?.transfuse_History];
                updatedHistory.splice(historyIndex, 1);
                return { ...item, transfuse_History: updatedHistory };
            }
            return item;
        });

        setFormData({ ...formData, transfusion: updatedTransfusions });
    };


    const toggleStoppedTransfusions = () => {
        setShowStoppedTransfusions(prevState => !prevState);
    };
    const handleStartTransfusion = (transfusionId: number) => {
        const doctorName = profile?.data?.fullName;
        const startedAt = dayjs().format("DD.MM.YYYY hh:mm A");

        const updatedTransfusions = formData?.transfusion?.map((item: any) => {
            if (item?.id === transfusionId && item?.status !== "started") {
                const updatedHistory = [
                    ...(item.transfuse_History || []),
                    { status: "started", date_Time: startedAt, doctorName },
                ];
                return {
                    ...item,
                    status: "started",
                    transfuse_History: updatedHistory,
                    lastAdvised: { action: "Started", date_Time: startedAt, doctorName },
                };
            }
            return item;
        });

        setFormData({ ...formData, transfusion: updatedTransfusions });
    };

    const handleStopTransfusion = (transfusionId: number) => {
        const transfusionToStop = formData?.transfusion?.find((item: any) => item.id === transfusionId);
        setTransfusionToStop(transfusionToStop);
        setStopReasonDialogOpen(true);
    };

    // const handleConfirmStop = () => {
    //     const doctorName = profile?.data?.fullName;
    //     const stoppedAt = dayjs().format("DD.MM.YYYY hh:mm A");
    //     const updatedTransfusions = formData?.transfusion?.filter(
    //         (item: any) => item?.id !== transfusionToStop?.id
    //     );
    //     const updatedStoppedTransfusions = [
    //         ...formData.stoppedTransfusion,
    //         {
    //             ...transfusionToStop,
    //             status: "stopped",
    //             stoppedAt,
    //             doctorName,
    //             reason,
    //         },
    //     ];
    //     setFormData({ transfusion: updatedTransfusions, stoppedTransfusion: updatedStoppedTransfusions });
    //     setStopReasonDialogOpen(false);
    //     setReason('')
    // };


    const handleConfirmStop = () => {
        const doctorName = profile?.data?.fullName;
        const stoppedAt = dayjs().format("DD.MM.YYYY hh:mm A");

        const updatedTransfusions = formData?.transfusion?.filter(
            (item: any) => item?.id !== transfusionToStop?.id
        );

        const updatedStoppedTransfusions = [
            ...(formData?.stoppedTransfusion || []),
            {
                ...transfusionToStop,
                status: "stopped",
                stoppedAt,
                doctorName,
                reason,
            },
        ];

        setFormData({
            transfusion: updatedTransfusions,
            stoppedTransfusion: updatedStoppedTransfusions,
        });

        setStopReasonDialogOpen(false);
        setReason('');
    };

    const handleGiven = (transfusionId: number) => {
        const doctorName = profile?.data?.fullName;
        const dateTime = dayjs().format("DD.MM.YYYY hh:mm A");
        const updatedTransfusions = formData.transfusion.map((item: any) => {
            if (item.id === transfusionId) {
                const updatedHistory = [
                    ...(item.transfuse_History || []),
                    { action: "Given", date_Time: dateTime, doctorName },
                ];
                return { ...item, transfuse_History: updatedHistory };
            }
            return item;
        });

        setFormData({ ...formData, transfusion: updatedTransfusions });
    };

    const handleOpenDialog = (transfusion: any) => {
        setSelectedTransfusion(transfusion);
    };

    const handleCloseDialog = () => {
        setSelectedTransfusion(null);
        setReason('')
    };
    useEffect(() => {
        onChange({ ...formData });
    }, [formData]);

    return (
        <>
            <Box sx={{ bgcolor: "rgba(223, 234, 255, 1)", border: "1px solid rgba(5, 95, 252, 1)", padding: "1px", borderRadius: "10px" }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Guideline
                </Typography>
                {formData?.guidance ? <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>{formData?.guidance}
                </Typography > : ""
                }
            </Box>
            <Typography sx={{ fontSize: '14px', fontWeight: '600', mt: 1 }} >Services</Typography>
            <DynamicTable
                headers={[
                    "SN",
                    "Services",
                    "Charge Type",
                    "Charges",
                    "Discount",
                    "Net Charges",
                ]}
                data={services?.map((item: any, index: any) => [
                    index + 1,
                    item?.ipdServices?.serviceName,
                    item?.ipdServices?.chargeType,
                    item?.ipdServices?.charges,
                    item?.discountRS,
                    item?.ipdServices?.charges - item?.discountRS,
                ])}
            />
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', mt: 1 }}>Medicine</Typography>
                <Button variant="contained" color='secondary' onClick={handleOpenStopDialog} size="small" >Stopped Medicines</Button>
            </Box>
            <DynamicTable
                headers={[
                    "SN",
                    "Medicine",
                    "Strength",
                    "Unit",
                    "Dosage",
                    "Route",
                    "Frequency",
                    "Duration",
                    "Doctor Name",
                    "Date & Time"
                ]}
                data={formData?.medicines?.map((item: any, index: any) => [
                    index + 1,
                    item?.name,
                    item?.strength,
                    item?.medicineUnit,
                    item?.dosage,
                    (() => {
                        const route = routeAdm?.find(
                            (route: any) =>
                                route?._id === item?.routAdministrationId
                        );
                        return route ? route?.RA_Abbreviation : "-";
                    })(),
                    item?.frequency?.abr_notation,
                    item?.duration,
                    item?.doctorName || "-",
                    item?.dateTime || dayjs(item?.dateAndTime).format('DD.MM.YYYY hh:mm A')
                ])}
            />
            <Grid container>
                <Grid item md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                    {Array.isArray(formData?.medicines) && formData?.medicines?.length > 0 && (
                        <Box display="flex" gap={2} flexWrap="wrap">
                            {Array.isArray(formData?.medicines) && formData?.medicines?.map((medicine: any, index: any) => {
                                if (!medicine) return null;
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            backgroundColor: '#F5F5FB',
                                            padding: '8px',
                                            borderRadius: '8px',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                            width: '180px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#888',
                                                borderRadius: '10px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                backgroundColor: '#555',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                color: '#077DD3',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            {medicine?.name}
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                {medicine?.status === 'started' ? (
                                                    <Tooltip placement="top" title="Stop">
                                                        <IconButton
                                                            size="small"
                                                            // color="primary"
                                                            onClick={() => handleStopClick(medicine)}
                                                        >
                                                            <StopIcon fontSize="small" sx={{ color: 'red' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip placement="top" title="Start" >
                                                        <IconButton
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => handleContinue(medicine)}
                                                        >
                                                            <PlayArrowIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </Typography>
                                        <Divider sx={{ width: '100%', mb: 0.2 }} />

                                        <Box mt={0.5} mb={0.5}>
                                            {
                                                Array.isArray(medicine?.med_History) && medicine?.med_History
                                                    ?.map((item: any, idx: any) => {
                                                        const { date, time } = formatDateTime(item?.date_Time);
                                                        let displayIndex = null;
                                                        const givenItems = medicine?.med_History?.filter((item: any) => item?.status === 'given');
                                                        const givenIndex = givenItems?.findIndex((givenItem: any) => givenItem === item);

                                                        if (item?.status === 'given') {
                                                            displayIndex = givenIndex + 1;
                                                        }
                                                        return (
                                                            <>
                                                                <Box>
                                                                    <Box display="flex" alignItems="center" gap={2}>
                                                                        {displayIndex !== null && (
                                                                            <span style={{ fontSize: '10px', fontWeight: '600' }}>
                                                                                {displayIndex}:
                                                                            </span>
                                                                        )}
                                                                        <Typography sx={{
                                                                            fontSize: '10px',
                                                                            color: item?.status === 'given' ? '#000' : item?.status === 'started' ? 'blue' : item?.status === 'stopped' ? 'red' : 'gray',
                                                                            textTransform: 'capitalize'
                                                                        }}>
                                                                            {item?.status} at: {time}
                                                                        </Typography>
                                                                        {item?.status === 'given' && item?.doctorName === profile?.data?.fullName && (
                                                                            <Tooltip title="Delete" placement="top">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    disabled={!isDateTimeGreaterThan30MinutesAgo(item?.date_Time)}
                                                                                    onClick={() => handleDeleteGivenBy(medicine?.name, idx)}
                                                                                    sx={{ color: 'red', height: 12, width: 12 }}
                                                                                >
                                                                                    <CloseIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Box>
                                                                    <Typography sx={{ fontSize: '10px', color: 'gray', marginTop: 0.3 }}>
                                                                        {item?.doctorName}
                                                                        <span style={{ fontSize: '10px', color: 'gray' }}> {date} </span>
                                                                    </Typography>
                                                                </Box>
                                                                <Divider sx={{ width: '100%', mb: 0.2 }} />
                                                            </>
                                                        );
                                                    })
                                            }
                                            <Box mt={1} display="flex" justifyContent="center">
                                                {medicine?.status === 'started' && (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => handleGivenBy(medicine)}
                                                        sx={{ mt: 'auto', color: '#fff' }}
                                                        color="success"
                                                    >
                                                        Given
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Box mt={1} sx={{ width: '100%' }} >
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant="h3" sx={{ fontSize: '14px', fontWeight: '600' }} gutterBottom>Transfusions</Typography>
                    <Button
                        variant="contained"
                        onClick={toggleStoppedTransfusions}
                        color='secondary'
                        size="small"
                    >
                        Show Stopped Transfusions
                    </Button>
                </Box>
                <Box display="flex"
                    flexWrap="wrap" // Allow wrapping of cards when needed
                    gap={2}
                >
                    {formData?.transfusion?.map((transfusion: any, index: any) => (
                        <Card key={index} sx={{ boxShadow: 3, position: 'relative', width: 'calc(20% - 16px)', minHeight: '120px', borderRadius: '12px', marginTop: '0px', }}>
                            <CardContent sx={{ padding: '4px' }}>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography variant="h6" sx={{ fontSize: '12px', fontWeight: 'bold', }}>{transfusion?.bloodTransfusion}</Typography>
                                    <Box >
                                        {transfusion?.status === "started" ? (
                                            <Tooltip title="Stop">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleStopTransfusion(transfusion.id)} // Stop only the clicked transfusion
                                                >
                                                    <StopIcon fontSize="small" sx={{ color: "red" }} />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Start">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleStartTransfusion(transfusion.id)} // Start only the clicked transfusion
                                                >
                                                    <PlayArrowIcon fontSize="small" sx={{ color: "green" }} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>
                                <Typography variant="body2" sx={{ fontSize: '10px' }}>
                                    Components: {transfusion?.components?.join(", ")}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '10px' }}>
                                    {transfusion?.bloodTransfusion === "Arrange" ? transfusion?.pint + " pint" : transfusion?.quantity + " ml"}  {transfusion?.bloodTransfusion === "Transfuse" ? transfusion?.duration + " " + transfusion?.schedule : ''}
                                </Typography>
                                <Divider />

                                <Box mt={2}>
                                    {transfusion?.transfuse_History?.map((history: any, idx: number) =>
                                        history.action === "Given" && (
                                            <Box key={idx} sx={{ marginBottom: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Box>
                                                    <Typography variant="body2" sx={{ fontSize: "10px", color: 'gray' }}>
                                                        {history?.action} At: {history?.date_Time}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: "10px", color: 'gray' }} variant="body2">
                                                        {history?.doctorName}
                                                    </Typography>
                                                </Box>
                                                {/* <Tooltip title="Delete">
                                                    <IconButton sx={{ color: 'red' }}
                                                        size="small"
                                                        onClick={() => handleDeleteGivenHistory(transfusion?.id, history?.date_Time)}
                                                        disabled={!isDateTimeGreaterThan30MinutesAgo(history?.date_Time)}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </Box>
                                        )
                                    )}
                                </Box>

                                <Box display="flex" justifyContent="center" gap={1} mt={2}>
                                    {transfusion?.status === "started" && (
                                        <Button size="small"
                                            variant="outlined"
                                            onClick={() => handleGiven(transfusion?.id)} // Given action for the clicked transfusion
                                        >
                                            Given
                                        </Button>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>

                <Dialog open={showStoppedTransfusions} onClose={toggleStoppedTransfusions} maxWidth='sm' fullWidth>
                    <Box>
                        <Typography variant="h6" sx={{ fontSize: '14px' }}>
                            Stopped Transfusions
                        </Typography>

                        <Tabs value={selectedTransfuseTab} onChange={handleTransfuseTabChange} aria-label="Stopped Transfusions Tabs">
                            {formData?.stoppedTransfusion?.map((transfusion: any, index: number) => (
                                <Tab key={index} label={transfusion.bloodTransfusion} />
                            ))}
                        </Tabs>

                        <Divider sx={{ marginY: .2 }} />
                        {formData?.stoppedTransfusion?.map((transfusion: any, index: number) => (
                            <Box
                                role="tabpanel"
                                hidden={selectedTransfuseTab !== index}
                                key={index}
                                sx={{ padding: 1 }}
                            >
                                <Typography variant="h6"
                                    sx={{ fontSize: '12px' }}
                                >{transfusion.bloodTransfusion}</Typography>
                                <Typography variant="body2" color="textSecondary"
                                    sx={{ fontSize: '12px' }}
                                >
                                    Quantity: {transfusion.quantity} ml
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
                                    Components: {transfusion.components.join(', ')}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', marginTop: 2, fontSize: '12px' }}>
                                    Stopped At: {transfusion.stoppedAt}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', marginTop: 1, fontSize: '12px' }} >
                                    Reason: {transfusion.reason || 'No reason provided'}
                                </Typography>

                                <Box mt={2}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Transfusion History:
                                    </Typography>
                                    {transfusion?.transfuse_History?.map((history: any, idx: number) => (
                                        <Box key={idx} sx={{ marginBottom: 1 }}>
                                            <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
                                                {history?.action || history?.status} At: {history?.date_Time}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
                                                Given By: {history?.doctorName}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Dialog >

                <Dialog open={stopReasonDialogOpen} onClose={() => setStopReasonDialogOpen(false)}>
                    <DialogTitle>Please enter the Reason</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Reason"
                            type="text"
                            fullWidth
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className="search-field"
                            size="small"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setStopReasonDialogOpen(false)}
                            sx={{
                                backgroundColor: "red",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                        >
                            Cancel
                        </Button>

                        <Button onClick={handleConfirmStop} sx={{ fontSize: "0.85rem", color: "#007bff", textTransform: "capitalize" }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={!!selectedTransfusion} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle>
                        Detail
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{ position: "absolute", right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {selectedTransfusion ? (
                            <Box>
                                <Typography variant="h6" align="center">
                                    {selectedTransfusion.bloodTransfusion}
                                </Typography>
                                {selectedTransfusion?.transfuse_History?.map((history: any, index: number) => (
                                    <Box key={index} mb={2}>
                                        <Typography variant="body2">
                                            {history.status === "started" ? "Started at" : "Stopped at"}: {history?.date_Time}
                                        </Typography>
                                        <Typography variant="body2">By: {history?.doctorName}</Typography>
                                        {history?.reason && (
                                            <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                                                Reason: {history?.reason}
                                            </Typography>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <Typography variant="body2" align="center">
                                No details available.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} sx={{ fontSize: "0.8rem", color: "#007bff", textTransform: "capitalize" }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box >

            <Dialog open={openStopDialog}>
                <DialogContent>
                    <Typography>Are you sure you want to stop this medicine?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelStop} color="primary">
                        No
                    </Button>
                    <Button onClick={handleStopMedicine} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelMed} onClose={handleCloseStopDialog} maxWidth="md" fullWidth>
                <DialogContent>
                    <Box>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '13px' }}>
                            Stopped Medicines History
                        </Typography>
                        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Deleted Medicines" variant={"scrollable"}>
                            {formData?.stopped_medicines && Array.isArray(formData?.stopped_medicines) && formData?.stopped_medicines.length > 0 ? (
                                formData?.stopped_medicines.map((medicine: any, index: any) => (
                                    <Tab key={index} label={medicine?.name} />
                                ))
                            ) : (
                                <Typography>No Stopped Medicines Available</Typography>
                            )}
                        </Tabs>

                        {/* Check if the selectedTab is within the bounds of stopped_medicines */}
                        {formData?.stopped_medicines && formData?.stopped_medicines?.length > 0 && formData?.stopped_medicines[selectedTab] && (
                            <Box p={1}>
                                {/* <Typography variant="h6" gutterBottom>
                                    Medicine History for: {formData?.stopped_medicines[selectedTab]?.name}
                                </Typography> */}
                                {Array.isArray(formData?.stopped_medicines[selectedTab]?.med_History) &&
                                    formData?.stopped_medicines[selectedTab]?.med_History?.length > 0 ? (
                                    formData?.stopped_medicines[selectedTab]?.med_History?.map((item: any, idx: any) => {
                                        return (
                                            <Box key={idx}>
                                                <Box display="flex" alignItems="center" gap={2}>
                                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{idx + 1}:</span>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '10px',
                                                            color: item?.status === 'stopped' ? 'red' : 'gray',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {item?.status} By {item?.doctorName} at: {item?.date_Time}
                                                    </Typography>
                                                </Box>
                                                <Divider sx={{ width: '100%', mb: 0.2 }} />
                                            </Box>
                                        );
                                    })
                                ) : (
                                    <Typography sx={{ fontSize: '10px', color: 'gray' }}>No History Available</Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseStopDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default TreamentChart;

