
import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DynamicTable from "../../../Dynamic-table"; // Assuming you already have this component
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css';

interface ObsHistoryDialogProps {
    openModal: boolean;
    handleModalClose: () => void;
    admissionId: string; // Admission ID passed as a prop
    onObsHistoryChange: (newHistory: { pregNumber: string, detail: string, admissionId: string }[]) => void; // Callback function
    onhistorychnagedata: any;
    setHistoryData: (data: any) => void
}

const ObsHistoryDialog: React.FC<ObsHistoryDialogProps> = ({ openModal, handleModalClose, admissionId, onObsHistoryChange, onhistorychnagedata, setHistoryData }) => {
    const [pregDetails, setPregDetails] = useState<{ pregNumber: string, detail: string, admissionId: string }[]>([]);
    const [newDetail, setNewDetail] = useState<string>("");
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editPregNumber, setEditPregNumber] = useState<string>(""); // State to store the pregnancy number during edit

    // Helper function to get the suffix for pregnancy number
    const getPregnancySuffix = (index: number) => {
        if (index === 0) return "st";
        if (index === 1) return "nd";
        if (index === 2) return "rd";
        return "th";
    };

    const handleSave = () => {
        if (newDetail.trim()) {
            if (editIndex !== null) {
                // Edit mode: Update the existing record in-place
                const updatedPregDetails = [...pregDetails];
                updatedPregDetails[editIndex] = {
                    ...updatedPregDetails[editIndex],
                    detail: newDetail, // Only update the detail for the selected record
                };
                setPregDetails(updatedPregDetails);
                onObsHistoryChange(updatedPregDetails); // Notify the parent with the updated pregnancy history
                setEditIndex(null); // Exit edit mode
                setEditPregNumber(""); // Reset the pregnancy number after saving
            } else {
                // Add mode: Add a new pregnancy record
                const newPregDetail = {
                    pregNumber: `${pregDetails.length + 1}${getPregnancySuffix(pregDetails.length)} Preg`,
                    detail: newDetail,
                    admissionId: admissionId,
                };
                const updatedPregDetails = [...pregDetails, newPregDetail];
                setPregDetails(updatedPregDetails);
                onObsHistoryChange(updatedPregDetails);
            }

            setNewDetail(""); // Clear the input field after saving
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewDetail(event.target.value);
    };

    const handleEdit = (index: number) => {
        setNewDetail(pregDetails[index].detail); // Set the detail for the selected record
        setEditIndex(index); // Set the index to indicate we're editing
        setEditPregNumber(pregDetails[index].pregNumber); // Store the pregnancy number for the edit
    };

    const isEditDisabled = (index: number) => {
        return pregDetails[index].admissionId !== admissionId;
    };

    const handleDelete = (index: number) => {
        if (pregDetails[index].admissionId === admissionId) {
            const updatedDetails = pregDetails.filter((_, i) => i !== index);
            setPregDetails(updatedDetails);
            if (index === editIndex) {
                setEditIndex(null); // Reset edit mode if we're deleting the current edit record
                setNewDetail("");
            }
            onObsHistoryChange(updatedDetails); // Notify parent after deletion
        } else {
            toast.info("You do not have permission to delete this record.");
        }
    };

    const isDeleteDisabled = (index: number) => {
        return pregDetails[index].admissionId !== admissionId || index !== pregDetails.length - 1;
    };

    useEffect(() => {
        if (onhistorychnagedata?.pregnancyInfo) {
            const data = onhistorychnagedata
            setPregDetails(data?.pregnancyInfo);
            setHistoryData({ ObsHistory: data?.pregnancyInfo })
        }
    }, [onhistorychnagedata]);

    return (
        <Dialog open={openModal} onClose={handleModalClose} fullWidth maxWidth="xs">
            <Box sx={{ padding: 1.5 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h6">OBS History</Typography>
                    <IconButton onClick={handleModalClose}>
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Grid container spacing={1} mt={0.7}>
                    {editIndex !== null && (
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography >
                                {editPregNumber}
                            </Typography>
                        </Grid>
                    )}
                    {editIndex == null && (<Grid item md={4} sm={6} xs={12}>
                        <Typography>{pregDetails.length + 1}{getPregnancySuffix(pregDetails.length)} Preg :</Typography>
                    </Grid>
                    )}

                    <Grid item md={8} sm={6} xs={12}>
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            className="search-field"
                            value={newDetail}
                            onChange={handleChange}
                            label={editIndex !== null ? `Edit ${editPregNumber} Detail` : `Detail for ${pregDetails.length + 1}${getPregnancySuffix(pregDetails.length)} Preg`} // Dynamic label
                            onKeyDown={(e) => e.key === "Enter" && handleSave()}
                        />
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Button variant="text" onClick={handleSave}>
                                {editIndex === null ? "Save" : "Update"}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <DynamicTable
                            headers={["SN", "Preg", "Detail", "Actions"]}
                            data={pregDetails?.map((item, index) => [
                                index + 1,
                                item.pregNumber,
                                item.detail,
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <IconButton
                                        onClick={() => handleEdit(index)}
                                        sx={{ marginRight: 1 }}
                                        disabled={isEditDisabled(index)}  // Disable the Edit button based on admissionId
                                    >
                                        <EditIcon sx={{ color: isEditDisabled(index) ? "grey" : "#FFB800" }} />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDelete(index)}
                                        sx={{ marginRight: 1 }}
                                        disabled={isDeleteDisabled(index)} // Disable delete if admissionId doesn't match OR not the last pregnancy
                                    >
                                        <DeleteIcon sx={{
                                            color: isDeleteDisabled(index) ? "grey" : "#FF5454",  // Conditional color based on the disabled state
                                        }} />
                                    </IconButton>
                                </Box>
                            ])}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default ObsHistoryDialog;
