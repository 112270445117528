import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Box, IconButton, Typography, FormControlLabel, Checkbox, Divider, RadioGroup, Radio } from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
interface RulesRegulationModalProps {
    isOpen: boolean;
    handleOpenClose: () => void;
    onTimeOutUpdate: (updatedTimeOut: any) => void;
    initialFormState: any;
}
const RulesRegulationModal: React.FC<RulesRegulationModalProps> = ({ isOpen, handleOpenClose, onTimeOutUpdate, initialFormState }) => {
    const [formState, setFormState] = useState({
        confirm_All_Team_Members_Have_Introduced_Themselves_By_Name: true,
        surgeon_Reviews: true,
        anaesthesia_Team_Review: true,
        nursing_Team_Reviews: true,
        Has_Antibiotic_Prophylaxis_Been_Given_within_the_Last_60_Minutes: 'yes',
        is_Essential_Imaging_Displayed: 'yes'
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, value, checked } = event.target;
        setFormState((prevState: any) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    useEffect(() => {
        if (initialFormState) {
            setFormState(initialFormState);
        }
    }, [initialFormState]);
    const handleTimeOutClick = () => {
        onTimeOutUpdate(formState);
        handleOpenClose();
    };
    return (
        <Dialog
            open={isOpen}
            onClose={handleOpenClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <DialogTitle id="alert-dialog-title">Before Patient Leaves Operating Room</DialogTitle>
                <IconButton onClick={handleOpenClose}>
                    <HighlightOffOutlinedIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Box sx={{ bgcolor: "rgba(5, 95, 252, 1)", textAlign: "center", width: "100%", p: 1, color: "white" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                        Time Out
                    </Typography>
                </Box>
                <Box sx={{ bgcolor: "rgba(5, 95, 252, .1)" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.confirm_All_Team_Members_Have_Introduced_Themselves_By_Name}
                            onChange={handleChange} name="confirm_All_Team_Members_Have_Introduced_Themselves_By_Name" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "13px", fontWeight: "700" }}>
                            Confirm All Team Members Have Introduced Themselves By Name & Role
                        </span>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, flexDirection: "column" }}>
                        <span style={{ flexGrow: 1, fontSize: "13px", fontWeight: "700", textTransform: "capitalize" }}>
                            surgeon, anaesthesia professional & nurse verbally confirm
                        </span>
                        <ul style={{ display: 'flex', padding: 0, justifyContent: "space-evenly", width: "100%", fontSize: "12px" }}>
                            <li>patient</li>
                            <li>Site</li>
                            <li>Procedure</li>
                        </ul>
                    </Box>
                    <Divider />
                    <span style={{ flexGrow: 1, fontSize: "13px", fontWeight: "700", textTransform: "capitalize", paddingLeft: "6px" }}>
                        anticipated critical events
                    </span>
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.surgeon_Reviews} onChange={handleChange} name="surgeon_Reviews" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            surgeon reviews: what are the critical / unexpected steps, operatives duration, anticipated blood loss?
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.anaesthesia_Team_Review} onChange={handleChange} name="anaesthesia_Team_Review" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            anaesthesia team review: are there any patient specific concerns?
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                        <FormControlLabel
                            control={<Checkbox checked={formState?.nursing_Team_Reviews} onChange={handleChange} name="nursing_Team_Reviews" />}
                            label=""
                            sx={{ marginLeft: 'auto' }}
                        />
                        <span style={{ flexGrow: 1, fontSize: "12px", fontWeight: "600", textTransform: "capitalize" }}>
                            nursing team reviews: has sterility (including indicator results) been confirmed? are there equipment uses or any concerns?
                        </span>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', padding: 1, flexDirection: "column", pl: 2 }}>
                        <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "700", textTransform: "capitalize" }}>
                            has antibiotic prophylaxis been given within the last 60 minutes?
                        </Typography>
                        <RadioGroup
                            name="Has_Antibiotic_Prophylaxis_Been_Given_within_the_Last_60_Minutes"
                            row
                            value={formState?.Has_Antibiotic_Prophylaxis_Been_Given_within_the_Last_60_Minutes}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="yes" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel value="notaplicable" control={<Radio size="small" />} label="Not Applicable" />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ display: 'flex', padding: 1, flexDirection: "column", pl: 2 }}>
                        <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "700", textTransform: "capitalize" }}>
                            is essential imaging displayed?
                        </Typography>
                        <RadioGroup
                            name="is_Essential_Imaging_Displayed"
                            row
                            value={formState?.is_Essential_Imaging_Displayed}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="yes" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel value="notaplicable" control={<Radio size="small" />} label="Not Applicable" />
                        </RadioGroup>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "column", mt: 1 }}>
                    <Button sx={{ bgcolor: "rgba(5, 95, 252, 1)" }} variant="contained" onClick={handleTimeOutClick}>
                        Time Out
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
export default RulesRegulationModal;