import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch } from 'react-redux';


// Define the initial state
const initialState = {
  profile: null,
  loading: false,
  error: null,
};

// Function to get token from localStorage
const getToken = () => {
  return localStorage.getItem('token');
};

// Define an asynchronous thunk action to fetch profile data from the API
export const fetchProfileData = createAsyncThunk(
  'profile/fetchProfileData',
  async (_, { rejectWithValue }) => { // Use destructuring to access `rejectWithValue` function
    try {
      // const dispatch = useDispatch()
      const token = getToken();
      if (!token) {
        throw new Error('Token not found');
      }
      
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/getUserProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // dispatch(setProfileData(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message); // Use `rejectWithValue` to pass error message to reducer
    }
  }
);
// export const setProfileData = createAction('profile/setProfileData');


// Create a slice for the profile data
const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Access error message from action.payload
      });
  },
});

// Export actions and reducer
export default profileSlice.reducer;
