import { useEffect } from 'react';
import { DecodedTokenPayload } from '../types';

const useTokenExpirationCheck = (): void => {

  const token = localStorage.getItem('token');

  const decodeJwtToken = (token: string): DecodedTokenPayload | null => {
    try {
      const [, payloadEncoded] = token.split('.').slice(0, 2);
      const decodedPayload = JSON.parse(atob(payloadEncoded)) as DecodedTokenPayload;
      return decodedPayload;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const isTokenExpired = (decodedToken: DecodedTokenPayload | null): boolean => {
    if (decodedToken && decodedToken.exp) {
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTime;
    }
    return false;
  };

  useEffect(() => {
    if (token) {
      const decodedToken = decodeJwtToken(token);
      if (isTokenExpired(decodedToken)) {
        localStorage.removeItem('token');

        window.location.href = '/login';
      } else {
        // console.log('Token is still valid.');
      }
    } else {
      if (window.location.pathname !== '/login') {
        if (window.location.pathname === '/sign-up' || window.location.pathname.includes('/verify-email')) {

        } else {
          window.location.href = '/login';
        }

      }
      // window.location.href = '/login';
    }
  }, []); // This effect will run only once on component mount
};

export default useTokenExpirationCheck;
