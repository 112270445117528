import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state
const initialState = {
  diagnoses: [],
  status: 'idle',
  error: null,
};

// Define the thunk to fetch data from the API
export const fetchDiagnoses = createAsyncThunk(
  'diagnoses/fetchDiagnoses',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_OPD_API_URL}/diagnosis/getAllDiagnosis`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },

    });
    return response.data;
  }
);

// Create the diagnosis slice
const diagnosisSlice = createSlice({
  name: 'diagnosisSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiagnoses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDiagnoses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.diagnoses = action.payload;
      })
      .addCase(fetchDiagnoses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default diagnosisSlice.reducer;

