import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Modal,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MedicineType from "./medicine-type";
import AddIcon from "@mui/icons-material/Add";
import MedicineScheduleForm from "../../../Modals/delete-modal/medicine-schedule-modal";
import MedicineAdministrationModal from "../../../Modals/adm-route-modal";
import Loader from "../../../loader/loader";
import debounce from "lodash.debounce";

interface MedicineForm {
  selectedRowData: any;
  onSubmit: () => void;
}

interface FormData {
  medicineName: string;
  medicineUnit: string;
  strength: string;
  medicineTypeId: string;
  companyName: string;
  genericId: string;
  routAdministrationId: string;
  unit: string;
  duration: string;
  dosage: string;
  durationUnit: string;
  frequency: {
    urdu_notation: string;
    math_notation: string;
    eng_notation: string;
    abr_notation: string;
  };
  note: string;
}

const MedicineForm: React.FC<MedicineForm> = ({
  selectedRowData,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<FormData>({
    medicineName: "",
    routAdministrationId: "",
    medicineUnit: "",
    strength: "",
    medicineTypeId: "",
    companyName: "",
    genericId: "",
    dosage: "",
    unit: "",
    duration: "1",
    durationUnit: "Days",
    frequency: {
      urdu_notation: "",
      math_notation: "",
      eng_notation: "",
      abr_notation: "",
    },
    note: "",
  });
  const [medicineType, setmedicineType] = useState<any>();
  const [generic, setGeneric] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const [frequency, setFrequency] = useState([]); // Initialize as an empty array or appropriate initial value
  const [selectedMedicineTypeId, setSelectedMedicineTypeId] =
    useState<string>("");
  const [selectedMedicineTypeName, setSelectedMedicineTypeName] =
    useState<string>("");
  const [dataExists, setDataExists] = useState(false);
  const [routeAdm, setRouteAdm] = useState<any>();
  const [typeAbr, setTypeAbr] = useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [inputunit, setInputUnit] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showTypeForm, setShowTypeForm] = useState(false);
  const [openFreq, setOpenFreq] = useState<boolean>(false);
  const [openRoute, setOpenRoute] = useState<boolean>(false);
  const [loading, setloading] = useState(false);
  const [searchGeneric, setSearchGeneric] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = debounce((value) => {
    getmedicineTypeId(value);
  }, 500);
  const handleInputChangeMedicineType = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
    // getmedicineTypeId(value)
  };

  const handleInputChangeMedicineTypeBlur = () => {
    debouncedSearch("");
    // getmedicineTypeId(value)
  };

  const handleCloseFreq = () => setOpenFreq(!openFreq);
  const handleCloseRoute = () => setOpenRoute(!openRoute);

  const handleOpenModal = () => {
    setModalOpen(true);
    setShowTypeForm(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getSchedule = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/dosage-schedules/getAllByPagination`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            count: 50000,
            pageNo: 1,
          },
        }
      );
      setFrequency(response.data?.data);
    } catch (error) {
      console.error("Error fetching schedule:", error);
    }
  };

  useEffect(() => {
    if (selectedRowData) {
      // If selectedRowData exists, update the form data state with the selected row data
      // setFormData(selectedRowData);
      setFormData((prevData: any) => ({
        ...prevData,
        medicineName: selectedRowData?.medicineName,
        medicineUnit: selectedRowData?.medicineUnit ? selectedRowData?.medicineUnit : '',
        strength: selectedRowData?.strength,
        medicineTypeId: selectedRowData?.medicineTypeId?._id,
        routAdministrationId: selectedRowData?.routAdministrationId,
        companyName: selectedRowData?.companyName,
        genericId: selectedRowData?.genericId?._id,
        unit: selectedRowData?.unit,
        duration: selectedRowData?.duration,
        durationUnit: selectedRowData?.durationUnit,
        frequency: selectedRowData?.frequency,
        note: selectedRowData?.note,
        dosage: selectedRowData?.dosage,
      }));
      setSelectedMedicineTypeId(selectedRowData?.medicineTypeId?._id);
      setDataExists(true);
    }
  }, [selectedRowData]);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    // const parsedValue =
    //   name === "strength" ? Math.max(0, parseFloat(value)) : value;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "medicineTypeId") {
      setSelectedMedicineTypeId(value as string);
      setUnit([]); // Clear units when medicine type changes
      getUnitsByMedicineTypeId(value); // Fetch units based on the selected medicine type
    }
  };

  const handleShortCourseChange = (
    e: { target: { name: any; value: any } } | any
  ) => {
    // Handle both event and direct value cases
    const name = e.target ? e.target.name : e.name;
    const value = e.target ? e.target.value : e.value;

    if (name === "unit") {
      // Ensure value is not less than 0 for "unit" and "duration"
      const parsedValue = Math.max(0, parseInt(value, 10));
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue.toString(), // Convert parsed value to string if necessary
      }));
    } else if (name === "frequency") {
      // Handle case where value might be an object or null
      if (value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          frequency: {
            urdu_notation: value.urdu_notation || "",
            math_notation: value.math_notation || "",
            eng_notation: value.eng_notation || "",
            abr_notation: value.abr_notation || "",
          },
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          frequency: {
            urdu_notation: "",
            math_notation: "",
            eng_notation: "",
            abr_notation: "",
          },
        }));
      }
    } else {
      // Handle updates for other fields
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const getRouteAdm = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/getAllByPagination`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          count: 50000,
          pageNo: 1,
        },
      }
    );
    setRouteAdm(response.data?.data);
  };

  const debouncedGetGeneric = useCallback(
    debounce(async (search: string) => {
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/generic/getAllSelectAbleGeneric?search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // params: {
          //   count: 50000,
          //   pageNo: 1,
          // },
        }
      );
      setGeneric(response.data?.data);
    }, 500), // Debounce time: 500ms
    []
  );
  useEffect(() => {
    debouncedGetGeneric(searchGeneric);
  }, [debouncedGetGeneric, searchGeneric]);

  const getUnitsByMedicineTypeId = async (medicineTypeId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/medicinetype/getById/${medicineTypeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const units = response.data?.data?.units || [];
      setUnit(units);
      // Set default unit if units are available
      if (units.length > 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          medicineUnit: units[0], // Set the default unit
        }));
      }
    } catch (error) {
      console.error("Error fetching units:", error);
      toast.error("Failed to load units");
    }
  };

  useEffect(() => {
    const medicineTypesName = medicineType?.find(
      (mt: any) => mt?._id === selectedMedicineTypeId
    );
    setSelectedMedicineTypeName(medicineTypesName?.medicineTypeName);
    setTypeAbr(medicineTypesName?.medicineTypeAbrivation || "");
  }, [selectedMedicineTypeId]);

  useEffect(() => {
    setFormData((prevdata) => ({
      ...prevdata,
      medicineUnit: inputunit,
    }));
  }, [inputunit]);

  const getmedicineTypeId = async (search: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_OPD_API_URL}/medicineType/getAll`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          count: 50000,
          pageNo: 1,
          search: search,
        },
      }
    );
    const fetchedMedicineType = response?.data?.data || [];
    const uniqueMedicineType = Array.from(
      new Map(fetchedMedicineType?.map((item: any) => [item?.medicineTypeName, item]))?.values()
    );
    setmedicineType(uniqueMedicineType);
  };

  const resetForm = () => {
    setFormData({
      medicineName: "",
      medicineUnit: "",
      strength: "",
      medicineTypeId: "",
      routAdministrationId: "",
      companyName: "",
      genericId: "",
      unit: "",
      duration: "",
      dosage: "",
      durationUnit: "Days",
      frequency: {
        urdu_notation: "",
        math_notation: "",
        eng_notation: "",
        abr_notation: "",
      },
      note: "",
    });
  };

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [modalOpen, searchTerm]);

  useEffect(() => {
    // getGeneric();
    if (selectedMedicineTypeId && !selectedRowData) {
      getUnitsByMedicineTypeId(selectedMedicineTypeId);
    }
    if (selectedRowData) {
      setFormData((prevData) => ({
        ...prevData,
        medicineUnit: selectedRowData.medicineUnit,
      }))
    }
  }, [selectedMedicineTypeId]);

  // useEffect(() => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     medicineUnit: selectedRowData.medicineUnit,
  //   }));
  // }, [selectedRowData]);
  useEffect(() => {
    getRouteAdm();
  }, [openRoute]);
  useEffect(() => {
    getSchedule();
  }, [openFreq]);
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setloading(true);
    try {
      let updatedFormData = { ...formData };
      if (typeAbr) {
        // Concatenate typeAbr with medicineName if typeAbr is not empty
        updatedFormData.medicineName = `${typeAbr}-${formData.medicineName}`;
      }
      let response;
      if (selectedRowData && dataExists) {
        // PATCH request for updating existing medicine
        response = await axios.patch(
          `${process.env.REACT_APP_OPD_API_URL}/medicine/updateById/${selectedRowData?._id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        // POST request for creating new medicine
        response = await axios.post(
          `${process.env.REACT_APP_OPD_API_URL}/medicine/createMedicine`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }

      if (response.data?.isSuccess === false) {
        toast.error(response.data?.message);
        setloading(false);
      } else {
        toast.success(response.data?.message);
        onSubmit();
        resetForm(); // Reset the form after successful submission
        setDataExists(false);
        setloading(false);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setloading(false);
    }
  };

  const handleCreateNewGeneric = async (inputValue: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_OPD_API_URL}/generic/createGeneric`,
        { genericName: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const newGeneric = response?.data?.data;
      if (newGeneric) {
        // setGeneric((prevGenerics: any) => [...prevGenerics, newGeneric]);
        setGeneric((prevGenerics: any) => [
          ...(Array.isArray(prevGenerics) ? prevGenerics : []),
          newGeneric
        ]);
        setFormData((prevFormData) => ({
          ...prevFormData,
          genericId: newGeneric?._id,
        }));
        toast.success(
          `New generic "${newGeneric?.genericName}" added successfully`
        );
      } else {
        toast.error("Failed to create new generic");
      }
    } catch (error) {
      console.error("Error creating new generic:", error);
      toast.error("Failed to create new generic");
    }
  };

  const handleInputChange = (
    event: any,
    newInputValue: React.SetStateAction<string>
  ) => {
    setInputValue(newInputValue);
    setSearchGeneric(newInputValue);
  };

  const handleInputChangeBlur = () => {
    setInputValue("");
    setSearchGeneric("");
  };

  const handleAddButtonClick = () => {
    if (inputValue.trim() !== "") {
      handleCreateNewGeneric(inputValue);
      setInputValue("");
    }
  };

  const handleKeyPress = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of form submission
      handleAddButtonClick();
    }
  };

  return (
    <Box
      sx={{
        background: "#fff",
        p: 2,
        borderRadius: "8px",
        maxHeight: { md: "78vh", sm: "100%", xs: "100%" },
        overflowY: "auto", 
        position: "relative",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#077DD3",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "rgba(7, 125, 211, 0.24)",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1.5}>
          <Grid item md={6} xs={6} sm={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Medicine Type :
            </Typography>
            <Autocomplete
              options={medicineType || []}
              size="small"
              getOptionLabel={(option: any) => option?.medicineTypeName}
              value={
                medicineType?.find(
                  (type: any) => type?._id === formData?.medicineTypeId
                ) || null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  handleChange({
                    target: {
                      name: "medicineTypeId",
                      value: newValue?._id,
                    },
                  });
                }
              }}
              // filterOptions={(options) => {
              //   const filteredOptions = options?.filter((option) =>
              //     option?.medicineTypeName?.toLowerCase().includes(searchTerm.toLowerCase())
              //   );
              //   return filteredOptions;
              // }}

              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Medicine Type"
                  variant="outlined"
                  // size="small"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                        <AddIcon
                          onClick={handleOpenModal}
                          sx={{
                            background: "#FFFFFF",
                            color: "#055FFC",
                            borderRadius: "50%",
                            p: 0.5,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        />
                      </React.Fragment>
                    ),
                  }}
                  className="search-field"
                  onChange={handleInputChangeMedicineType} // Add this line
                  onBlur={handleInputChangeMedicineTypeBlur} // Add this line
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Company Name :
            </Typography>
            <TextField
              size="small"
              placeholder="Company Name"
              fullWidth
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="search-field"
            />
          </Grid>
          <Grid item md={3} sm={3} xs={2}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Abr :
            </Typography>
            <TextField
              size="small"
              placeholder="ABR"
              fullWidth
              value={typeAbr}
              disabled
              className="search-field"
            />
          </Grid>
          <Grid item md={9} sm={6} xs={10}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Medicine Name :
            </Typography>
            <TextField
              size="small"
              placeholder="Medicine Name"
              fullWidth
              name="medicineName"
              required
              value={formData.medicineName}
              onChange={handleChange}
              className="search-field"
            />
          </Grid>

          <Grid item md={6} xs={6} sm={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Strength :
            </Typography>
            <TextField
              size="small"
              placeholder="Strength"
              fullWidth
              name="strength"
              value={formData.strength}
              onChange={handleChange}
              className="search-field"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Units :
            </Typography>

            <Autocomplete
              fullWidth
              size="small"
              options={unit || []}
              getOptionLabel={(option) => option || ""}
              value={formData.medicineUnit}
              onChange={(event, newValue) => {
                handleChange({
                  target: {
                    name: "medicineUnit",
                    value: newValue ? newValue : "",
                  },
                });
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Unit"
                  value={inputunit}
                  onChange={(event) => setInputUnit(event.target.value)}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  className="search-field"
                />
              )}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={12}>
            <Grid item xs={6} sm={6} md={12}>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
              >
                Generic :
              </Typography>
              <Autocomplete
                fullWidth
                size="small"
                options={generic ?? []}
                getOptionLabel={(option) => option?.genericName}
                value={
                  generic?.find(
                    (gen: any) => gen?._id === formData?.genericId
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "genericId",
                      value: newValue ? newValue._id : "",
                    },
                  });
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Generic"
                    value={inputValue}
                    onChange={(event) =>
                      handleInputChange(event, event.target.value)
                    }
                    onBlur={handleInputChangeBlur}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}

                          <AddIcon
                            sx={{
                              background: "#FFFFFF",
                              color: "#055FFC",
                              borderRadius: "50%",
                              p: 0.2,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={handleAddButtonClick}
                          />
                          {/* </Button> */}
                        </React.Fragment>
                      ),
                    }}
                    className="search-field"
                  />
                )}
                filterOptions={(options, state) => {
                  const searchGeneric = state.inputValue.toLowerCase();
                  if (searchGeneric === "") {
                    // If search term is empty, show all medicines
                    return options;
                  }
                  // Filter based on search term
                  return options?.filter((option) =>
                    option.genericName?.toLowerCase().includes(searchGeneric)
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid item md={4} sm={6} xs={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Dosage :
            </Typography>
            <TextField
              size="small"
              placeholder="Dosage"
              fullWidth
              name="dosage"
              value={formData.dosage}
              onChange={handleShortCourseChange}
              className="search-field"
            />
          </Grid>

          <Grid item md={8} sm={6} xs={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Freq :
            </Typography>
            <Autocomplete
              size="small"
              options={frequency || []} // Ensure frequency is always an array
              getOptionLabel={(option: any) => option?.abr_notation || ""} // Ensure option is not null
              value={
                frequency?.find(
                  (option: any) =>
                    option?.abr_notation === formData?.frequency?.abr_notation
                ) || null
              }
              onChange={(event, newValue) => {
                handleShortCourseChange({
                  target: {
                    name: "frequency",
                    value: newValue || {
                      urdu_notation: "",
                      math_notation: "",
                      eng_notation: "",
                      abr_notation: "",
                    },
                  },
                });
              }}
              filterOptions={(options, { inputValue }) => {
                const filteredOptions = options?.filter((option) =>
                  option.abr_notation.toLowerCase().includes(inputValue.toLowerCase())
                );
                return filteredOptions;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Freq"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                        <AddIcon
                          onClick={() => setOpenFreq(true)}
                          sx={{
                            background: "#FFFFFF",
                            color: "#055FFC",
                            borderRadius: "50%",
                            p: 0.2,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        />
                      </React.Fragment>
                    ),
                  }}
                  className="search-field"
                />
              )}
            />
          </Grid>

          <Grid item md={7} sm={6} xs={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Route : <span style={{ fontSize: "8px" }}>(optional)</span>
            </Typography>

            <Autocomplete
              options={routeAdm || []} // Ensure options is always an array
              getOptionLabel={(option: any) => option?.RA_Abbreviation || ""} // Handle undefined option
              value={
                routeAdm?.find(
                  (r: any) => r?._id === formData?.routAdministrationId
                ) || null
              } // Handle undefined formData.routAdministrationId
              onChange={(event, newValue) => {
                // Handle the change event
                if (newValue) {
                  handleChange({
                    target: {
                      name: "routAdministrationId",
                      value: newValue._id,
                    },
                  });
                } else {
                  handleChange({
                    target: { name: "routAdministrationId", value: "" },
                  });
                }
              }}
              filterOptions={(options, { inputValue }) => {
                const filteredOptions = options?.filter((option) =>
                  option.RA_Abbreviation.toLowerCase().includes(inputValue.toLowerCase())
                );
                return filteredOptions;
              }}
              size="small"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Route"
                  name="routAdministrationId"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                        <AddIcon
                          onClick={() => setOpenRoute(true)}
                          sx={{
                            background: "#FFFFFF",
                            color: "#055FFC",
                            borderRadius: "50%",
                            p: 0.2,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        />
                      </React.Fragment>
                    ),
                  }}
                  className="search-field"
                />
              )}
            />
          </Grid>

          <Grid item sm={6} md={5} xs={6}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Duration :{" "}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                size="small"
                className="search-field"
                type="number"
                name="duration"
                value={formData.duration}
                onChange={handleShortCourseChange}
              />
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    background: "#F5F5FB",
                    borderRadius: "12px",
                    // border: "1px solid #D8D8D8",
                    fontSize: "12px",
                  },
                }}
              >
                <Select
                  placeholder="Duration Type"
                  name="durationUnit"
                  value={formData.durationUnit}
                  onChange={handleShortCourseChange}
                  sx={{
                    borderRadius: "12px",
                    // border: "1px solid #D8D8D8",
                    background: "#F5F5FB",
                    fontSize: "12px",
                    boxShadow: "none", // Remove any default shadow that might look like a border
                    "& fieldset": {
                      // Target the fieldset for better border control
                      border: "none", // Remove default border if present
                    },
                  }}
                >
                  <MenuItem value="Days">Days</MenuItem>
                  <MenuItem value="Weeks">Weeks</MenuItem>
                  <MenuItem value="Hours">Hours</MenuItem>
                  <MenuItem value="Minutes">Minutes</MenuItem>
                  <MenuItem value="Months">Months</MenuItem>
                  <MenuItem value="Years">Years</MenuItem>
                  <MenuItem value="Lifetime">Lifetime</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} md={12}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Note :{" "}
            </Typography>
            <TextField
              className="search-field"
              size="small"
              placeholder="Note"
              multiline
              fullWidth
              name="note"
              value={formData.note}
              onChange={handleShortCourseChange}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {loading ? (
            <Loader />
          ) : (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Box>
      </form>
      {/* <MedicineTypeModal open={modalOpen} onClose={() => setModalOpen(false)} showTypeForm={showTypeForm}  /> */}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="xs"
      >
        <MedicineType
          onClose={() => setModalOpen(false)}
          showTypeForm={showTypeForm}
        />{" "}
        {/* Pass onClose to close the modal */}
      </Dialog>
      <Dialog
        open={openFreq}
        onClose={handleCloseFreq}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <MedicineScheduleForm onClose={handleCloseFreq} />
      </Dialog>
      <Dialog
        open={openRoute}
        onClose={handleCloseRoute}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <MedicineAdministrationModal onClose={handleCloseRoute} />
      </Dialog>
    </Box>
  );
};

export default MedicineForm;
